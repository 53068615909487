import { BaseEntity } from "src/app/shared/@models/baseEntity";
import { courseBusinessUnitCourse } from "./course-business-unit-course";

export class courseBusinessUnit extends BaseEntity {
  businessUnitId: string;
  courseBusinessUnitCourses: courseBusinessUnitCourse[];

  constructor() {
    super();

    this.courseBusinessUnitCourses = [];
  }
}
