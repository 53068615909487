import { Component, OnInit } from '@angular/core';
import { hotsites } from '../../@model/hotsites';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hotsites-index',
  templateUrl: './hotsites-index.component.html',
  styleUrls: ['./hotsites-index.component.scss']
})
export class HotsitesIndexComponent implements OnInit {

  hotsites: hotsites = new hotsites();
  hotsities: hotsites[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "asc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.doSearch(1);
  }

  onCreate() {
    this.removeAction = false;
    this.hotsites = new hotsites();
    this.hotsites.isActive = true;
    this.hotsites.show = true;
    this.modalService.modal('modal-hotsites').title = "Criar hotsites";
    this.modalService.modal('modal-hotsites').to = "modal";
    this.modalService.modal('modal-hotsites').labelAction = "Salvar";
    this.modalService.modal('modal-hotsites').labelClose = "Cancelar";
    this.modalService.modal('modal-hotsites').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-hotsites');
  }

  onEdit(hotsites: hotsites): void {
    this.hotsites = Object.assign({}, hotsites);
    this.removeAction = false;
    this.modalService.modal('modal-hotsites').title = "Editar hotsite";
    this.modalService.modal('modal-hotsites').to = "modal";
    this.modalService.modal('modal-hotsites').labelAction = "Salvar";
    this.modalService.modal('modal-hotsites').labelClose = "Cancelar";
    this.modalService.modal('modal-hotsites').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-hotsites');
  }

  onRemove(hotsites: hotsites): void {
    this.hotsites = hotsites;
    this.removeAction = true;
    this.modalService.modal('modal-hotsites').title = "Excluir Hotsite " + this.hotsites.title;
    this.modalService.modal('modal-hotsites').to = "modal";
    this.modalService.modal('modal-hotsites').labelAction = "Excluir";
    this.modalService.modal('modal-hotsites').labelClose = "Cancelar";
    this.modalService.modal('modal-hotsites').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-hotsites');
  }

  onDetails(hotsites: hotsites): void {
    this.removeAction = false;
    this.hotsites = Object.assign({}, hotsites);
    this.modalService.modal('modal-hotsites').title = "Detalhes do Hotsite";
    this.modalService.modal('modal-hotsites').to = "modal";
    this.modalService.modal('modal-hotsites').labelClose = "Fechar";
    this.modalService.modal('modal-hotsites').footerAction = false;
    this.modalService.modal('modal-hotsites').noAction = true;
    this.modalService.open('modal-hotsites');
  }

  getHotsites(n: number = 1, order) {
    this.isinit = false;
    this.paginationSearch = false;
    this.apiClientService.getPaginated(environment.urls.api, "hotsite", n, this.pageSize, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.hotsities = [];
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.hotsities.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  doSearch(n: number = 1) {

    if (!this.searchByResult) {
      this.getHotsites(1, this.order);
      return
    }

    let cmd = {
      "code": this.searchByResult,
      "title": this.searchByResult,
      "description": this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.hotsities.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, 'hotsite/search', cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.pageSize = data.pageSize
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.hotsities.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getHotsites(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getHotsites(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  saveData(): void {
    if (this.removeAction && this.validateCurrent()) {
      this.apiClientService.delete<hotsites>(environment.urls.api, 'hotsite', this.hotsites.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.hotsites !== null && this.validateCurrent()) {
        this.apiClientService.save<hotsites>(environment.urls.api, 'hotsite', this.hotsites, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  callbackSuccess = (resp: any) => {
    let msg = 'Hotsite ' + (this.removeAction ? 'excluído' : this.hotsites.id !== undefined && this.hotsites.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getHotsites(1, this.order);
    this.modalService.close('modal-hotsites');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.hotsites.title === null || this.hotsites.title === undefined || this.hotsites.title === '') {
      this.toastr.error("Campo Título deve ser preenchido.");
      response = false;
    }
    if (this.hotsites.description === null || this.hotsites.description === undefined || this.hotsites.description === '') {
      this.toastr.error("Campo Descrição deve ser preenchido.");
      response = false;
    }
    if (this.hotsites.link === null || this.hotsites.link === undefined || this.hotsites.link === '') {
      this.toastr.error("Campo Link deve ser preenchido.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

}
