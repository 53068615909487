import { NgModule } from '@angular/core';
import { CourseBusinessUnitFormComponent } from './@pages/course-business-unit-form/course-business-unit-form.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';

const routes: Routes = [
  {
    path: 'course-business-unit-form/index',
    component: CourseBusinessUnitFormComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class CourseBusinessUnitRoutingModule { }
