import { Component, OnInit } from '@angular/core';
import { banner } from '../../@model/banner';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';
import { businessUnit } from 'src/app/shared/@models/businessUnit';

@Component({
  selector: 'app-carousel-index',
  templateUrl: './carousel-index.component.html',
  styleUrls: ['./carousel-index.component.scss']
})
export class CarouselIndexComponent implements OnInit {

  banner: banner = new banner();
  banners: banner[] = [];
  direction: boolean = true;
  pageSize: number = 0;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "Order";
  show: boolean = true;
  businessUnit: businessUnit[] = [];
  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.getBanners(1, this.order);
    this.getBusinessUnit(1, this.order);
  }

  onCreate() {
    this.removeAction = false;
    this.banner = new banner();
    this.banner.show = true;
    this.modalService.modal('modal-banner').title = "Criar Banner";
    this.modalService.modal('modal-banner').to = "modal";
    this.modalService.modal('modal-banner').labelAction = "Salvar";
    this.modalService.modal('modal-banner').labelClose = "Cancelar";
    this.modalService.modal('modal-banner').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-banner');
  }

  onEdit(banner: banner): void {
    this.banner = Object.assign({}, banner);
    this.removeAction = false;
    this.modalService.modal('modal-banner').title = "Editar Banner";
    this.modalService.modal('modal-banner').to = "modal";
    this.modalService.modal('modal-banner').labelAction = "Salvar";
    this.modalService.modal('modal-banner').labelClose = "Cancelar";
    this.modalService.modal('modal-banner').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-banner');
  }

  onRemove(banner: banner): void {
    this.banner = banner;
    this.removeAction = true;
    this.modalService.modal('modal-banner').title = "Excluir Banner";
    this.modalService.modal('modal-banner').to = "modal";
    this.modalService.modal('modal-banner').labelAction = "Excluir";
    this.modalService.modal('modal-banner').labelClose = "Cancelar";
    this.modalService.modal('modal-banner').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-banner');
  }

  onUp(banner: banner): void {
    this.banner = Object.assign({}, banner);
    this.apiClientService.save<banner>(environment.urls.api, 'banner/up', this.banner, this.callbackSuccess, this.callbackErr);
  }

  onDown(banner: banner): void {
    this.banner = Object.assign({}, banner);
    this.apiClientService.save<banner>(environment.urls.api, 'banner/down', this.banner, this.callbackSuccess, this.callbackErr);

  }

  onDetails(banner: banner): void {
    this.removeAction = false;

    this.banner = Object.assign({}, banner);

    this.modalService.modal('modal-banner').title = "Detalhes do Banner";
    this.modalService.modal('modal-banner').to = "modal";
    this.modalService.modal('modal-banner').labelClose = "Fechar";
    this.modalService.modal('modal-banner').footerAction = false;
    this.modalService.modal('modal-banner').noAction = true;
    this.modalService.open('modal-banner');
  }



  sortResult() {
    if (this.direction == false) {
      this.order = "desc";
      this.getBanners(1, this.order);
      this.direction = true;
    }
    else {
      this.order = "asc";
      this.getBanners(1, this.order);
      this.direction = false;
    }
  }

  getBanners(n: number = 1, order) {
    this.isinit = false;
    this.banners = [];
    this.apiClientService.getAll(environment.urls.api, "banner").then((data: banner[]) => {
      if (data !== undefined) {
        this.banners = data;
        this.totalRows = this.banners.length;
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  getBusinessUnit(n: number = 1, order) {
    this.apiClientService.getAll(environment.urls.api, "BusinessUnit").then((data: businessUnit[]) => {
      this.businessUnit = data;
    }).finally(() => {
    });
  }

  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<banner>(environment.urls.api, 'banner', this.banner.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.banner !== null && this.validateCurrent()) {
        this.apiClientService.save<banner>(environment.urls.api, 'banner', this.banner, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  callbackSuccess = (resp: any) => {
    let msg = `Banner ` + (this.removeAction ? `excluído` : this.banner.id !== undefined && this.banner.id !== null ? `alterado` : `criado`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.getBanners(1, this.order);
    this.modalService.close('modal-banner');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.banner.description === null || this.banner.description === undefined || this.banner.description === '') {
      this.toastr.error("Campo Descrição deve ser preenchido.");
      response = false;
    }

    if (this.banner.businessUnitId === undefined || this.banner.businessUnitId === null || this.banner.businessUnitId === '' || this.banner.businessUnitId === '') {

    } else {
      this.banner.businessUnitName = this.businessUnit.find(param => param.integrationCode == this.banner.businessUnitId).name;
    }

    if (this.banner.image === undefined || this.banner.image === null || this.banner.image === '') {
      this.toastr.error("Campo Imagem deve ser preenchido.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }



}
