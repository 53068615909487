import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbSelectModule, NbTabsetModule, NbUserModule, NbDatepickerModule, NbTimepickerModule } from '@nebular/theme';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { SenacRoutingModule } from './senac-routing.module';
import { SenacIndexComponent } from './@pages/senac-index/senac-index.component';
import { SenacFormComponent } from './@pages/senac-form/senac-form.component';


@NgModule({
  declarations: [SenacIndexComponent, SenacFormComponent],
  imports: [
    CommonModule,
    SenacRoutingModule,
    SharedModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AutocompleteLibModule,
    BsDropdownModule.forRoot(),
    NgxMaskModule.forRoot(),
    /* NEBULAR - Modules */
    NbLayoutModule,
    NbSelectModule,
    NbActionsModule,
    NbCheckboxModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbFormFieldModule,
    NbTabsetModule,
    NbUserModule,
    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NbIconModule,
    NbInputModule,
    NzCarouselModule,
    NbDatepickerModule,
    NbTimepickerModule
  ]
})
export class SenacModule { }
