import { Component, ElementRef, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from './modal.service';
import { Modal } from './modal';


@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Output() onClose: EventEmitter<any>;
  @Output() onAction: EventEmitter<any>;

  setedModal: boolean = false;

  public modal: Modal = new Modal();
  private element: any;
  object: any;
  scrollX: number;
  scrollY: number;

  constructor(private modalService: ModalService, private el: ElementRef) {
      this.element = el.nativeElement;
      this.onClose = new EventEmitter<any>();
      this.onAction = new EventEmitter<any>();
      this.setedModal = false;
  }

  ngOnInit(): void {
      let modal = this;

      if (!this.id) {
          console.error('modal must have an id');
          return;
      }

      this.setedModal = this.modal !== null && this.modal !== undefined;

      this.element.addEventListener('keypress', function(e: any) {
          if (e === 27) {
              modal.enableScroll();
              modal.close();
          }
      });

      this.modalService.add(this);
  }

  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }

  open = (): void => {
      this.scrollX = window.pageXOffset === undefined ? document.body.scrollTop : window.pageXOffset;
      this.scrollY = window.pageYOffset  === undefined ? document.body.scrollLeft : window.pageYOffset;
      window.scroll(0, 0);

      if(!this.setedModal && this.modal.to.toLowerCase() === 'modal') {
        this.element.querySelector('.modal-body').style.maxHeight = (window.innerHeight - (window.innerHeight * 0.2) - 140) + "px";
      } else {
        this.element.querySelector('.modal-body').style.maxHeight = 'auto';
      }

      this.element.querySelector('.modal').style.display = "block"
      this.element.querySelector('.modal-body').style.overflow = "auto"
      this.element.querySelector('.modal-backdrop').classList.add('show');
      this.element.firstElementChild.classList.add('show');
      document.body.style.overflow = "hidden";
      this.element.style.display = 'block';
  }

  close = (): void => {
      this.onClose.emit(null);
      this.enableScroll();
      window.scroll(this.scrollX, this.scrollY);
      this.element.firstElementChild.classList.remove('show');
      this.element.querySelector('.modal-backdrop').classList.remove('show');
      this.element.style.display = 'none';
  }

  action = (): void =>  {
    this.onAction.emit(null);
  }

  enableScroll() {
    document.body.style.overflow = "auto";
  }
}