import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CarouselRoutingModule } from './carousel-routing.module';
import { NbCardModule, NbButtonModule, NbActionsModule, NbSelectModule, NbCheckboxModule } from '@nebular/theme';
import { CarouselIndexComponent } from './@pages/carousel-index/carousel-index.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CarouselFormComponent } from './@pages/carousel-form/carousel-form.component';
import { CarouselBtnDropActionComponent } from './components/carousel-btn-drop-action/carousel-btn-drop-action.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [CarouselIndexComponent, CarouselFormComponent, CarouselBtnDropActionComponent],
  imports: [
    CommonModule,
    FormsModule,
    CarouselRoutingModule,
    SharedModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    BsDropdownModule.forRoot()
  ]
})

export class CarouselModule { }
