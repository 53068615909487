import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './@pages/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './@pages/internal-server-error/internal-server-error.component';
import { HelpComponent } from './@pages/help/help.component';
import { AccessDeniedComponent } from './@pages/access-denied/access-denied.component';
const routes: Routes = [
  {
    path: 'internalError',
    component: InternalServerErrorComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'accessdenied',
    component: AccessDeniedComponent
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class SharedRountingModule { }
