export class areas{
    $id: string;
    areaCode: string;
    areaDescription: string;

    constructor(id: string, areaCode: string, areaDescription: string) {
        this.$id = id;
        this.areaCode = areaCode;
        this.areaDescription = areaDescription;
    }
}