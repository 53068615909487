import { Component, OnInit, Input } from '@angular/core';
import { evidence } from '../../@model/evidence';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';

import { course } from '../../../shared/@models/course';
import { product } from '../../../shared/@models/product';
import { hotsites } from 'src/app/hotsites/@model/hotsites';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { ToastrService } from 'ngx-toastr';
import { areas } from 'src/app/shared/@models/areas';

@Component({
  selector: 'app-evidence-form',
  templateUrl: './evidence-form.component.html',
  styleUrls: ['./evidence-form.component.scss']
})
export class EvidenceFormComponent implements OnInit {

  @Input() evidence: evidence;
  @Input() businessUnit: businessUnit[] = [];
  @Input() course: course[] = [];
  @Input() product: product[] = [];
  @Input() hotsites: hotsites[] = [];
  @Input() area: areas[] = [];
  tinymceKey: string = environment.tinymceKey;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    
  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
    };
  }

  removeImage(){
    this.evidence.image = undefined;
  }

  

  handleOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.evidence.image = data[0].source;
  }

  handleOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }
}
