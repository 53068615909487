import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkedCourseService {

  constructor() { }

  getLevels() {
    return [
      {
        "code": "1",
        "name": "FIC"
      },
      {
        "code": "2",
        "name": "Técnico"
      },
      {
        "code": "3",
        "name": "Superior"
      },
      {
        "code": "4",
        "name": "Extensão"
      },
      {
        "code": "6",
        "name": "Pós-Graduação"
      }
    ];
  }

}
