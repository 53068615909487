import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const allowedModule = next.data.allowedModule;
    const canAccess = await Promise.resolve(this.authorizationService.userHasAccessToSelectedModule(allowedModule));

    if (!canAccess) {
      this.router.navigate(['/accessdenied']);
    } 

    return this.authorizationService.userHasAccessToSelectedModule(allowedModule);
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const allowedModule = next.data.allowedModule;
    const canAccess = await Promise.resolve(this.authorizationService.userHasAccessToSelectedModule(allowedModule));

    if (!canAccess)
      this.router.navigate(['/accessdenied']);

    return this.authorizationService.userHasAccessToSelectedModule(allowedModule);
  }
}