<content-header title="Arquivos">
  <div content-text>
      Nesta tela você poderá cadastrar e administrar os arquivos.      
  </div>  
  <div content-action>    
      <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
          <i class="fa fa-plus margin-right-8"></i> Criar
      </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..."
          [(ngModel)]="searchByResult" (keydown.enter)="getMedia()">
      <button nbSuffix nbButton ghost (click)="getMedia()">
        <nb-icon icon="search-outline" pack="eva"> </nb-icon>
      </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
      <div class="alert alert-warning col-12" role="alert"
      *ngIf="medias.length == 0 && isinit && !errorOnLoaging && paginationSearch">
        Nenhum arquivo encontrado.
      </div>
      <div class="alert alert-danger col-12" role="alert"
      *ngIf="medias.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
        Nenhum arquivo cadastrado.
      </div>
      <div class="col-12 mx-auto table-responsive-sm no-padding">
          <div class="table-container">
              <table class="table table-striped table-hover table-fixed" *ngIf="medias.length > 0">
                  <thead>
                      <tr>
                        <th class="siteKey">Chave</th>                       
                        <th class="description">Descrição</th>
                        <th class="alternativeDescription">Arquivo</th>
                        <th class="options">Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let m of medias">
                        <td class="title-grid">
                          {{m.mediaSiteKey?.siteKey}}
                        </td>
                        <td>
                          {{m.mediaSiteKey?.description}}
                        </td>
                        <td>
                          {{m.alternativeDescription}}
                        </td>
                        <td class="td-action">
                          <btn-drop-action (onEdit)="onEdit(m)" (onRemove)="onRemove(m)" (onDownload)="onDownload(m)" canDownload="true">...</btn-drop-action>
                        </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </nb-card-body>
  <!-- Begin Pagination -->  
  <div class="pagination-container" *ngIf="!paginationSearch && medias.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="getMedia($event)">
    </pagination>
  </div>
  <div class="pagination-container" *ngIf="paginationSearch && medias.length > 0">
    <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
      [totalPage]="totalPage" (goPage)="getMedia($event)"></pagination>
  </div>
  <div class="table-info-container" *ngIf="medias.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-media" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão deste arquivo?</div>
  <div body *ngIf="!removeAction">
      <app-media-form 
        [media]="media" 
        [mediaSiteKeys]="mediaSiteKeys"
        [saveMedia]="saveMedia"
        (saveSuccess)="saveSuccess($event)"
        (saveError)="saveError($event)"
        [resetMedia]="resetMedia"
      ></app-media-form>
  </div>
</modal>
<!-- End Modal-->