import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRountingModule } from './shared-routing.module';
import { PageNotFoundComponent } from './@pages/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './@pages/internal-server-error/internal-server-error.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './components/modal/modal.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { HeaderComponent } from './templates/header/header.component';
import { FooterComponent } from './templates/footer/footer.component';
import { SearchComponent } from './components/search/search.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { LoadingService } from './services/loading.service';
import { SelectivePreloadingStrategy } from './strategies/selective-preloading-strategy';
import { CanDeactivateGuard } from './strategies/can-deactivate-guard.service';
import { MenuComponent } from './templates/menu/menu.component';
import { NbMenuModule, NbButtonModule, NbActionsModule, NbSelectModule, NbUserModule, NbProgressBarModule, NbPopoverModule, NbToggleModule, NbContextMenuModule } from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { HelpComponent } from './@pages/help/help.component';
import { ApiClientService } from './services/api-client.service';
import { HttpClientModule } from '@angular/common/http';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { BtnDropActionComponent } from './components/btn-drop-action/btn-drop-action.component';
import { FilterPipe } from './pipes/filter.pipe';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DropdownCardComponent } from './components/dropdown-card/dropdown-card.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { UploadComponent } from './components/upload/upload.component';
import { TagsComponent } from './components/tags/tags/tags.component';
import { MinioImageComponent } from './components/minio-image/minio-image.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    InternalServerErrorComponent,
    ModalComponent,
    NotificationsComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    PaginationComponent,
    MenuComponent,
    HelpComponent,
    ContentHeaderComponent,
    BtnDropActionComponent,
    FilterPipe,
    DropdownCardComponent,
    UploadComponent,
    TagsComponent,
    MinioImageComponent
  ],
  imports: [
    FormsModule,
    NbMenuModule.forRoot(),
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NbUserModule,
    NbPopoverModule,
    NbProgressBarModule,
    NbToggleModule,
    CommonModule,
    HttpClientModule,
    SharedRountingModule,
    AutocompleteLibModule,
    BsDropdownModule.forRoot(),
    NbMomentDateModule,
    NbContextMenuModule,
    NzUploadModule,
    NzModalModule,
    NzButtonModule,
    NzIconModule,
    NzTagModule
  ],
  exports: [
    ModalComponent,
    NotificationsComponent,
    HeaderComponent,
    FooterComponent,
    SearchComponent,
    PaginationComponent,
    MenuComponent,
    ContentHeaderComponent,
    BtnDropActionComponent,
    DropdownCardComponent,
    UploadComponent,
    TagsComponent,
    MinioImageComponent
  ],
  providers: [
    CanDeactivateGuard,
    SelectivePreloadingStrategy,
    LoadingService,
    ModalService,
    ApiClientService
  ]
})
export class SharedModule { }
