import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';
import { SenacIndexComponent } from './@pages/senac-index/senac-index.component';
const routes: Routes = [
  {
    path: 'meu-senac/index',
    component: SenacIndexComponent,
    canActivate: [AuthenticationGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class SenacRoutingModule { }
