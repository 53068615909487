import { environment } from 'src/environments/environment';
import { Component, Input, OnChanges } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'minio-image',
  template: `<img [style]="style" [class]="class" [src]="dataUrl$|async"/>`
})
export class MinioImageComponent implements OnChanges  {
  @Input() private src: string;

  @Input() style: string;

  @Input() class: string;

  private src$ = new BehaviorSubject(this.src);

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));

  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer) {
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient
      .get(`${environment.urls.api}/media/object/${url}`, {responseType: 'blob'})
      .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }
}
