import { LinkedCourseService } from './../../linked-course.service';
import { Component, OnInit, Input } from '@angular/core';
import { linkedCourse } from '../../@model/linkedCourse';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { course } from 'src/app/shared/@models/course';
import { product } from 'src/app/shared/@models/product';
import { ResponseMessage } from './../../../shared/@models/responseMessage';

@Component({
  selector: 'app-linkedCourse-form',
  templateUrl: './linkedCourse-form.component.html',
  styleUrls: ['./linkedCourse-form.component.scss']
})
export class LinkedCourseFormComponent implements OnInit {

  @Input() linkedCourse: linkedCourse;

  @Input() courseDataSource: TransferItem[] = [];

  levels: any[];

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService,
    private linkedCourseService: LinkedCourseService
  ) {

  }

  ngOnInit(): void {
    this.levels = this.linkedCourseService.getLevels();
    this.getCourses(1, this.linkedCourse.levelEducationCode);
  }

  getLevels() {
    return this.linkedCourseService.getLevels();
  }

  getCourses(n: number = 1, level: string): void {
    if (!level) {
      return;
    }

    const endpoint = this.getCourseEndpointByLevel(level);
    const ret: Array<TransferItem & { description: string }> = [];


    const pCourses = this.apiClientService.getPaginated(environment.urls.courseApi, endpoint, n, 20000);
    const pLCC = this.apiClientService.getPath<ResponseMessage>(environment.urls.api, `linkedCourse/courses/${level}`);

    Promise.all([pCourses, pLCC])
      .then(values => {
        const courses = values[0].data as course[];

        if (courses != null) {
          const lcc = values[1].data as string[];

          courses.forEach((d: course | product) => {
            const description = (d as course).documentDescription || (d as product).documentName;
            const direction = this.linkedCourse.linkedCourseCourses.map(m => m).includes(d.integrationCode) ? "right" : undefined;
            const disabled = lcc.includes(d.integrationCode);

            if (direction !== "right") {
              ret.push({
                key: d.integrationCode,
                title: description,
                description,
                direction,
                disabled
              });
            }
          });
        }

        this.courseDataSource = ret;
      });
  }

  getCourseEndpointByLevel(level: string) {
    let path = '';

    switch (level) {
      case '1':
      case '4':
        path = `Product/GetByIsWeb/${level}`;
        break;

      case '2':
      case '3':
      case '6':
        path = `Course/GetByIsWeb/${level}`;
        break;
    }

    return path;
  }

  changeLevelLearning() {
    if (this.linkedCourse.levelEducationCode == 'S') {
      this.courseDataSource = [];
      return;
    }

    this.getCourses(1, this.linkedCourse.levelEducationCode);
  }

  filterOption(inputValue: string, item: any): boolean {
    return item && item.description && item.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  }

  change(ret: any): void {
    this.addCourse(ret);
    this.removeCourse(ret);
  }

  onChangeTag(tags: any): void {
    this.linkedCourse.keywords = tags;
  }

  private addCourse(ret: any) {
    if (ret.from == "left") {
      (ret.list || []).forEach((course: { key: string; }) => {
        this.linkedCourse.linkedCourseCourses = this.linkedCourse.linkedCourseCourses.concat(course.key);
      });
    }
  }

  private removeCourse(ret: any) {
    if (ret.from == "right") {
      (ret.list || []).forEach((course: { key: string; }) => {
        this.linkedCourse.linkedCourseCourses.splice(this.linkedCourse.linkedCourseCourses.findIndex(item => item === course.key), 1);
      });
    }
  }
}
