import { BaseEntity } from '../../shared/@models/baseEntity';
import { MediaSiteKey } from './media-site-key';

export class Media extends BaseEntity {
    source: string;
    alternativeDescription: string;
    mediaSiteKeyId: string;
    businessUnitId: string;
    mediaSiteKey: MediaSiteKey;

    constructor(
        id?: string,
        source?: string,
        alternativeDescription?: string,
        mediaSiteKeyId?: string,
        businessUnitId?: string,
        mediaSiteKey?: MediaSiteKey
    ) {
        super();
        this.id = id;
        this.source = source;
        this.alternativeDescription = alternativeDescription;
        this.mediaSiteKeyId = mediaSiteKeyId;
        this.businessUnitId = businessUnitId;
        this.mediaSiteKey = mediaSiteKey;
    }
}
