<div class="content-header">
    <h6>
        {{title}}
    </h6>
    <p class="lead">
        {{description}} 
        <ng-content select="[content-text]"></ng-content>   
    </p>
    <div class="actions" >
        <ng-content select="[content-action]"></ng-content>                 
    </div>
</div>