<content-header title="Escolas">
  <div content-text>
    &nbsp;
  </div>
  <div content-action class='group-action'></div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar ..." [(ngModel)]="searchParameter" (keydown.enter)="doSearch()">
      <button nbSuffix nbButton ghost (click)="doSearch()">
        <nb-icon icon="search-outline" pack="eva"> </nb-icon>
      </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
    <!-- Alertas  -->
    <div class="alert alert-warning col-12" role="alert"
      *ngIf="schools.length == 0 && isinit && !errorOnLoaging && paginationSearch">
      Nenhuma escola encontrada.
    </div>
    <div class="alert alert-danger col-12" role="alert"
      *ngIf="schools.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
      Nenhuma escola cadastrada.
    </div>
    <!-- Tabela com a listagem dos Grupos de Acesso -->
    <div class="col-12 mx-auto table-responsive no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed" *ngIf="schools.length > 0">
          <thead>
            <tr>
              <th (click)="sortResult()" class="name">
                <b>Escola&nbsp;</b>
                <i class="fa fa-chevron-up" *ngIf="!direction"></i>
                <i class="fa fa-chevron-down" *ngIf="direction"></i>
              </th>
              <th><b>Cidade/UF</b></th>
              <th><b>Ativo</b></th>
              <th><b>Ações</b></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let school of schools" style="max-width: 244px; overflow: hidden;">
              <td>{{ school.name | slice:0:90 }}</td>
              <td>
                {{ school.unitAdress.city | uppercase }} / {{ school.unitAdress.state | uppercase }}
              </td>
              <td>
                <nb-checkbox [disabled]="true" [ngModel]="school.show"></nb-checkbox>
              </td>
              <td class="td-action">
                <btn-drop-action (onEdit)="onEdit(school)" [canRemove]="false"></btn-drop-action>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Begin Pagination -->
      <div class="pagination-container" *ngIf="!paginationSearch && schools.length > 0">
        <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
          (goPage)="getSchools($event)">
        </pagination>
      </div>
      <div class="pagination-container" *ngIf="paginationSearch && schools.length > 0">
        <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
          [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
      </div>
      <div class="table-info-container" *ngIf="schools.length > 0">
        <div class="table-info-label">
          <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
        </div>
      </div>
      <!-- End Pagination -->
    </div>
  </nb-card-body>
</nb-card>
<!-- Modal -->
<modal id="modal-school" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="!removeAction">
    <app-school-form *ngIf="show" [school]="selectedSchool" [isEdit]="edit">
    </app-school-form>
  </div>
</modal>
