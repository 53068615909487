import { Component, OnInit } from '@angular/core';
import { article } from '../../@model/article';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { hotSites } from 'src/app/shared/@models/hotSites';
import { areas } from 'src/app/shared/@models/areas';

@Component({
  selector: 'app-article-index',
  templateUrl: './article-index.component.html',
  styleUrls: ['./article-index.component.scss']
})
export class ArticleIndexComponent implements OnInit {
  article: article = new article();
  articles: article[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "Order";
  show: boolean = true;
  businessUnit: businessUnit[] = [];
  hotSites: hotSites[] = [];
  areas: areas[] = [];

  errorOnLoaging: boolean = false;
  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchParameterDescription: string = "";

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.getArticle(1, this.order);
    this.getBusinessUnit(1, this.order);
    this.getHotSitesUnit(1, this.order);
    this.getAreas(1, this.order);
  }


  onCreate() {
    this.removeAction = false;
    this.article = new article();
    this.article.hotSitesId = [];
    this.article.text = "";
    this.article.show = true;
    this.modalService.modal('modal-article').title = "Criar Artigo";
    this.modalService.modal('modal-article').to = "modal";
    this.modalService.modal('modal-article').labelAction = "Salvar";
    this.modalService.modal('modal-article').labelClose = "Cancelar";
    this.modalService.modal('modal-article').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-article');
  }

  onEdit(article: article): void {
    this.article = Object.assign({}, article);
    this.removeAction = false;
    this.modalService.modal('modal-article').title = "Editar Artigo";
    this.modalService.modal('modal-article').to = "modal";
    this.modalService.modal('modal-article').labelAction = "Salvar";
    this.modalService.modal('modal-article').labelClose = "Cancelar";
    this.modalService.modal('modal-article').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-article');
  }

  onRemove(article: article): void {
    this.article = article;
    this.removeAction = true;
    this.modalService.modal('modal-article').title = "Excluir Artigo";
    this.modalService.modal('modal-article').to = "modal";
    this.modalService.modal('modal-article').labelAction = "Excluir";
    this.modalService.modal('modal-article').labelClose = "Cancelar";
    this.modalService.modal('modal-article').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-article');
  }

  onDetails(article: article): void {
    this.removeAction = false;

    this.article = Object.assign({}, article);

    this.modalService.modal('modal-article').title = "Detalhes do Artigo";
    this.modalService.modal('modal-article').to = "modal";
    this.modalService.modal('modal-article').labelClose = "Fechar";
    this.modalService.modal('modal-article').footerAction = false;
    this.modalService.modal('modal-article').noAction = true;
    this.modalService.open('modal-article');
  }



  sortResult() {
    if (this.direction == false) {
      this.order = "desc";
      this.getArticle(1, this.order);
      this.direction = true;
    }
    else {
      this.order = "asc";
      this.getArticle(1, this.order);
      this.direction = false;
    }
  }


  getArticle(n: number = 1, order) {
    this.isinit = false;
    this.articles = [];
    this.apiClientService.getPaginated(environment.urls.api, "article", n, 30, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.articles.push(d);
        });

      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  getBusinessUnit(n: number = 1, order) {
    this.apiClientService.getAll(environment.urls.api, "BusinessUnit").then((data: any) => {
      this.businessUnit = data;
    });
  }

  getHotSitesUnit(n: number = 1, order) {
    this.apiClientService.getPaginated(environment.urls.api, "hotsite", n, 20000, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.hotSites = [];
        data.data.forEach(d => {
          this.hotSites.push(d);
        });

      }
    }).finally(() => {
    });
  }

  getAreas(n: number = 1, order) {
    this.apiClientService.getPath(environment.urls.genericApi, "GetAreas").then((data: any) => {
      if (data != null) {
        this.areas = [];
        data.forEach(d => {
          this.areas.push(d);
        });

      }
    }).finally(() => {
    });
  }

  doSearch(n: number = 1) {

    if (!this.searchParameterDescription) {
      this.getArticle(n, this.order);
      return
    }

    let cmd = {
      "description": this.searchParameterDescription
    };

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.articles.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, "article/search", cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.articles.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
        this.isSearchFinish = true;
      });
  }



  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<article>(environment.urls.api, 'article', this.article.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.article !== null && this.validateCurrent()) {
        this.apiClientService.save<article>(environment.urls.api, 'article', this.article, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  callbackSuccess = (resp: any) => {
    let msg = `Artigo ` + (this.removeAction ? `excluído` : this.article.id !== undefined && this.article.id !== null ? `alterado` : `criado`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.getArticle(1, this.order);
    this.modalService.close('modal-article');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.article.title === null || this.article.title === undefined || this.article.title === '') {
      this.toastr.error("Campo Título deve ser preenchido.");
      response = false;
    }

    if (this.article.autor === null || this.article.autor === undefined || this.article.autor === '') {
      this.toastr.error("Campo Autor deve ser preenchido.");
      response = false;
    }

    if (this.article.formation === null || this.article.formation === undefined || this.article.formation === '') {
      this.toastr.error("Campo Formação deve ser preenchido.");
      response = false;
    }

    if (this.article.text === null || this.article.text === undefined || this.article.text === '') {
      this.toastr.error("Campo Texto deve ser preenchido.");
      response = false;
    }

    if (!this.article.businessUnitId) {
      this.toastr.error("Campo Escola deve ser preenchido.");
      response = false;
    } else {
      this.article.businessUnitName = this.businessUnit.find(param => param.unitCode == this.article.businessUnitId).name;
    }

    if (this.article.areaId === undefined || this.article.areaId === null || this.article.areaId === '') {
      this.toastr.error("Campo Area deve ser preenchido.");
      response = false;
    } else {
      this.article.areaName = this.areas.find(param => param.$id == this.article.areaId).areaDescription;
    }


    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }



}