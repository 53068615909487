<nb-tabset class="my-nb-tabset">

  <nb-tab tabTitle="Dados">
    <form #formDados class="col-12">
      <div class="form-group col-12">
        <label for="name">Nome do {{ typeName }}:&nbsp;&nbsp;</label>
        <input type="text" id="name" name="name" class="form-control col-12" [ngModel]="name" disabled>
      </div>

      <div class="form-group col-12">
        <label for="description">Nome do {{ typeName }} no Site:</label>
        <input type="text" id="siteName" name="siteName" [(ngModel)]="detail.siteName" class="form-control col-12"
          placeholder="">
      </div>

      <div class="form-group col-sm-6">
        <label for="workload">Carga Horária:</label>
        <input type="number" id="dtpWorkload" name="dtpWorkload" [(ngModel)]="detail.workload"
          class="form-control col-12" placeholder="Carga horária">
      </div>

      <div class="form-group col-12">
        <label>Nível:</label><br>
        {{ level }}
      </div>
      <div class="form-group col-12">

      </div>

      <div class="form-group col-12">
        <label for="color">Área:</label><br>
        <nb-select id="area" placeholder="Selecione..." [(selected)]="detail.areaId" class="select-color">
          <nb-option class="wrapper-icon-color" *ngFor="let a of areas" [value]="a.areaCode">
            <span class="descriptive-text">{{a?.areaDescription}}</span>
          </nb-option>
        </nb-select>
      </div>

      <div class="form-group col-12">
        <label for="color" class="col-12">Apresentação:</label>
        <upload [maxLimitFiles]="1" (onUploadSuccess)="handleOnUploadSuccessPdf($event)"
          (onUploadFailed)="handleOnUploadSuccessError($event)" [fileTypes]="'application/pdf'"></upload>

        <div class="form-group col-12" *ngIf="detail.presentationFile">
          <a href target="_blak" [href]="handlePdf()">{{handlePdf()}}</a>
        </div>
      </div>

      <div class="form-group col-12">
        <label for="color" class="col-12">Foto:</label>
        <upload [maxLimitFiles]="1" (onUploadSuccess)="handleOnUploadSuccess($event)"
          (onUploadFailed)="handleOnUploadSuccessError($event)"></upload>

        <div class="form-group col-12" *ngIf="detail.photoFile">
          <minio-image style="max-height: 200px;" class="stretch" [src]="detail.photoFile"></minio-image>
        </div>
      </div>

      <div class="form-group col-12">
        <label>Url Externa:</label>
        <input type="urlExternal" [(ngModel)]="detail.urlExternal" name="urlExternal" class="form-control col-12"
          placeholder="Url Externa" id="photoVLibras">
      </div>

      <div class="form-group col-12">
        <label>Descrição Imagem (VLibras):</label>
        <input type="text" [(ngModel)]="detail.photoVLibras" name="photoVLibras" class="form-control col-12"
          placeholder="Descrição Imagem (VLibras)" id="photoVLibras">
      </div>

      <div class="form-group col-12">
        <label>Certificação:</label>
        <input type="checkbox" [(ngModel)]="detail.certification" [checked]="detail.certification" name="v"
          id="certification" style="margin-left: 5px;">
      </div>

      <div class="form-group col-12">
        <label>Ativo:</label>
        <input type="checkbox" [(ngModel)]="detail.activeSite" [checked]="detail.activeSite"
          name="activeSite" id="activeSite" style="margin-left: 5px;">
      </div>

      <div class="form-group col-12">
        <label>Venda e-commerce:</label>
        <input type="checkbox" [(ngModel)]="detail.turmaEcommerce" [checked]="detail.turmaEcommerce"
          name="turmaEcommerce" id="turmaEcommerce" style="margin-left: 5px;">
      </div>

      <div class="form-group col-12">
        <label>Palavras-chave (separado por ','):</label>
        <input type="text" [(ngModel)]="detail.keyWords" name="keyWords" class="form-control col-12"
          placeholder="Palavras-chave (separado por  ',')" id="keyWords">
      </div>

    </form>
  </nb-tab>

  <nb-tab tabTitle="Informações Complementares" [lazyLoad]="true">
    <div>
      <button nbButton *ngFor="let detail of listDetails() | keyvalue" (click)="click(detail.key)"
        [class.selectedButton]="isSelectedButton(detail.key)"
      >{{ detail.value }}</button>
    </div>

    <form class="col-12">
        <div class="form-group col-12">
          <editor [(ngModel)]="detail[selectedDetail]" [ngModelOptions]="{standalone: true}"
            [init]="editorInit" [apiKey]="tinymceKey" [disabled]="!anySelectedButton()"></editor>
          </div>
    </form>
  </nb-tab>

</nb-tabset>
