<div class="btn-group btn-drop-action" dropdown>
  <ul id="dropdown-btn-action" *dropdownMenu class="dropdown-menu list-inline" role="menu"
    aria-labelledby="button-basic" (click)="$event.stopPropagation()">
    <li role="menuitem" class="list-inline-item">
      <a class="dropdown-item btn btn-lg btn-remove" href="javascript:void(0)" (click)="register($event)" title="Inscrições">
        <i class="fas fa-list"></i>
      </a>
    </li>

    <li role="menuitem" class="list-inline-item">
      <a class="dropdown-item btn btn-lg btn-edit" href="javascript:void(0)" (click)="edit($event)">
        <i class="fas fa-edit"></i>
      </a>
    </li>

    <li role="menuitem" class="list-inline-item">
      <a class="dropdown-item btn btn-lg btn-remove" href="javascript:void(0)" (click)="remove($event)">
        <i class="far fa-trash-alt"></i>
      </a>
    </li>
  </ul>

  <button id="dropdown-btn-action" dropdownToggle type="button" class="btn btn-lg dropdown-toggle btn-action"
    aria-controls="dropdown-btn-action">
    <i class="fas fa-ellipsis-v"></i>
  </button>
</div>
