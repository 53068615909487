import { BaseEntity } from '../../shared/@models/baseEntity';

export class Subscriber extends BaseEntity {
  eventId: string;
  cpf: string;
  name: string;
  email: string;
  phone: string;
  cellphone: string;
  subscriberTypeId: string;
}
