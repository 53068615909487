import { CourseFormComponent } from './../course-form/course-form.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Moment } from 'moment';
import { unix } from 'ngx-bootstrap/chronos/utils/date-getters';
import { ToastrService } from 'ngx-toastr';
import { areas } from 'src/app/shared/@models/areas';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { course } from '../../@model/course';
import { Product } from '../../@model/product';
import { GenericDetail } from '../../@model/generic-detail';

@Component({
  selector: 'app-course-index',
  templateUrl: './course-index.component.html',
  styleUrls: ['./course-index.component.scss']
})
export class CourseIndexComponent implements OnInit {

  areas: areas[] = [];
  areasFilter: areas[] = [];
  genericDetail: GenericDetail = new GenericDetail();
  courses: course[] | Product[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "asc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = '';
  errorOnLoaging: boolean = false;


  name: string = '';
  levelEducation: string = '';
  selectedOption: string = "course";
  selectedArea: string = '';
  activeSiteOnly: boolean = true;

  @ViewChild('courseForm', { read: CourseFormComponent }) courseForm: CourseFormComponent;


  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.doSearch(1);
    this.getAreas(1, this.order);
  }

  getCourse(pageNumber: number = 1, order) {
    this.isinit = false;
    this.paginationSearch = false;
    this.courses = [];

    this.apiClientService.getPaginated(
      environment.urls.courseApi, this.selectedOption + "/detail",
      pageNumber, this.pageSize, order)
      .then((response: ResponseMessage) => {
        if (response != null && response.rows > 0) {
          this.configuraLocalPagination(response);
          this.getData(response.data);
        }
      }).finally(() => {
        this.isinit = true;
      });
  }

  private getData(data: any[]) {
    if (this.selectedOption === 'course') {
      this.courses = data as course[];
    } else {
      this.courses = data as Product[];
    }
  }

  private configuraLocalPagination(response: ResponseMessage) {
    this.pageSize = response.pageSize;
    this.page = response.page;
    this.rows = response.rows;
    this.totalRows = response.totalRows;
    this.totalPage = response.totalPage;
  }

  doSearch(pageNumber: number = 1) {
    if (!this.searchByResult && !this.selectedArea && this.activeSiteOnly === false) {
      this.getCourse(1, this.order);
      return;
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.courses.length = 0;

    const url = `${this.selectedOption}/detail`;
    const filters = `?search=${this.searchByResult}&areaId=${this.selectedArea}&activeSiteOnly=${this.activeSiteOnly}`;

    this.apiClientService.getPaginated(
      environment.urls.courseApi, url + filters,
      pageNumber, this.pageSize, this.order)
      .then((response: ResponseMessage) => {
        if (response != null && response.rows > 0) {
          this.configuraLocalPagination(response);
          this.getData(response.data);
        }
      }).finally(() => {
        this.isinit = true;
      });
  }

  onEdit(item: course | Product): void {
    let title = '';

    if (this.selectedOption === 'course') {
      title += 'curso';
      this.prepareCourseForEdition(item);
    } else {
      title += 'produto';
      this.prepareProductForEdition(item);
    }

    this.removeAction = false;
    this.modalService.modal('modal-course').title = `Editar ${title}`;
    this.modalService.modal('modal-course').to = "modal";
    this.modalService.modal('modal-course').labelAction = "Salvar";
    this.modalService.modal('modal-course').labelClose = "Cancelar";
    this.modalService.modal('modal-course').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-course');
  }


  private prepareCourseForEdition(item: course | Product) {
    const course = item as course;

    this.name = course.documentDescription;
    this.levelEducation = course.levelEducation;

    this.genericDetail = course.courseDetail ?? new GenericDetail();
    this.genericDetail.courseId = course.id;
  }

  private prepareProductForEdition(item: course | Product) {
    const product = item as Product;

    this.name = product.documentName;
    this.levelEducation = product.levelEducation;

    this.genericDetail = product.productDetail ?? new GenericDetail();
    this.genericDetail.productId = product.id;
  }

  getAreas(n: number = 1, order) {
    this.apiClientService.getPath(environment.urls.genericApi, "GetAreas").then((data: any) => {
      if (data != null) {
        this.areas = [];
        this.areasFilter = [];

        this.areasFilter.push(new areas("0","","Todas"))
        
        data.forEach(d => {
          this.areas.push(d);
          this.areasFilter.push(d);
        });
      }
    }).finally(() => {
    });
  }

  saveData(): void {

    this.normalizeHours(this.genericDetail);

    if (this.validateCurrent()) {
      this.apiClientService.save<GenericDetail>(
        environment.urls.courseApi, this.selectedOption + 'Detail',
        this.genericDetail,
        this.callbackSuccess,
        this.callbackErr);
    }
  }

  callbackSuccess = (resp: any) => {
    let itemName = '';
    let itemId: string;

    if (this.selectedOption === 'course') {
      itemName = 'Curso';
      itemId = resp?.courseId;
    } else {
      itemName = 'Produto';
      itemId = resp?.productId;
    }

    const action = this.removeAction ? `excluído` : itemId !== undefined && itemId !== null ? `alterado` : `criado`;
    const msg = `${itemName} ${action} com sucesso!`;

    this.toastr.success(msg);
    this.getCourse(1, this.order);
    this.modalService.close('modal-course');
  }

  private normalizeHours(detail: GenericDetail) {
    this.genericDetail.workload = this.convertMomentToUtcDate(detail.workload);
  }

  private convertMomentToUtcDate(moment) {
    try {
      let m = (moment as unknown) as Moment;
      m.utc(true);
      return m.toDate();
    } catch {
      return moment;
    }
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.genericDetail.siteName === null || this.genericDetail.siteName === undefined || this.genericDetail.siteName === '') {
      this.toastr.error(`Campo Nome do ${this.selectedOption === 'curse' ? 'Curso' : 'Produto' } no Site deve ser preenchido.`);
      response = false;
    }

    if (this.genericDetail.workload === null || this.genericDetail.workload === undefined) {
      this.toastr.error("Campo Carga Horária deve ser preenchido.");
      response = false;
    }

    if (this.genericDetail.areaId === undefined || this.genericDetail.areaId === null || this.genericDetail.areaId === '') {
      this.toastr.error("Campo Área deve ser preenchido.");
      response = false;
    }

    if (this.genericDetail.photoFile === null || this.genericDetail.photoFile === undefined || this.genericDetail.photoFile === '') {
      this.toastr.error("Campo Foto deve ser preenchido.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;

    if (this.courseForm) {
      this.courseForm.clear();
    }

    setTimeout(() => {
      this.show = true;
    }, 10);
  }


  normalizeArea(id: number): string {
    if (id != undefined)
      return this.areas?.find(param => param.$id === id.toString())?.areaDescription;
    else
      return "";
  }

  normalizeCertication(p: boolean): string {
    if (p != undefined)
      return p ? "SIM" : "NÃO";
    else
      return "";
  }
}
