export class UnitAdress {
    id: string;
    integrationCode: string;
    businessUnitId: string;
    completeAddress: string;
    address: string;
    number: string;
    mailBox: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    googleMapsUrl: string;
    insertedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    commited: boolean;
    isActive: boolean;
    isRemoved: boolean;
}

export class Contact {
    id: string;
    businessUnitId: string;
    integrationCode: string;

    contactTypeDescription: string;
    contactType: string;
    areaCode: string;
    contact: string;
    primaryContact: string;
    sector: string;

    insertedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    commited: boolean;
    isActive: boolean;
    isRemoved: boolean;
}

export class Region {
    regionCode: string;
    description: string;
    active: boolean;
    id: string;
    insertedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    commited: boolean;
    isActive: boolean;
    isRemoved: boolean;
}

export class Photo {
    id: string;
    photoGalleryId: string;
    photoData: string;
    description: string;
    vLibrasDescription: string;
    isActive: boolean;
}

export class School {
    id: string;
    integrationCode: string;

    name: string;
    unitCode: string;
    siteDescription: string;
    directorName: string;
    directorSex: string;
    about: string;
    facebookUrl: string;
    instagramUrl: string;

    directorPhoto: string;
    vLibrasDirectorDescription: string;
    buildFacedePhoto: string;
    vLibrasBuildFacedeDescription: string;

    unitAdress: UnitAdress;
    contacts: Contact[];
    photos: Photo[];

    region: Region;
    regionCode: string;
    regionDescription: string;
    regionId: string;

    insertedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    commited: boolean;
    isActive: boolean;
    isRemoved: boolean;
    show: boolean;
    openModel: boolean;

    openingHoursWorkDays: Date;
    closingHoursWorkDays: Date;
    openingHoursWeekend: Date;
    closingHoursWeekend: Date;
}