<content-header title="Eventos">
  <div content-text>
    Nesta tela você poderá cadastrar e administrar seus Eventos.
  </div>
  <div content-action>
    <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
      <i class="fa fa-plus margin-right-8"></i> Criar
    </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..." [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
      <button nbSuffix nbButton ghost (click)="doSearch()">
        <nb-icon icon="search-outline" pack="eva"> </nb-icon>
      </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
    <div class="alert alert-danger col-12" *ngIf="events?.length === 0">
      Nenhum Evento cadastrado.
    </div>
    <div class="col-12 mx-auto table-responsive-sm no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed">
          <thead>
            <tr>
              <th class="title">Título</th>
              <th class="title">Local</th>
              <th class="title">Tipo</th>
              <th class="date">Data Início</th>
              <th class="date">Data Inclusão</th>
              <th class="title">Qtd de Vagas</th>
              <th class="title">Qtd de Inscritos</th>
              <th class="check">Ativo</th>
              <th class="options">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let e of events">
              <td class="title-grid">{{e.title}}</td>
              <td>{{e.localization}}</td>
              <td>{{getEventTypeName(e.eventTypeId)}}</td>
              <td>{{e.dateInitial | date: 'dd/MM/yyyy'}}</td>
              <td>{{e.dateFinal | date: 'dd/MM/yyyy'}}</td>
              <td>{{e.quantityForRegistration}}</td>
              <td>{{e.subscribers?.length}}</td>
              <td>
                <input type="checkbox" [checked]="e.active" disabled>
              </td>
              <td class="td-action">
                <app-event-btn-drop-action (onEdit)="onEdit(e)" (onRemove)="onRemove(e)" (onRegister)="onRegister(e)">...</app-event-btn-drop-action>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
  <!-- Begin Pagination -->
  <div class="pagination-container" *ngIf="events.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="doSearch($event)">
    </pagination>
  </div>
  <div class="table-info-container" *ngIf="events.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-event" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão deste Evento?</div>
  <div body *ngIf="!removeAction">
    <app-event-form [event]="event" [eventTypes]="eventTypes" #eventForm></app-event-form>
  </div>
</modal>
<!-- End Modal-->
