import { NgModule } from '@angular/core';
import { NewsIndexComponent } from './@pages/news-index/news-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';

const routes: Routes = [
  {
    path: 'news/index',
    component: NewsIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class NewsRoutingModule { }
