import { Component, ElementRef, Input, OnInit, EventEmitter } from '@angular/core';
import { Contact, Photo, School, UnitAdress } from '../../@model/school';
import { ToastrService } from 'ngx-toastr';
import { ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { MediaSiteKey } from 'src/app/media/@model/media-site-key';
import { Media } from 'src/app/media/@model/media';

@Component({
  selector: 'app-school-form',
  templateUrl: './school-form.component.html',
  styleUrls: ['./school-form.component.scss'],
})
export class SchoolFormComponent implements OnInit {

  @ViewChild('inputFile') myInputVariable: ElementRef;
  @Input() school: School = new School();
  @Input() isEdit: boolean;

  contactAdd: Contact = new Contact();
  photoDescription: string = "";
  vlibrasDescription: string = "";
  pageSize: number = 30;
  removeAction: boolean = false;

  mediaSiteKeys: MediaSiteKey[] = [];

  private _media: Media = new Media();
  public set media(value: Media) {
      this._media = value;
      this.resetFile();
      if (this._media && this._media.mediaSiteKeyId) {
        this.onSelectedChangeMediaSiteKey(this._media.mediaSiteKeyId);
      }
  }
  public get media(): Media {
      return this._media;
  }

  medias: Media[] = [];

  description: string = '';
  typeFile: string = '';

  directionMedia: boolean = true;
  pageSizeMedia: number = 5;
  rowsMedia: number = 0;
  totalRowsMedia: number = 0;
  totalPageMedia: number = 1;
  pageMedia: number = 1;
  removeActionMedia: boolean = false;
  isinitMedia: boolean = false;
  orderMedia: string = "asc";

  isSearchFinishMedia: boolean = false;
  paginationSearchMedia: boolean = false;
  searchByResultMedia: string = "";
  errorOnLoagingMedia: boolean = false;

  saveMedia: EventEmitter<any> = new EventEmitter();
  resetMedia: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiClientService: ApiClientService,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.school != undefined && this.school != null) {
      if (this.school.unitAdress == undefined || this.school == null)
        this.school.unitAdress = new UnitAdress();

      if (this.school.photos == undefined || this.school.photos == null) {
        this.school.photos = new Array<Photo>();
      }

      this.media.businessUnitId = this.school?.unitCode;
    }

    this.getSchoolContacts();

    this.getMedia(1, this.orderMedia);

    this.getMediaSiteKey();
  }

  handleInputChange(e): void {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e): void {
    let reader = e.target;
    this.school.directorPhoto = reader.result;
  }

  removeImage() {
    this.school.directorPhoto = undefined;
  }

  handleInputChangeFachada(e): void {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoadedFachada.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoadedFachada(e): void {
    let reader = e.target;
    this.school.buildFacedePhoto = reader.result;
  }

  removeImageFachada() {
    this.school.buildFacedePhoto = undefined;
  }

  handleInputChangeSchool(e): void {
    if (this.photoDescription === undefined || this.photoDescription.length === 0 || this.photoDescription === "") {
      this.toastr.error("O campo descrição deve ser informado.");
      this.myInputVariable.nativeElement.value = '';
      return;
    }
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoadedSchool.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoadedSchool(e): void {
    let reader = e.target;

    let tempPhoto: Photo = new Photo();

    tempPhoto.description = this.photoDescription;
    tempPhoto.photoData = reader.result;

    this.school.photos.push(tempPhoto);
    this.myInputVariable.nativeElement.value = '';
  }

  removeImageSchool(photo: Photo) {
    const index = this.school.photos.indexOf(photo, 0);
    if (index > -1) {
      this.school.photos.splice(index, 1);
    }
  }

  onSelectedDirectorSexChange(_dirSex: string) {
    switch (_dirSex) {
      case "0":
        this.school.directorSex = "Masculino";
        break;
      case "1":
        this.school.directorSex = "Feminino";
        break;
    }
  }

  onSelectedContactTypeChange(value: any) {

  }

  addContac() {
    if (this.validateCurrent()) {
      this.removeAction = false;
      this.contactAdd.businessUnitId = this.school.id;

      this.apiClientService.save<Contact>(
        environment.urls.businessUnitApi, 'businessUnit/contacts', this.contactAdd, this.callbackSuccess, this.callbackErr);
    }

  }

  deleteContact(p: Contact) {
    const index = this.school.contacts.indexOf(p, 0);

    if (index > -1) {
      this.school.contacts.splice(index, 1);
      if (p.id != undefined) {
        this.removeAction = true;
        this.apiClientService.delete<Contact>(environment.urls.businessUnitApi, 'businessunit/contacts', p.id, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  clearForm() {
    this.contactAdd.contactType = undefined;
    this.contactAdd.contact = undefined;
    this.contactAdd.sector = undefined;
  }

  getSchoolContacts(n: number = 1, order: string = 'asc') {
    this.apiClientService.getPaginated(environment.urls.businessUnitApi, "businessUnit/contacts/unitId/" + this.school.id, n, this.pageSize, order)
      .then((data: ResponseMessage) => {
        this.school.contacts = new Array<Contact>();
        this.school.contacts.length = 0;
        if (data != null && data.rows > 0) {
          data.data.forEach(element => { this.school.contacts.push(element) });
        }
      })
      .finally(() => {
        // this.isinit = true;
      });
  }

  callbackSuccess = (resp: any) => {
    let msg = `Contato` + ' ' + (this.removeAction ? `excluído` : `adicionado`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.school.contacts.push(Object.assign({}, this.contactAdd));
    this.clearForm();
    this.getSchoolContacts();
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.contactAdd.contactType === null
      || this.contactAdd.contactType === undefined
      || this.contactAdd.contactType === '') {
      this.toastr.error("Campo tipo deve ser preenchido.");
      response = false;
    }

    if (this.contactAdd.contact === null
      || this.contactAdd.contact === undefined
      || this.contactAdd.contact === ''
    ) {
      this.toastr.error("Campo contato deve ser preenchido.");
      response = false;
    }

    if (this.contactAdd.sector === null
      || this.contactAdd.sector === undefined
      || this.contactAdd.sector === '') {
      this.toastr.error("Campo setor deve ser preenchido.");
      response = false;
    }
    return response;
  }

  handleDirectorPhotoOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.school.directorPhoto = data[0].source;
  }

  handleDirectorPhotoOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handleDirectorPhoto(): string {
    return this.school.directorPhoto ? `${environment.urls.minio}/${this.school.directorPhoto}` : null;
  }

  handleBuildFacedePhotoOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.school.buildFacedePhoto = data[0].source;
  }

  handleBuildFacedePhotoOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handleBuildFacedePhoto(): string {
    return this.school.buildFacedePhoto ? `${environment.urls.minio}/${this.school.buildFacedePhoto}` : null;
  }

  handlePhotosOnUploadSuccess(data: any): void {
    if (data && data.length > 0) {
      const photo = new Photo();
      photo.photoData = data[0].source;
      photo.isActive = true;
      photo.description = this.photoDescription;
      photo.vLibrasDescription = this.vlibrasDescription;
      this.school.photos.push(photo);
      this.clearPhotoForm();
    }
  }

  handlePhotosOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handlePhoto(photo: string): string {
    return photo ? `${environment.urls.minio}/${photo}` : null;
  }

  handlePhotoDisableUploadAction(): boolean {
    return !this.photoDescription || !this.vlibrasDescription;
  }

  handleRemoveBuildFacedePhoto() {
    if (this.school) {
      this.school.buildFacedePhoto = null;
    }
  }

  handleRemoveDirectorPhoto() {
    if (this.school) {
      this.school.directorPhoto = null;
    }
  }

  clearPhotoForm() {
    this.photoDescription = '';
    this.vlibrasDescription = '';
  }

  getMediaSiteKey() {
    this.apiClientService.getAll(environment.urls.api, 'Media/MediaSiteKey/true')
    .then((data: any) => {
      if (data && data.length > 0) {
        this.mediaSiteKeys = [];
        data.forEach(element => { this.mediaSiteKeys.push(element) });
      }
    })
  }

  getMedia(n: number = 1, order: string = this.orderMedia) {
    let cmd = {      
      'source': this.searchByResultMedia,
      'alternativeDescription': this.searchByResultMedia,
      'siteKey': this.searchByResultMedia,
      'siteKeyDescription': this.searchByResultMedia,
      'businessUnitId': this.school?.unitCode
    }

    this.paginationSearchMedia = true;
    this.isSearchFinishMedia = false;
    this.isinitMedia = false;
    this.errorOnLoagingMedia = false;
    this.medias.length = 0;    
    this.apiClientService.postPaginated(environment.urls.api, 'Media/search', cmd, n, this.pageSizeMedia, order)
    .then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.pageMedia = data.page;
        this.rowsMedia = data.rows;
        this.pageSizeMedia = data.pageSize
        this.totalRowsMedia = data.totalRows;
        this.totalPageMedia = data.totalPage;
        data.data.forEach(element => { this.medias.push(element) });
      }
    })
    .finally(() => {
      this.isinitMedia = true;
    });
  }

  editMedia(media: Media) {
    this.media = Object.assign({}, media);
    this.removeActionMedia = false;
  }

  removeMedia(media: Media) {
    this.media = media;
    this.removeActionMedia = true;
    this.apiClientService.delete<Media>(environment.urls.api, 'media', this.media.id, this.callbackSuccessMedia, this.callbackErr);
  }

  downloadMedia(media: Media) {
    if (media) {
      let url = environment.urls.api + '/Media/object/SiteKey/' + media.mediaSiteKey?.siteKey + '/';

      if (media.businessUnitId) {
        url = url + media.businessUnitId + '/';
      }
      
      window.open(url);
    }
  }

  public onSelectedChangeMediaSiteKey(id: any): void {
    this.resetFile();
    this.description = '';
    this.typeFile = '';
    if (this.mediaSiteKeys) {
      this.mediaSiteKeys.forEach(mediaSiteKey => { 
        if (mediaSiteKey.id == id) {
          this.description = mediaSiteKey.description;
          this.typeFile = mediaSiteKey.typeFile ?? undefined;
        }
      });
    }
  }
  
  saveMediaFile() {
    if (this.media !== null && this.validateCurrentMedia()) {
      this.saveFile();
    }
  }

  private saveFile() {
    if (this.saveMedia) {
      this.saveMedia.emit(null);
    }
  }

  private resetFile() {
    this.description = '';
    this.typeFile = '';
    if (this.resetMedia) {
      this.resetMedia.emit(null);
    }
  }

  saveFileSuccess(data: any): void {
    if (data && data.length > 0) {
      this.callbackSuccessMedia({});
    }
  }

  saveFileFailed(error: any): void {
    console.error(error);
    if (error && !error?.error) {
      this.toastr.error("Não foi possivel realizar o upload do arquivo!");
    }
  }

  callbackSuccessMedia = (resp: any) => {
    this.resetFile();
    let msg = 'Arquivo ' + (this.removeActionMedia ? 'excluído' : this.media.id !== undefined && this.media.id !== null ? 'alterado' : 'adicionado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getMedia(1, this.orderMedia);
    this.media = new Media();
    this.media.businessUnitId = this.school?.unitCode;
  } 

  validateCurrentMedia() {
    let response = true;

    if (this.media.mediaSiteKeyId === null || this.media.mediaSiteKeyId === undefined || this.media.mediaSiteKeyId === '') {
      this.toastr.error("Campo Chave no Site deve ser preenchido.");
      response = false;
    } 

    if (this.media.businessUnitId === null || this.media.businessUnitId === undefined || this.media.businessUnitId === '') {
      this.toastr.error("Campo Código da Escola deve ser preenchido.");
      response = false;
    } 

    return response;
  }

  clearMediaFile() {
    this.resetFile();
    this.media = new Media();
    this.media.businessUnitId = this.school?.unitCode;
  }
}
