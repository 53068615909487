import { BaseEntity } from './../../shared/@models/baseEntity';
import { SurveyBusinessUnit } from './survey-business-unit';

export class Survey extends BaseEntity {
  title: string;
  insertedUser: string;
  anotherAnswers: boolean;
  active: boolean;

  businessUnits: SurveyBusinessUnit[] = [];
  hotsites: string[] = [];
  alternatives: string[] = [];

  constructor() {
    super();
  }

}
