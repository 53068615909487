import { LinkedCourseService } from './../../linked-course.service';
import { Component, OnInit } from '@angular/core';
import { linkedCourse } from '../../@model/linkedCourse';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { course } from 'src/app/shared/@models/course';
import { product } from 'src/app/shared/@models/product';

@Component({
  selector: 'app-linkedCourse-index',
  templateUrl: './linkedCourse-index.component.html',
  styleUrls: ['./linkedCourse-index.component.scss']
})
export class LinkedCourseIndexComponent implements OnInit {

  linkedCourse: linkedCourse = new linkedCourse();
  linkedCourses: linkedCourse[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "desc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  transferItem: TransferItem[] = []

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService,
    private linkedCourseService: LinkedCourseService
  ) { }

  ngOnInit(): void {
    this.getLinkedCourse(1, this.order);
  }


  getLevelDescription(code: string): string {
    return this.linkedCourseService.getLevels().find(level => level.code === code)?.name;
  }

  onCreate() {
    this.removeAction = false;
    this.linkedCourse = new linkedCourse();
    this.linkedCourse.linkedCourseCourse = [];
    this.linkedCourse.linkedCourseCourses = [];
    this.linkedCourse.keywords = [];
    this.linkedCourse.levelEducationCode = "S";
    this.transferItem = [];
    this.linkedCourse.show = true;
    this.modalService.modal('modal-linkedCourse').title = "Criar Curso Unificado";
    this.modalService.modal('modal-linkedCourse').to = "modal";
    this.modalService.modal('modal-linkedCourse').labelAction = "Salvar";
    this.modalService.modal('modal-linkedCourse').labelClose = "Cancelar";
    this.modalService.modal('modal-linkedCourse').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-linkedCourse');
  }

  onEdit(linkedCourse: linkedCourse): void {
    this.linkedCourse = Object.assign({}, linkedCourse);
    this.transferItem = [];
    this.getCourses(1, linkedCourse);

    this.removeAction = false;
    this.modalService.modal('modal-linkedCourse').title = "Editar Curso Unificado";
    this.modalService.modal('modal-linkedCourse').to = "modal";
    this.modalService.modal('modal-linkedCourse').labelAction = "Salvar";
    this.modalService.modal('modal-linkedCourse').labelClose = "Cancelar";
    this.modalService.modal('modal-linkedCourse').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-linkedCourse');
  }

  onRemove(linkedCourse: linkedCourse): void {
    this.linkedCourse = linkedCourse;
    this.removeAction = true;
    this.modalService.modal('modal-linkedCourse').title = "Excluir Curso Unificado " + this.linkedCourse.courseName;
    this.modalService.modal('modal-linkedCourse').to = "modal";
    this.modalService.modal('modal-linkedCourse').labelAction = "Excluir";
    this.modalService.modal('modal-linkedCourse').labelClose = "Cancelar";
    this.modalService.modal('modal-linkedCourse').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-linkedCourse');
  }

  getLinkedCourse(n: number = 1, order) {
    this.isinit = false;
    this.paginationSearch = false;
    this.apiClientService.getPaginated(environment.urls.api, "linkedCourse/GetAllLinkedCourse", n, this.pageSize, order).then((data: ResponseMessage) => {
      this.linkedCourses = [];
      if (data != null && data.rows > 0) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.linkedCourses.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  getCourses(n: number = 1, course: linkedCourse) {
    const endpoint = this.getCourceEndpointByLevel(course.levelEducationCode);
    const ret: Array<TransferItem & { description: string }> = [];

    const pCourses = this.apiClientService.getPaginated(environment.urls.courseApi, endpoint, n, 20000);
    const pLCC = this.apiClientService.getPath<ResponseMessage>(environment.urls.api, `linkedCourse/courses/${course.levelEducationCode}`);

    Promise.all([pCourses, pLCC])
      .then(values => {
        const courses = values[0].data as course[];

        if (courses != null) {
          const lcc = values[1].data as string[];

          courses.forEach((d: course | product) => {
            const description = (d as course).documentDescription || (d as product).documentName;
            const direction = this.linkedCourse.linkedCourseCourses.map(m => m).includes(d.integrationCode) ? "right" : undefined;
            const disabled = lcc.includes(d.integrationCode) && !direction;

            ret.push({
              key: d.integrationCode,
              title: description,
              description,
              direction,
              disabled
            });
          });
        }

        this.transferItem = ret;
      });
  }

  getCourceEndpointByLevel(level: string) {
    let path = '';

    switch (level) {
      case '1':
      case '4':
      case 'F':
        path = `Product/GetByIsWeb/${level}`;
        break;

      case '2':
      case '3':
      case '6':
        path = `Course/GetByIsWeb/${level}`;
        break;
    }

    return path;
  }

  doSearch(n: number = 1) {

    if (!this.searchByResult) {
      this.getLinkedCourse(1, this.order);
      return
    }

    let cmd = {
      "courseName": this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.linkedCourses.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, 'linkedCourse/search', cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.pageSize = data.pageSize
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.linkedCourses.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getLinkedCourse(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getLinkedCourse(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<linkedCourse>(environment.urls.api, 'linkedCourse', this.linkedCourse.id, this.callbackDeleteSuccess, this.callbackErr);
    } else {
      if (this.linkedCourse !== null && this.validateCurrent()) {
        this.apiClientService.save<linkedCourse>(environment.urls.api, 'linkedCourse', this.linkedCourse, this.callbackSaveSuccess, this.callbackErr);
      }
    }
  }

  callbackSaveSuccess = (resp: any) => {
    let msg = 'Curso Unificado ' + (this.removeAction ? 'excluído' : this.linkedCourse.id !== undefined && this.linkedCourse.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getLinkedCourse(1, this.order);
    this.modalService.close('modal-linkedCourse');
  }

  callbackDeleteSuccess = (resp: any) => {
    let msg = 'Curso Unificado ' + (this.removeAction ? 'excluído' : this.linkedCourse.id !== undefined && this.linkedCourse.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getLinkedCourse(1, this.order);
    this.modalService.close('modal-linkedCourse');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.linkedCourse.description === null || this.linkedCourse.description === undefined || this.linkedCourse.description === '') {
      this.toastr.error("Campo Descrição deve ser preenchido.");
      response = false;
    }

    if (this.linkedCourse.courseName === null || this.linkedCourse.courseName === undefined || this.linkedCourse.courseName === '') {
      this.toastr.error("Campo Nome do Curso deve ser preenchido.");
      response = false;
    }

    if (this.linkedCourse.levelEducationCode === null || this.linkedCourse.levelEducationCode === undefined || this.linkedCourse.levelEducationCode === '') {
      this.toastr.error("Campo Nível de Ensino deve ser preenchido.");
      response = false;
    }

    if (this.linkedCourse.linkedCourseCourses === null || this.linkedCourse.linkedCourseCourses === undefined || this.linkedCourse.linkedCourseCourses.length === 0) {
      this.toastr.error("Campo Cursos deve ter pelo menos um vínculo.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

}
