/* Angular Core -- Modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';

registerLocaleData(localePt, 'pt', localePtExtra);

// BootStrapModules - NGX-Bootstrap
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

/* Plugins */
import { MomentModule } from 'ngx-moment';
import { NbThemeModule, NbSelectModule, NbLayoutModule, NbSidebarModule, NbListModule, NbTableModule, NbTreeGridModule, NbDatepickerModule, NbTimepickerModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_BR } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';

/* App  Modules */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { IndexComponent } from './index/index.component';
import { CarouselModule } from './carousel/carousel.module';
import { ArticleModule } from './article/article.module';
import { HotsitesModule } from "./hotsites/hotsites.module";
import { EvidenceModule } from "./evidence/evidence.module";
import { PartnershipModule } from './partnership/partnership.module';
import { NewsModule } from './news/news.module';
import { SurveyModule } from './survey/survey.module';
import { SchoolModule } from './school/school.module';
import { LinkedCourseModule } from './linkedCourse/linkedCourse.module';
import { EventModule } from './event/event.module';
import { CourseModule } from './course/course.module';
import { CourseBusinessUnitModule } from './course-business-unit/course-business-unit.module';
import { MediaModule } from "./media/media.module";
import { SenacModule } from "./senac/senac.module";
import { AccessDeniedComponent } from './shared/@pages/access-denied/access-denied.component';
import { AuthorizationService } from './shared/auth/authorization.service';
import { AuthenticationService } from './shared/auth/authentication.service';
import { NbMomentDateModule } from '@nebular/moment';


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MomentModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({ backdropBorderRadius: '14px', fullScreenBackdrop: true }),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbListModule,
    NbTableModule,
    NbTreeGridModule,
    FormsModule,
    NbSelectModule,
    EditorModule,
    NzIconModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbMomentDateModule,

    /* APP MODULES  */
    AppRoutingModule,
    SharedModule,
    CarouselModule,
    ArticleModule,
    HotsitesModule,
    SenacModule,
    EvidenceModule,
    PartnershipModule,
    NewsModule,
    SchoolModule,
    SurveyModule,
    LinkedCourseModule,
    EventModule,
    CourseBusinessUnitModule,
    MediaModule,
    HttpClientModule,
    CourseModule
  ],
  providers: [
    AuthorizationService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID, useValue: 'pt-br'
    },
    {
      provide: NZ_I18N, useValue: pt_BR
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
