import { BaseEntity } from "src/app/shared/@models/baseEntity";

export class banner  extends BaseEntity {
    title: string;
    subTitle: string;
    description: string;
    link: string;
    textButton: string;
    image: string;
    integrationCode: string;
    removedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    updatedUser: string;
    updatedUserId: string;
    businessUnitId: string;
    businessUnitName: string;
    show: boolean;
    order: number;
    imageVLibra: string;
    onHomePageAndBusinessUnit?: boolean;

    constructor() {
        super();
    }
}
