import { BaseEntity } from "src/app/shared/@models/baseEntity";
import { newsHotsites } from "./news-hotsites";
import { newsBusinessUnit } from "./news-business-unit"

export class news extends BaseEntity {
    title: string;
    resume: string;
    text: string;
    photo: string;
    photoLegend: string;
    date: Date;
    externalLink: string;
    prominence: boolean;
    businessUnitId: string;
    insertedUserId: string;
    updatedUser: string;
    updatedUserId: string;
    areaId: number;
    newsHotsites: newsHotsites[];
    newsBusinessUnits: newsBusinessUnit[];
    photoVLibra: string;
    show: boolean;

    constructor(
        id?: string,
        title?: string,
        resume?: string,
        text?: string,
        photo?: string,
        photoLegend?: string,
        date?: Date,
        externalLink?: string,
        prominence?: boolean,
        show?: boolean,
        areaId?: number,
        newsHotsites?: newsHotsites[],
        newsBusinessUnits?: newsBusinessUnit[],
        photoVLibra?: string
    ) {
        super();
        this.id = id;
        this.title = title;
        this.resume = resume;
        this.text = text;
        this.photo = photo;
        this.photoLegend = photoLegend;
        this.date = date;
        this.externalLink = externalLink;
        this.prominence = prominence;
        this.areaId = areaId;
        this.newsHotsites = newsHotsites;
        this.newsBusinessUnits = newsBusinessUnits,
        this.photoVLibra = photoVLibra;
    }
}
