<content-header title="Depoimentos">
  <div content-text>
      Nesta tela você poderá cadastrar e administrar os Depoimentos.      
  </div>  
  <div content-action>    
      <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
          <i class="fa fa-plus margin-right-8"></i> Criar
      </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..."
          [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
      <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
      </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
    <div class="alert alert-warning col-12" role="alert"
      *ngIf="evidences.length == 0 && isinit && !errorOnLoaging && paginationSearch">
        Nenhum depoimento encontrado.
    </div>
    <div class="alert alert-danger col-12" role="alert"
      *ngIf="evidences.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
        Nenhum depoimento cadastrado.
    </div>
      <div class="col-12 mx-auto table-responsive-sm no-padding">
          <div class="table-container">
              <table class="table table-striped table-hover table-fixed" *ngIf="evidences.length > 0">
                  <thead>
                      <tr>
                        <th class="name">Nome</th>                       
                        <th class="course">Curso</th>
                        <th class="businessUnit">Escola</th>
                        <th class="check">Ativo</th>
                        <th class="options">Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let e of evidences">                          
                          <td>
                            {{e.name}}
                          </td>
                          <td>
                            {{e.courseDocumentDescription}}
                          </td>
                          <td>
                            {{e.businessUnitName}}
                          </td>
                          <td>
                            <input type="checkbox" [checked]="e.show" disabled>
                          </td>                          
                          <td class="td-action">
                            <btn-drop-action (onEdit)="onEdit(e)" (onRemove)="onRemove(e)">...</btn-drop-action>
                          </td>
                      </tr>
                </tbody>
              </table>
          </div>
      </div>
  </nb-card-body>
  <!-- Begin Pagination -->
  <div class="pagination-container" *ngIf="!paginationSearch && evidences.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="getEvidence($event)">
    </pagination>
  </div>
  <div class="pagination-container" *ngIf="paginationSearch && evidences.length > 0">
    <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
      [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
  </div>
  <div class="table-info-container" *ngIf="evidences.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-evidence" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão deste depoimento?</div>
  <div body *ngIf="!removeAction">
      <app-evidence-form [evidence]="evidence" [businessUnit]="businessUnit" [course]="course" 
      [product]="product" [hotsites]="hotsites" [area]="area"></app-evidence-form>
  </div>
</modal>
<!-- End Modal-->