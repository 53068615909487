import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiClientService } from './../services/api-client.service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private location: Location,
    private apiClientService: ApiClientService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (localStorage.getItem('token') != null && localStorage.getItem('token').length > 0)
        resolve(true);
      else {
        return this.tokenParameterVerify(next);
      }
    });
  }

  tokenParameterVerify(next: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (!next.queryParams || !next.queryParams.token) {
        this.authenticationService.logout();
        resolve(false);
      } else {
        localStorage.setItem('token', next.queryParams.token);
        return this.getUserData();
      }
    });
  }

  getUserData(): Promise<boolean> {
    return this.apiClientService.get(environment.urls.authApi, "auth/user", "")
    .then((data) => {
      if (data) {
        localStorage.setItem('user_data', JSON.stringify(data));
      }
      
      this.router.navigate([this.router.url])
      this.location.replaceState(this.router.url)
      
      return true;
    })
    .catch(() => {
      this.authenticationService.logout();
      return false;
    });
  }
}
