import { Partnership } from './../../@model/partnership';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

@Component({
  selector: 'app-partnership-index',
  templateUrl: './partnership-index.component.html',
  styleUrls: ['./partnership-index.component.scss']
})
export class PartnershipIndexComponent implements OnInit {

  /* PAGINATION */
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 0;
  page: number = 0;
  order: string = "asc";
  /* */
  moment: any = moment;
  show: boolean = false;
  direction: boolean = false;
  isinit: boolean = false;
  errorOnLoaging: boolean = false;

  partnerships: Array<Partnership> = [];
  selectedPartnership: Partnership = new Partnership();
  removeAction: boolean = false;
  edit: boolean = false;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchParameterPartnershipDescription: string = "";

  constructor(
    private apiClientService: ApiClientService,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) {
    moment.locale('pt-br');
  }

  ngOnInit(): void {
    this.getPartnerships()
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getPartnerships(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getPartnerships(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  doSearch(n: number = 1) {

    if (!this.searchParameterPartnershipDescription) {
      this.getPartnerships(n);
      return
    }

    let cmd = {
      "description": this.searchParameterPartnershipDescription
    };

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.partnerships.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, "partnerships/search", cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.partnerships.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
        this.isSearchFinish = true;
      });
  }

  getPartnerships(n: number = 1, order: string = 'asc') {
    this.isinit = false;
    this.show = false;
    this.errorOnLoaging = false;
    this.partnerships.length = 0;
    this.apiClientService.getPaginated(environment.urls.api, "partnerships", n, this.pageSize, order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.partnerships.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  // Chamar modal em Modo de Criação
  onCreate() {
    this.selectedPartnership = new Partnership();
    this.selectedPartnership.active = true;
    this.removeAction = false;
    this.edit = false;
    this.show = true;
    this.modalService.modal('modal-partnership').title = "Criar nova Parceria";
    this.modalService.modal('modal-partnership').to = "modal";
    this.modalService.modal('modal-partnership').labelAction = "Salvar";
    this.modalService.modal('modal-partnership').labelClose = "Cancelar";
    this.modalService.modal('modal-partnership').iconClose = "fas fa-times margin-right-8"
    this.modalService.modal('modal-partnership').iconAction = "fas fa-plus margin-right-8";
    this.modalService.open('modal-partnership');
  }

  // Chamar modal em Modo de Edição
  onEdit(obj: Partnership) {
    this.selectedPartnership = Object.assign({}, obj);
    this.removeAction = false;
    this.edit = true;
    this.show = true;
    this.modalService.modal('modal-partnership').title = "Editar Parceria";
    this.modalService.modal('modal-partnership').to = "modal";
    this.modalService.modal('modal-partnership').labelAction = "Salvar";
    this.modalService.modal('modal-partnership').labelClose = "Cancelar";
    this.modalService.modal('modal-partnership').iconClose = "fas fa-times margin-right-8"
    this.modalService.modal('modal-partnership').iconAction = "fas fa-plus margin-right-8";
    this.modalService.open('modal-partnership');
  }

  // Chamar modal em Modo de Exclusão
  onRemove(obj: Partnership) {
    this.selectedPartnership = Object.assign({}, obj);
    this.removeAction = true;
    this.edit = false;
    this.show = true;
    this.modalService.modal('modal-partnership').title = "Excluir Parceria";
    this.modalService.modal('modal-partnership').to = "modal";
    this.modalService.modal('modal-partnership').labelAction = "Excluir";
    this.modalService.modal('modal-partnership').labelClose = "Cancelar";
    this.modalService.modal('modal-partnership').iconClose = "fas fa-times margin-right-8"
    this.modalService.modal('modal-partnership').iconAction = "fas fa-minus margin-right-8";
    this.modalService.open('modal-partnership');
  }

  resetForm() {
    this.show = false;
    this.selectedPartnership = undefined;
  }

  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<Partnership>(environment.urls.api, 'partnerships', this.selectedPartnership.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.selectedPartnership !== null && this.validateCurrent()) {
        this.apiClientService.save<Partnership>(environment.urls.api, 'partnerships', this.selectedPartnership, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  callbackSuccess = (resp: any) => {
    let msg = `Parceria` + ' ' + (this.removeAction ? `excluída` : this.selectedPartnership.id !== undefined && this.selectedPartnership.id !== null ? `alterada` : `criada`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.getPartnerships();
    this.modalService.close('modal-partnership');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (
      this.selectedPartnership.description === null
      || this.selectedPartnership.description === undefined
      || this.selectedPartnership.description === '') {
      this.toastr.error("Campo Parceria deve ser preenchido.");
      response = false;
    }

    if (this.selectedPartnership.discountPercentage === null
      || this.selectedPartnership.discountPercentage === undefined
    ) {
      this.toastr.error("Campo Desconto deve ser preenchido.");
      response = false;
    } else {
      if (this.selectedPartnership.discountPercentage < 0.1 || this.selectedPartnership.discountPercentage > 100) {
        this.toastr.error("Campo Desconto tem um valor minimo do 0,1% e máximo de 100%.");
        response = false;
      }
    }

    if (
      this.selectedPartnership.coverage === null
      || this.selectedPartnership.coverage === undefined
      || this.selectedPartnership.coverage === '') {
      this.toastr.error("Campo Abrangência deve ser preenchido.");
      response = false;
    }

    if (
      this.selectedPartnership.benefited === null
      || this.selectedPartnership.benefited === undefined
      || this.selectedPartnership.benefited === '') {
      this.toastr.error("Campo Beneficiados deve ser preenchido.");
      response = false;
    }
    return response;
  }

}
