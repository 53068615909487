import { courseBusinessUnitCourse } from './../../@model/course-business-unit-course';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TransferItem } from 'ng-zorro-antd/transfer';
import { ToastrService } from 'ngx-toastr';
import { courseBusinessUnit } from 'src/app/course-business-unit/@model/course-business-unit';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-course-business-unit-form',
  templateUrl: './course-business-unit-form.component.html',
  styleUrls: ['./course-business-unit-form.component.scss']
})
export class CourseBusinessUnitFormComponent implements OnInit {

  @Input() courseBusinessUnit: courseBusinessUnit;

  @Input() courseDataSource: TransferItem[] = [];

  businessUnits: any[];

  levelEducationCode: string = "";

  levels = [
    {
      "code": "1",
      "name": "FIC"
    },
    {
      "code": "2",
      "name": "Técnico"
    },
    {
      "code": "3",
      "name": "Superior"
    },
    {
      "code": "4",
      "name": "Extensão"
    },
    {
      "code": "6",
      "name": "Pós-Graduação"
    },
    {
      "code": "7",
      "name": "Ensino Médio"
    }
  ]

  selectItem: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  public ngOnInit() {
    this.courseBusinessUnit = new courseBusinessUnit();
    this.getBusinessUnits();
  }

  private getBusinessUnits(n: number = 1) {
    this.apiClientService.getAll(environment.urls.api, "businessUnit").then((data: any) => {
      this.businessUnits = data;
    });
  }

  private getCoursesProducts(level: string) {
    if (this.isCourse(level)) {
      this.getCourses(level);
    }

    if (this.isProduct(level)) {
      this.getProducts(level);
    }
  }

  private getCourses(level: string) {
    const endpoint = `Course/Site`;
    const ret: Array<TransferItem & { description: string }> = [];
    let cmd = { "levelEducationCode": level };
    this.apiClientService.postPaginated(environment.urls.courseApi, endpoint, cmd, 1, 20000).then((data: any) => {
      this.courseDataSource = this.dataProcess(data);
      this.selectItem = null;
    });
  }

  private getProducts(level: string) {
    const endpoint = `Product/GetByIsWeb/${level}`;
    this.apiClientService.getPaginated(environment.urls.courseApi, endpoint, 1, 20000).then((data: any) => {
      this.courseDataSource = this.dataProcess(data);
      this.selectItem = null;
    });
  }

  private dataProcess(data: any): any {
    const ret: Array<TransferItem & { description: string }> = [];
    if (data != null) {
      data.data.forEach(
        (d: {
          documentDescription: any;
          documentName: any;
          description: any;
          frameworkDescription: any;
          siteName: string;
          integrationCode: string;
          totalWorkload: number;
          workload: number;
        }) => {
          const desc = `${d.documentDescription || d.documentName || d.description}`;
          const description = '(' + d.integrationCode + ') ' + desc + (d.totalWorkload && ` - ${d.totalWorkload}h` || '');
          const direction = this.courseBusinessUnit.courseBusinessUnitCourses.map(m => m.courseId).includes(d.integrationCode) ? "right" : undefined;

          ret.push({
            key: d.integrationCode,
            title: description,
            description,
            descriptionItem: desc,
            frameworkDescription: d.frameworkDescription ?? d.siteName,
            totalWorkload: d.totalWorkload ?? d.workload,
            documentName: d.documentName ?? d.description ?? d.documentDescription,
            direction
          });
        }
      );
    }
    return ret;
  }

  private isProduct(level: string): boolean {
    let product = false;

    switch (level) {
      case '1':
      case '4':
        product = true;
        break;

      case '2':
      case '3':
      case '6':
      case '7':
        product = false;
        break;
    }

    return product;
  }

  private isCourse(level: string): boolean {
    let course = false;

    switch (level) {
      case '1':
      case '4':
        course = false;
        break;

      case '2':
      case '3':
      case '6':
      case '7':
        course = true;
        break;
    }

    return course;
  }

  public changeLevelLearning() {
    if (this.levelEducationCode == 'S') {
      this.courseDataSource = [];
      this.selectItem = null;
      return;
    }

    this.getCoursesProducts(this.levelEducationCode);
  }

  public onSelectedChangeBusinessUnit(id: any) {
    this.apiClientService.get<courseBusinessUnit>(environment.urls.api, 'courseBusinessUnit/businessUnit', id).then(data => {
        const o = new courseBusinessUnit();
        o.businessUnitId = id;

        this.courseBusinessUnit = data || o;
        this.levelEducationCode && this.getCoursesProducts(this.levelEducationCode);
    });

  }

  public change(ret: any): void {
    this.addCourse(ret);
    this.removeCourse(ret);

    this.createOrUpdate();
  }

  public selectChange(ret: any): void {
    this.selectItem = ret;
  }

  public filterOption(inputValue: string, item: any): boolean {
    return item && item.description && item.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  }

  public clearForm() {
    this.courseBusinessUnit = new courseBusinessUnit();
    this.levelEducationCode = "";
    this.courseDataSource = [];
    this.selectItem = null;
  }

  private createOrUpdate(): void {
    this.apiClientService.save<courseBusinessUnit>(environment.urls.api, 'courseBusinessUnit', this.courseBusinessUnit, this.callbackSaveSuccess, this.callbackError);
  }

  private callbackSaveSuccess = (resp: any) => {
    let msg = 'Curso por Escola ' + (this.courseBusinessUnit.id !== undefined && this.courseBusinessUnit.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
  }

  private callbackError = (resp: any) => {
    console.error(resp);
  }

  private addCourse(ret: any) {
    if (ret.from == "left") {
      (ret.list || []).forEach((course: { key: string; }) => {
        var c = new courseBusinessUnitCourse();
        c.courseId = course.key;
        c.courseType = this.levelEducationCode;

        this.courseBusinessUnit.courseBusinessUnitCourses = this.courseBusinessUnit.courseBusinessUnitCourses.concat(c);
      });
    }
  }

  private removeCourse(ret: any) {
    if (ret.from == "right") {
      (ret.list || []).forEach((course: { key: string; }) => {
        this.courseBusinessUnit.courseBusinessUnitCourses.splice(this.courseBusinessUnit.courseBusinessUnitCourses.findIndex(item => item.courseId === course.key), 1);
      });
    }
  }
}
