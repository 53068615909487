import { Component, OnInit, Input } from '@angular/core';
import { news } from '../../@model/news';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit {

  @Input() news: news;
  businessUnits: any[];
  areas: any[];
  hotsites: any[];

  tinymceKey: string = environment.tinymceKey;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getAreas();
    this.getHotsites();
    this.getBusinessUnits();
  }

  removeImage() {
    this.news.photo = undefined;
  }

  handleOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.news.photo = data[0].source;
  }

  handleOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handlePhoto(): string {
    return this.news.photo ? `${environment.urls.minio}/${this.news.photo}` : null;
  }

  getBusinessUnits(n: number = 1) {
    this.apiClientService.getAll(environment.urls.api, "businessUnit").then((data: any) => {
      this.businessUnits = data;
    }).finally(() => {});
  }

  getAreas(n: number = 1) {
    this.apiClientService.getAll(environment.urls.genericApi, "GetAreas").then((data: any) => {
      if (data != null) {
        this.areas = [];
        data.forEach(d => {
          this.areas.push(d);
        });
      }
    }).finally(() => {});
  }

  getHotsites(n: number = 1) {
    this.apiClientService.getPaginated(environment.urls.api, "hotsite", n, 20000).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.hotsites = [];
        data.data.forEach(d => {
          this.hotsites.push(d);
        });
      }
    }).finally(() => {});
  }
}
