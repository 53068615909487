import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { MeuSenacPerson } from '../../@model/meu-senac-person';

@Component({
  selector: 'app-senac-index',
  templateUrl: './senac-index.component.html',
  styleUrls: ['./senac-index.component.scss']
})

export class SenacIndexComponent implements OnInit {

  MeuSenacPerson: MeuSenacPerson = new MeuSenacPerson();
  MeuSenacPersons: MeuSenacPerson[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "asc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  constructor(

    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.doSearch(1);
  }

  onCreate() {
    this.removeAction = false;
    this.MeuSenacPerson = new MeuSenacPerson();
    this.MeuSenacPerson.isActive = true;
    // this.modalService.modal('modal-meusenac').title = "Criar Meu Senac";
    // this.modalService.modal('modal-meusenac').to = "modal";
    // this.modalService.modal('modal-meusenac').labelAction = "Salvar";
    // this.modalService.modal('modal-meusenac').labelClose = "Cancelar";
    // this.modalService.modal('modal-meusenac').iconAction = "fa fa-plus margin-right-8";
    // this.modalService.open('modal-meusenac');
  }

  onEdit(meusenac: MeuSenacPerson): void {
    this.MeuSenacPerson = Object.assign({}, meusenac);
    this.removeAction = false;
    this.saveData();
    // this.modalService.modal('modal-meusenac').title = "Editar hotsite";
    // this.modalService.modal('modal-meusenac').to = "modal";
    // this.modalService.modal('modal-meusenac').labelAction = "Salvar";
    // this.modalService.modal('modal-meusenac').labelClose = "Cancelar";
    // this.modalService.modal('modal-meusenac').iconAction = "far fa-save margin-right-8";
    // this.modalService.open('modal-meusenac');
  }

  onRemove(meusenac: MeuSenacPerson): void {
    this.MeuSenacPerson = meusenac;
    this.removeAction = true;
    // this.modalService.modal('modal-meusenac').title = "Excluir Hotsite " + this.MeuSenacPerson.name;
    // this.modalService.modal('modal-meusenac').to = "modal";
    // this.modalService.modal('modal-meusenac').labelAction = "Excluir";
    // this.modalService.modal('modal-meusenac').labelClose = "Cancelar";
    // this.modalService.modal('modal-meusenac').iconAction = "fas fa-check margin-right-8";
    // this.modalService.open('modal-meusenac');
  }

  onDetails(meusenac: MeuSenacPerson): void {
    this.removeAction = false;
    this.MeuSenacPerson = Object.assign({}, meusenac);
    // this.modalService.modal('modal-meusenac').title = "Detalhes do Hotsite";
    // this.modalService.modal('modal-meusenac').to = "modal";
    // this.modalService.modal('modal-meusenac').labelClose = "Fechar";
    // this.modalService.modal('modal-meusenac').footerAction = false;
    // this.modalService.modal('modal-meusenac').noAction = true;
    // this.modalService.open('modal-meusenac');
  }

  getHotsites(n: number = 1, order) {
    this.isinit = false;
    this.paginationSearch = false;
    this.apiClientService.getPaginated(environment.urls.api, "meusenacperson", n, this.pageSize, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.MeuSenacPersons = [];
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.MeuSenacPersons.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  doSearch(n: number = 1) {

    if (!this.searchByResult) {
      this.getHotsites(1, this.order);
      return
    }

    let cmd = {
      "code": this.searchByResult,
      "title": this.searchByResult,
      "description": this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.MeuSenacPersons.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, 'meusenacperson/search', cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.pageSize = data.pageSize
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.MeuSenacPersons.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getHotsites(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getHotsites(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  saveData(): void {
      if (this.MeuSenacPerson !== null && this.validateCurrent()) {
        this.apiClientService.put<MeuSenacPerson>(environment.urls.api, 'MeuSenacPerson/password', this.MeuSenacPerson, this.callbackSuccess, this.callbackErr);
      }
  }

  callbackSuccess = (resp: any) => {
    let msg = 'Meu Senac  ' + (this.removeAction ? 'excluído' : this.MeuSenacPerson.id !== undefined && this.MeuSenacPerson.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getHotsites(1, this.order);
    // this.modalService.close('modal-meusenac');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

}
