<content-header title="Gestão de Artigos">
    <div content-text class="info">
        Nesta tela você poderá cadastrar e administrar os Artigos.
    </div>
    <div content-action>
        <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
            <i class="fa fa-plus margin-right-8"></i> Criar
        </button>
    </div>
</content-header>
<nb-card>
    <nb-card-header>
        <nb-form-field>
            <input nbInput type="text" placeholder="Pesquisar ..."
                [(ngModel)]="searchParameterDescription" (keydown.enter)="doSearch()">
            <button nbSuffix nbButton ghost (click)="doSearch()">
                <nb-icon icon="search-outline" pack="eva"> </nb-icon>
            </button>
        </nb-form-field>
    </nb-card-header>
    <nb-card-body>
        <div class="alert alert-warning col-12" role="alert"
        *ngIf="articles.length == 0 && isinit && !errorOnLoaging && paginationSearch">
            Nenhum artigo encontrado.
        </div>
        <div class="alert alert-danger col-12" role="alert"
        *ngIf="articles.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
            Nenhum artigo cadastrado.
        </div>
        <div class="col-12 mx-auto table-responsive-sm no-padding">
            <div class="table-container">
                <table class="table table-striped table-hover table-fixed" *ngIf="articles.length > 0">
                    <thead>
                        <tr>
                            <th class="name">Título</th>
                            <th class="description">Escola</th>
                            <th class="description">Autor</th>
                            <th class="name">Data Inclusão</th>
                            <th class="name">Ativo</th>
                            <th class="options">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of articles">
                            <td class="title-grid">
                                {{a.title}}
                            </td>
                            <td>
                                {{a.businessUnitName}}
                            </td>
                            <td>
                                {{a.autor}}
                            </td>
                            <td>
                                {{a.insertedAt | date:'dd/MM/yy HH:mm'}}
                            </td>
                            <td>
                                <input type="checkbox" [checked]="a.show" disabled>
                            </td>
                            <td class="td-action">
                                <btn-drop-action (onEdit)="onEdit(a)" (onRemove)="onRemove(a)">
                                </btn-drop-action>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination-container" *ngIf="articles.length > 0">
                <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
                    [totalPage]="totalPage" (goPage)="doSearch($event, order)"></pagination>
            </div>
            <div class="table-info-container" *ngIf="articles.length > 0">
                <div class="table-info-label">
                    <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<modal id="modal-article" (onAction)="saveData()" (onClose)="resetForm()">
    <div body *ngIf="removeAction">Confirma a exclusão deste artigo?</div>
    <div body *ngIf="!removeAction">
        <app-article-form [article]="article" [hotSites]="hotSites" [businessUnit]="businessUnit" [areas]="areas"></app-article-form>
    </div>
</modal>