import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from '../shared/auth/authentication.guard';
import { AuthorizationGuard } from '../shared/auth/authorization.guard';

import { EventIndexComponent } from './@pages/event-index/event-index.component';
import { SubscriberIndexComponent } from './@pages/subscriber-index/subscriber-index.component';

const routes: Routes = [
  {
      path: 'event',
      component: EventIndexComponent,
      data: { allowedModule: 'CMS_EVENTOS' },
       canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'event/subscription',
    component: SubscriberIndexComponent,
    data: { allowedModule: 'CMS_EVENTOS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },

 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class EventRoutingModule { }
