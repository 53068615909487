import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {


  @Input() pageSize: number;
  @Input() rows: number;
  @Input() totalRows: number;
  @Input() totalPage: number;
  @Input() page: number;
  @Output() goPage: EventEmitter<any> = new EventEmitter();

  element: any;
  currentIndex: number = 1;
  firstPage: number = 1;
  lastPage: number = 1;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
  }

  pages(n: number, startFrom: number): number[] {
    
    startFrom = this.page > 5 ? (this.totalPage - this.page) > 5 ? this.page : (this.totalPage - (this.totalPage - this.page))+5 >= this.totalPage ? this.totalPage-5 : (this.totalPage - (this.totalPage - this.page)) : startFrom;
    

    this.currentIndex = this.page;

    n = n - 2 <= 5 ? n : this.totalPage - startFrom < 6 ? 6 : 5;

    this.lastPage = startFrom + n;
    this.firstPage = startFrom + 1;
    return [...Array(n).keys()].map(i => i + startFrom);
  }

  pagesListFull(): number[] {
    return [...Array(this.totalPage).keys()].map(i => i + 1);
  }

  goTo(n: number) {
      this.currentIndex = n;
      this.page = n;
      this.goPage.emit(n);
  }

  goToPage(n: number) {

    if(this.page == 1 && this.currentIndex == 1 && n === -1)
      return;

    if(this.page == this.totalPage && this.currentIndex >= this.totalPage  && n === 1)
      return;

    this.currentIndex = this.currentIndex === 1 && n === -1 ? 1 : this.currentIndex + n;
    this.page = this.currentIndex;

    this.goPage.emit(this.currentIndex);
  }

  openList() {
    this.element.querySelector('.pagination-page-select').focus();
  }

}
