<div class="form-group col-12 buttonUpload">
    <button [disabled]="handleUploadDisable()" 
    nz-button nzType="primary" (click)="isVisible = true">
    <i nz-icon nzType="file-image" nzTheme="outline"></i>Adicionar arquivo</button>
</div>
<nz-modal
    nzWrapClassName="vertical-center-modal"
    nzTitle="Central de Midia" 
    nzCancelText="Cancelar" 
    [nzVisible]="isVisible" 
    [nzOkText]="handleOkButtonText()"
    [nzOkDisabled]="handleOkDisabled()"
    [nzOkLoading]="uploading"
    (nzOnCancel)="handleCancel()" 
    (nzOnOk)="handleUpload()"
>
    <nz-upload 
        nzType="drag" 
        nzListType="picture" 
        (nzChange)="handleChange($event)" 
        [nzBeforeUpload]="beforeUpload" 
        [nzShowButton]="fileList.length < 2" 
        [nzPreview]="handlePreview"
        [(nzFileList)]="fileList"
        [nzDisabled]="fileList.length >= maxLimitFiles"
        [nzFileType]="fileTypes">
        <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
        <p class="ant-upload-hint">
            Número máximo de arquivo(s): {{maxLimitFiles}}
        </p>
    </nz-upload>
    <nz-modal 
        nzWrapClassName="vertical-center-modal"
        [nzVisible]="previewVisible" 
        [nzContent]="modalContent" 
        [nzFooter]="null"
        (nzOnCancel)="previewVisible = false"
        >
        <ng-template #modalContent>
            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
        </ng-template>
    </nz-modal>
</nz-modal>