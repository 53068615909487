import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingService } from './loading.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../@models/responseMessage';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  //basePath: string;

  constructor(private http: HttpClient, private toastr: ToastrService, private loadingService: LoadingService) {
    //this.basePath = `${environment.urls.api}`;
  }

  // setBasePath(url: string) {
  //   console.log('Url: ' + url);
  //   this.basePath = url;
  //   console.log('BasePath: ' + this.basePath);
  // }

  save<T>(basePath: string, path: string, cmd: any, success: (data: any) => void, error: (err: any) => void) {
    if (cmd.id !== undefined && cmd.id !== null) {
      return this.put<T>(basePath, path, cmd, success, error);
    } else {
      return this.post<T>(basePath, path, cmd, success, error);
    }
  }

  post<T>(basePath: string, path: string, cmd: any, success: (data: T) => void, error: (err: any) => void) {
    this.loadingService.start();
    return this.http.post<T>(`${basePath}/${path}`, cmd).toPromise()
      .catch((response) => {
        error(response);
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: T) => {
          if (data !== undefined && data !== null) {
            success(data);
          }
        },
        (err: any) => {
          error(err);
        }
      ).finally(() => {
        this.loadingService.end();
      });
  }

  put<T>(basePath: string, path: string, cmd: any, success: (data: T) => void, error: (err: any) => void) {
    this.loadingService.start();
    return this.http.put<T>(`${basePath}/${path}`, cmd).toPromise()
      .then(
        (data: T) => {
          success(data);
        },
        (err: any) => {
          error(err);
          this.showErrorStatusCode(err.status, err.error);
        }
      ).finally(() => {
        this.loadingService.end();
      });
  }

  delete<T>(basePath: string, path: string, id: string, success: (data: T) => void, error: (err: any) => void) {
    this.loadingService.start();
    return this.http.delete<T>(`${basePath}/${path}/${id}`, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).toPromise()
      .catch((response) => {
        error(response);
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: T) => {
          if (data !== undefined) {
            success(data);
          }
        },
        (err: any) => {
          error(err);
        }
      ).finally(() => {
        this.loadingService.end();
      });
  }

  get<T>(basePath: string, path: string, id: string) {
    this.loadingService.start();
    return this.http.get<T>(`${basePath}/${path}/${id}`).toPromise()
      .catch((response) => {
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: T) => {
          return data;
        },
        (err: any) => {
          return null;
        }
      ).finally(() => {
        this.loadingService.end();
      });
  }

  getAll(basePath: string, path: string) {
    this.loadingService.start();
    return this.http.get<any>(`${basePath}/${path}`, {}).toPromise()
      .catch((response) => {
        console.log(response.status);
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: any) => data).finally(() => {
          this.loadingService.end();
        });
  }

  getPath<T>(basePath: string, path: string) {
    this.loadingService.start();
    return this.http.get<T>(`${basePath}/${path}`).toPromise()
      .catch((response) => {
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: T) => {
          return data;
        },
        (err: any) => {
          return null;
        }
      ).finally(() => {
        this.loadingService.end();
      });
  }

  getPaginated(basePath: string, path: string, page: number, pageSize: number, order: string = 'asc') {
    this.loadingService.start();
    return this.http.get<ResponseMessage>(`${basePath}/${path}`, {
      headers: new HttpHeaders().set('Page', page.toString()).set('PageSize', pageSize.toString()).set('order', order),
    }
    ).toPromise()
      .catch((response) => {
        console.log(response.status);
        this.showErrorStatusCode(response.status, response.error);
      })
      .then(
        (data: ResponseMessage) => {
          if (data === null || data === undefined) {
            return null;
          }
          if (data.isValid) {
            return data;
          } else {
            this.toastr.error("Não foi possível obter a lista de registros.");
          }
          return null;
        },
      ).finally(() => {
        this.loadingService.end();
      });
  }

  postPaginated(basePath: string, path: string, cmd: any, page: number, pageSize: number, order: string = 'asc') {
    this.loadingService.start();
    return this.http.post<ResponseMessage>(`${basePath}/${path}`, cmd, {
      headers: new HttpHeaders().set('Page', page.toString()).set('PageSize', pageSize.toString()).set('order', order)
    }).toPromise()
      .catch((response) => {
        this.showErrorStatusCode(response.status, response.errors);
      })
      .then(
        (data: ResponseMessage) => {
          if (data === null || data === undefined) {
            return null;
          }
          if (data.isValid) {
            return data;
          } else {
            this.toastr.error("Não foi possível obter a lista de registros.");
          }
          return null;
        },
      ).finally(() => {
        this.loadingService.end();
      });
  }

  showErrorStatusCode = (status: number, error: any) => {
    switch (status) {
      case 400:
        if (error.errors !== undefined && error.errors !== null) {
          let porperties = Object.getOwnPropertyNames(error.errors);
          porperties.forEach(element => {
            Reflect.get(error.errors, element).forEach(x => this.toastr.error(x));
          });
        } else {
          this.toastr.warning('Ocorreu um erro de validação das informações.');
        }
        break;
      case 201:
        this.toastr.success('Informação registrada com sucesso.');
        break;
      case 204:
        this.toastr.success('Operação realizada com sucesso.');
        break;
      case 401:
        this.toastr.error('Usuário não autenticado.');
        break;
      case 404:
        //this.toastr.error('Não há registros para a informação desejada.');
        break;
      case 403:
        this.toastr.error('Você não possui permissão para realizar está operação.');
        break;
      case 500:
        this.toastr.error('As informações não estão coesas e geraram erro na aplicação.');
        break;
    }
  }
}
