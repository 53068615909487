import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { article } from '../../@model/article';
import { hotSites } from 'src/app/shared/@models/hotSites';
import { areas } from 'src/app/shared/@models/areas';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss']
})
export class ArticleFormComponent implements OnInit {
  @Input() article: article;
  @Input() hotSites: hotSites[] = [];
  @Input() businessUnit: businessUnit[] = [];
  @Input() areas: areas[] = [];
  
  tinymceKey: string = environment.tinymceKey;
  
  constructor(
    protected sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.tinymceKey = environment.tinymceKey;
  }


onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
    };
  }

}
