<content-header title="Cursos Unificados">
  <div content-text>
      Nesta tela você poderá cadastrar e administrar seus Cursos Unificados.
  </div>
  <div content-action>
      <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
          <i class="fa fa-plus margin-right-8"></i> Criar
      </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..."
          [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
        <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
        </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
    <div class="alert alert-warning col-12" role="alert"
    *ngIf="linkedCourses.length == 0 && isinit && !errorOnLoaging && paginationSearch">
      Nenhum curso unificado encontrado.
    </div>
    <div class="alert alert-danger col-12" role="alert"
    *ngIf="linkedCourses.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
      Nenhum curso unificado cadastrado.
    </div>
      <div class="col-12 mx-auto table-responsive-sm no-padding">
          <div class="table-container">
              <table class="table table-striped table-hover table-fixed" *ngIf="linkedCourses.length > 0">
                  <thead>
                      <tr>
                        <th class="Description">Nome</th>
                        <th class="Description">Nível</th>
                        <th class="dateCreate">Data Inclusão</th>
                        <th class="dateUpdate">Data Alteração</th>
                        <th class="check">Ativo</th>
                        <th class="options">Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let lc of linkedCourses">
                          <td class="title-grid">
                              {{lc.courseName}}
                          </td>
                          <td>
                            {{getLevelDescription(lc.levelEducationCode)}}
                          </td>
                          <td>
                            {{lc.insertedAt | date:'dd/MM/yyyy'}}
                          </td>
                          <td>
                            {{lc.updatedAt | date:'dd/MM/yyyy'}}
                          </td>
                          <td>
                            <input type="checkbox" [checked]="lc.show" disabled>
                          </td>
                          <td class="td-action">
                            <btn-drop-action (onEdit)="onEdit(lc)" (onRemove)="onRemove(lc)">...</btn-drop-action>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </nb-card-body>
  <!-- Begin Pagination -->
  <div class="pagination-container" *ngIf="!paginationSearch && linkedCourses.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="getLinkedCourse($event)">
    </pagination>
  </div>
  <div class="pagination-container" *ngIf="paginationSearch && linkedCourses.length > 0">
    <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
      [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
  </div>
  <div class="table-info-container" *ngIf="linkedCourses.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-linkedCourse" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão deste hotsite?</div>
  <div body *ngIf="!removeAction">
      <app-linkedCourse-form [linkedCourse]="linkedCourse" [courseDataSource]="transferItem"></app-linkedCourse-form>
  </div>
</modal>
<!-- End Modal-->
