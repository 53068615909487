import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HotsitesRoutingModule } from './hotsites-routing.module';
import { NbCardModule, NbButtonModule, NbActionsModule, NbSelectModule, NbFormFieldModule, NbCheckboxModule } from '@nebular/theme';
import { HotsitesIndexComponent } from './@pages/hotsites-index/hotsites-index.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HotsitesFormComponent } from './@pages/hotsites-form/hotsites-form.component';
import { NbIconModule, NbInputModule } from '@nebular/theme';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [HotsitesIndexComponent, HotsitesFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    HotsitesRoutingModule,
    SharedModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    NbIconModule,
    NbInputModule,
    NbFormFieldModule
  ]
})

export class HotsitesModule { }
