import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

import { environment } from 'src/environments/environment';

import { Event } from '../../@model/event';
import { EventListCommand } from '../../@model/event-list-command';
import { EventService } from './../../@services/event.service';
import { EventTypeService } from '../../@services/event-type.service';
import { EventType } from '../../@model/event-type';
import { Moment } from 'moment';

@Component({
  selector: 'app-event-index',
  templateUrl: './event-index.component.html',
  styleUrls: ['./event-index.component.scss'],
})
export class EventIndexComponent implements OnInit {
  event = new Event();
  events: Event[] = [];
  eventTypes: EventType[];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = 'desc';
  show: boolean = true;

  isSearchFinish: boolean = false;
  searchByResult: string = '';
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getEventTypes();
    this.doSearch();
  }

  getEventTypes() {
    // this.eventTypes = this.eventTypeService.getAll()
    //   .sort((a,b) => (a.description >= b.description) ? 1 : -1);

    this.eventTypeService.getAll().then((data: ResponseMessage) => {
      this.eventTypes = data.data
        .map((eventType: EventType) => eventType)
        .sort((a, b) => (a.description >= b.description ? 1 : -1));
    });
  }

  getEventTypeName(eventTypeId: string) {
    if (!this.eventTypes) return '';

    const eventTypes = this.eventTypes.filter((f) => f.id === eventTypeId);

    if (
      eventTypes !== null &&
      eventTypes !== undefined &&
      eventTypes.length > 0
    ) {
      return eventTypes[0].description;
    }
  }

  doSearch(pageNumber: number = 1) {
    const cmd = new EventListCommand(this.searchByResult);

    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.events.length = 0;

    this.eventService
      .getPaginated(cmd, pageNumber, this.pageSize, this.order)
      //this.apiClientService.postPaginated(environment.urls.api, 'event/search', cmd, pageNumber, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.loadPaginationInfo(data);
          this.loadData(data);
        } else {
          this.events = [];
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  private loadPaginationInfo(data: ResponseMessage) {
    this.page = data.page;
    this.rows = data.rows;
    this.pageSize = data.pageSize;
    this.totalRows = data.totalRows;
    this.totalPage = data.totalPage;
  }

  private loadData(data: ResponseMessage) {
    this.events = this.filterActiveSubscribers(data.data);
  }

  filterActiveSubscribers(events: Event[]) {
    return events.map((event: Event) => {
      event.subscribers = event.subscribers?.filter((f) => f.isActive);
      return event;
    });
  }

  onEdit(event: Event): void {
    this.openModal(event);
  }

  onRemove(event: Event): void {
    const remove = true;
    this.openModal(event, remove);
  }

  onCreate() {
    this.openModal();
  }

  private openModal(event: Event = null, remove: boolean = false) {
    this.event = event ?? new Event();

    if (!event) {
      // this.event.isActive = true;
      this.configModal('Criar Evento', 'Salvar', 'fa-plus');
    } else {
      if (!remove) {
        this.configModal('Editar Evento', 'Salvar', 'fa-save');
      } else {
        this.removeAction = true;
        this.configModal(
          'Excluir Evento ' + this.event.title,
          'Excluir',
          'fa-check'
        );
      }
    }

    this.modalService.modal('modal-event').to = 'modal';
    this.modalService.modal('modal-event').labelClose = 'Cancelar';
    this.modalService.open('modal-event');
  }

  private configModal(title: string, labelAction: string, iconAction: string) {
    this.modalService.modal('modal-event').title = title;
    this.modalService.modal('modal-event').labelAction = labelAction;
    this.modalService.modal(
      'modal-event'
    ).iconAction = `fas ${iconAction} margin-right-8`;
  }

  onRegister(event: Event): void {
    this.router.navigate(['event/subscription'], { state: { data: event } });
  }

  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<Event>(
        environment.urls.api,
        'event',
        this.event.id,
        this.callbackSuccess,
        this.callbackErr
      );
    } else {
      this.normalizeHours(this.event);

      if (this.event !== null && this.validateCurrent()) {
        this.apiClientService.save<Event>(
          environment.urls.api,
          'event',
          this.event,
          this.callbackSuccess,
          this.callbackErr
        );
      }
    }
  }

  private normalizeHours(event: Event) {
    event.openingHours = this.convertMomentToUtcDate(this.event.openingHours);
    event.closingHours = this.convertMomentToUtcDate(this.event.closingHours);
  }

  private convertMomentToUtcDate(moment) {
    try {
      let m = (moment as unknown) as Moment;
      m.utc(true);
      return m.toDate();
    } catch {
      return moment;
    }
  }

  validateCurrent() {
    let response = true;

    if (
      this.event.title === null ||
      this.event.title === undefined ||
      this.event.title === ''
    ) {
      this.toastr.error('Campo Título deve ser preenchido.');
      response = false;
    }

    if (
      this.event.localization === null ||
      this.event.localization === undefined ||
      this.event.localization === ''
    ) {
      this.toastr.error('Campo Local deve ser preenchido.');
      response = false;
    }

    if (
      this.event.dateInitial === null ||
      this.event.dateInitial === undefined
    ) {
      this.toastr.error('Campo Data Inicial deve ser preenchido.');
      response = false;
    }

    if (this.event.dateFinal === null || this.event.dateFinal === undefined) {
      this.toastr.error('Campo Data Final deve ser preenchido.');
      response = false;
    }

    if (
      this.event.openingHours === null ||
      this.event.openingHours === undefined
    ) {
      this.toastr.error('Campo Hora Inicial deve ser preenchido.');
      response = false;
    }

    if (
      this.event.closingHours === null ||
      this.event.closingHours === undefined
    ) {
      this.toastr.error('Campo Hora Final deve ser preenchido.');
      response = false;
    }

    if (
      this.event.whoPromotes === null ||
      this.event.whoPromotes === undefined ||
      this.event.whoPromotes === ''
    ) {
      this.toastr.error('Campo Quem Promove deve ser preenchido.');
      response = false;
    }

    if (
      this.event.eventBusinessUnits === null ||
      this.event.eventBusinessUnits === undefined ||
      this.event.eventBusinessUnits.length < 1
    ) {
      this.toastr.error('Campo Escola deve conter pelo menos um elemento.');
      response = false;
    }

    if (
      this.event.description === null ||
      this.event.description === undefined ||
      this.event.description === ''
    ) {
      this.toastr.error('Campo Descrição deve ser preenchido.');
      response = false;
    }

    return response;
  }

  callbackSuccess = (resp: any) => {
    const msg =
      'Evento ' +
      (this.removeAction
        ? 'excluída'
        : this.event.id !== undefined && this.event.id !== null
        ? 'alterada'
        : 'criada') +
      ' com sucesso!';
    this.toastr.success(msg);

    this.doSearch(1);
    this.modalService.close('modal-event');
  };

  callbackErr = (resp: any) => {
    console.error(resp);
  };

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }
}
