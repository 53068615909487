<content-header title="Notícias">
  <div content-text>
      Nesta tela você poderá cadastrar e administrar suas Notícias.      
  </div>  
  <div content-action>    
      <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
          <i class="fa fa-plus margin-right-8"></i> Criar
      </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..."
          [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
        <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
        </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
    <div class="alert alert-warning col-12" role="alert"
    *ngIf="newss.length == 0 && isinit && !errorOnLoaging && paginationSearch">
      Nenhuma notícia encontrado.
    </div>
    <div class="alert alert-danger col-12" role="alert"
    *ngIf="newss.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
      Nenhuma notícia cadastrado.
    </div>
      <div class="col-12 mx-auto table-responsive-sm no-padding">
          <div class="table-container">
              <table class="table table-striped table-hover table-fixed" *ngIf="newss.length > 0">
                  <thead>
                      <tr>
                        <th class="title">Título</th>
                        <th class="date">Data</th>
                        <th class="check">Ativo</th>
                        <th class="options">Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let h of newss">
                          <td class="title-grid">
                              {{h.title}}
                          </td>
                          <td>
                              {{h.date | date: 'dd/MM/yyyy'}}
                          </td>
                          <td>
                              <input type="checkbox" [checked]="h.show" disabled>
                          </td>
                          <td class="td-action">
                            <btn-drop-action (onEdit)="onEdit(h)" (onRemove)="onRemove(h)">...</btn-drop-action>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </nb-card-body>
  <!-- Begin Pagination -->  
  <div class="pagination-container" *ngIf="!paginationSearch && newss.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="getNews($event)">
    </pagination>
  </div>
  <div class="pagination-container" *ngIf="paginationSearch && newss.length > 0">
    <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
      [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
  </div>
  <div class="table-info-container" *ngIf="newss.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-news" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão desta noticia?</div>
  <div body *ngIf="!removeAction">
      <app-news-form [news]="news"></app-news-form>
  </div>
</modal>
<!-- End Modal-->