import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnershipRoutingModule } from './partnership-routing.module';
import { PartnershipIndexComponent } from './@pages/partnership-index/partnership-index.component';
import { PartnershipFormComponent } from './components/partnership-form/partnership-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbSelectModule, NbTabsetModule, NbThemeModule, NbUserModule } from '@nebular/theme';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';


@NgModule({
  declarations: [
    PartnershipIndexComponent,
    PartnershipFormComponent
  ],
  imports: [
    CommonModule,
    PartnershipRoutingModule,
    SharedModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    BsDropdownModule.forRoot(),
    /* NEBULAR - Modules */
    NbLayoutModule,
    NbSelectModule,
    NbActionsModule,
    NbCheckboxModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbFormFieldModule,
    NbTabsetModule,
    NbUserModule,
    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NbInputModule,

  ]
})
export class PartnershipModule { }
