import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscriber } from '../../@model/subscriber';
import { ToastrService } from 'ngx-toastr';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-subscriber-form',
  templateUrl: './subscriber-form.component.html',
  styleUrls: ['./subscriber-form.component.scss'],
})
export class SubscriberFormComponent implements OnInit {
  @Input() subscriber: Subscriber;
  @Input() subscribers: Subscriber[];

  @ViewChild('inputCpf', { static: false }) inputCpf: ElementRef;

  constructor(
    protected sanitizer: DomSanitizer,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {}

  validateAlreadyExistent() {
    if (
      this.subscriber.cpf === null ||
      this.subscriber.cpf === undefined ||
      this.subscriber.cpf == ''
    ) {
      return;
    }

    if (this.cpfAlteadyExists(this.subscriber.cpf)) {
      this.toastr.warning('CPF já cadastrado neste Evento.');
      this.subscriber.cpf = undefined;
      setTimeout(() => this.inputCpf.nativeElement.focus());
    }
  }

  private cpfAlteadyExists(cpf: string) {
    const result = this.getRegistratiosByCpf(cpf);

    return (result !== null && result !== undefined && result.length > 0);
  }

  private getRegistratiosByCpf(cpf: string) {
    return this.subscribers.filter(
      (f) =>
        f.cpf === cpf &&
        (this.subscriber.id === undefined || f.id !== this.subscriber.id)
    );
  }
}
