<form #newsForm class="col-12">
  <div class="form-group col-12">
      <label>Título:</label>
      <input fullWidth type="text" [(ngModel)]="survey.title" name="title" class="form-control col-12"
          placeholder="Título" id="title" maxlength="200" required>
  </div>
  <div class="form-group col-12">
      <label>Escolas:</label>
      <nb-select fullWidth multiple placeholder="Escolas" (selectedChange)="buSelectedChange($event)" name="surveyBusinessUnit" [selected]="selectedBU">
          <nb-option *ngFor="let b of businessUnits" [value]="b.id">{{b.name}}</nb-option>
      </nb-select>
  </div>
  <div class="form-group col-12">
    <label>Adicionar Alternativas:</label>
    <nb-card>
      <nb-form-field>
        <input nbInput fullWidth type="text" name="alternative" id="alternative" placeholder="Nova Alternativa" [(ngModel)]="alternative" maxlength="200">
        <button nbSuffix nbButton ghost (click)="include()">
          <nb-icon icon="plus"></nb-icon>
        </button>
      </nb-form-field>
      <nb-list>
        <nb-list-item class="listItem" *ngFor="let alternative of survey.alternatives" >
            <input nbInput fullWidth readonly type="text" [value]="alternative" style="font-weight: normal;">
            <button nbSuffix nbButton ghost (click)="exclude(alternative)">
              <nb-icon icon="archive-outline"></nb-icon>
            </button>
        </nb-list-item>
      </nb-list>
    </nb-card>
    <div>
      <label>Outra Resposta:</label>
      <input type="checkbox" [(ngModel)]="survey.anotherAnswers" [(checked)]="survey.anotherAnswers" name="anotherAnswers" id="anotherAnswers"
          style="margin-left: 5px;">
    </div>
  </div>
<div class="form-group col-12">
</div>

  <div class="form-group col-12">
      <label>Publicar em Hotsites:</label>
      <nb-select fullWidth multiple placeholder="Hotsites" [(ngModel)]="survey.hotsites" name="surveyHotsites" [selected]="survey.hotsites">
          <nb-option *ngFor="let h of hotsites" [value]="h.id">{{h.title}}</nb-option>
      </nb-select>
  </div>
  <div class="form-group col-12">
      <label>Ativo:</label>
      <input type="checkbox" [(ngModel)]="survey.active" [(checked)]="survey.active" name="active" id="active"
          style="margin-left: 5px;">
  </div>
</form>
