<form #eventForm class="col-12">
  <div class="form-group col-12">
      <label for="name">Nome do Aluno:</label>
      <input type="name" [(ngModel)]="evidence.name" name="name" class="form-control col-12"
          placeholder="Nome do Aluno" id="name" autocomplete="off">
  </div>

  <div class="form-group col-12">
      <label >Profissão:</label>
      <input type="text" [(ngModel)]="evidence.occupation" name="occupation" class="form-control col-12" placeholder="Profissão"
          id="occupation">
  </div>

  <div class="form-group col-12">
       <label >Depoimento:</label>
       <editor [(ngModel)]="evidence.evidenceDesc" [ngModelOptions]="{standalone: true}" [init]="{height: 500,menubar: false, plugins: ['advlist autolink lists link image charmap print preview anchor',            'searchreplace visualblocks code fullscreen',            'insertdatetime media table paste code help wordcount'],         toolbar:
            'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'
    }" apiKey="{{tinymceKey}}"></editor>
  </div>

  <upload [maxLimitFiles]="1" (onUploadSuccess)="handleOnUploadSuccess($event)"
        (onUploadFailed)="handleOnUploadSuccessError($event)"></upload>

    <div class="form-group col-12" *ngIf="evidence.image">
        <minio-image style="max-height: 200px;" class="stretch" [src]="this.evidence.image"></minio-image>
    </div>

  <div class="form-group col-12">
    <label for="color">Descrição Imagem (VLibras):</label>
    <input type="text" [(ngModel)]="evidence.imageVLibra" name="imageVLibra" class="form-control col-12"
        placeholder="Descrição Imagem (VLibras)" id="text" autocomplete="off">
  </div>

  <div class="form-group col-12">
      <label for="color">Escola:</label>
      <nb-select fullWidth placeholder="Selecione..." [(ngModel)]="evidence.businessUnitId" name="businessUnitId" class="select-color col-12">
          <nb-option class="wrapper-icon-color" *ngFor="let b of businessUnit" [value]="b.unitCode">
              <span class="descriptive-text">{{b.name}}</span>
          </nb-option>
      </nb-select>
  </div>

  <div class="form-group col-12">
    <label for="color">Area:</label>
    <nb-select id="areaId" name="areaId" fullWidth placeholder="Selecione..." [(selected)]="evidence.areaId" class="select-color col-12">
        <nb-option class="wrapper-icon-color" *ngFor="let a of area" [value]="a.$id">{{a.areaDescription}}</nb-option>
    </nb-select>
  </div>

  <div class="form-group col-12">
    <label for="color">Curso:</label>
    <nb-select id="color" fullWidth placeholder="Selecione..." [(ngModel)]="evidence.courseId" name="courseId" class="select-color col-12">
        <nb-option class="wrapper-icon-color" *ngFor="let c of course" [value]="c.integrationCode">{{c.description}}</nb-option>
    </nb-select>
  </div>

  <div class="form-group col-12">
    <label for="color">Publicar em Hotsite:</label>
    <nb-select id="color" fullWidth multiple placeholder="Selecione..." [(ngModel)]="evidence.evidenceHotsites" name="evidenceHotsites" [(selected)]="evidence.evidenceHotsites" class="select-color col-12">
        <nb-option class="wrapper-icon-color" *ngFor="let h of hotsites" [value]="h.id" [hidden]="h.show === false" >
            <span class="descriptive-text">{{h.title}}</span>
        </nb-option>
    </nb-select>
  </div>

  <div class="form-group col-12">
    <label >Ativo:</label>
    <input type="checkbox" [(ngModel)]="evidence.show" [(checked)]="evidence.isActive" name="isActive" id="isActive" style="margin-left: 5px;">
  </div>

</form>
