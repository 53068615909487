<content-header title="Cursos">
  <div content-text>
    Nesta tela você poderá cadastrar e administrar os Cursos.
  </div>
  <div content-action>
    &nbsp;
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <div class="form-group col-12">
      <nb-form-field>
        <input nbInput type="text" placeholder="Pesquisar..." [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
        <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
        </button>
      </nb-form-field>
    </div>

    <div class="form-inline">
      <div class="form-group col-4 colline">
        <label for="color">Tipo:&nbsp;&nbsp;</label>
        <select [(ngModel)]="selectedOption" class="form-control filter" (change)="doSearch()">
          <option value="course">Cursos</option>
          <option value="product">Produtos</option>
        </select>
      </div>

      <div class="form-group col-4 colline area">
        <label for="color">Área:&nbsp;&nbsp;</label>
        <nb-select id="area" placeholder="Selecione..." [(selected)]="selectedArea" class="select-color filter" (selectedChange)="doSearch()">
          <nb-option class="wrapper-icon-color" *ngFor="let a of areasFilter" [value]="a.areaCode">
            <span class="descriptive-text">{{a?.areaDescription}}</span>
          </nb-option>
        </nb-select>
      </div>

      <div class="form-group col-4 colline" style="padding-top: 16px;">
        <label for="activeSiteOnly">Somente Ativos:&nbsp;&nbsp;</label>
        <input type="checkbox" [(ngModel)]="activeSiteOnly" [checked]="activeSiteOnly" name="activeSiteOnly" id="activeSiteOnly" (change)="doSearch()">
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <div class="alert alert-warning col-12" role="alert"
      *ngIf="courses.length == 0 && isinit && !errorOnLoaging && paginationSearch">
      Nenhum curso encontrado.
    </div>
    <div class="alert alert-danger col-12" role="alert"
      *ngIf="courses.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
      Nenhum curso cadastrado.
    </div>
    <div class="col-12 mx-auto table-responsive-sm no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed" *ngIf="courses.length > 0">
          <thead>
            <tr>
              <th class="code">Nome do Curso</th>
              <th class="code">Descrição</th>
              <th class="title">Área</th>
              <th class="description">Nível</th>
              <th class="check">Certificação</th>
              <th class="check">Ativo</th>
              <th class="options">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of courses">
              <td class="title-grid">
                {{(c.documentDescription === undefined ? c.documentName : c.documentDescription)}}
              </td>
              <td>
                {{ c.description }}
              </td>
              <td>
                {{normalizeArea((c.courseDetail !== undefined ? c.courseDetail?.areaId : c.productDetail?.areaId))}}
              </td>
              <td>
                {{ c.levelEducation }}
              </td>
              <td>
                {{normalizeCertication((c.courseDetail !== undefined ? c.courseDetail?.certification : c.productDetail?.certification))}}
              </td>
              <td>
                <input type="checkbox" [checked]="(c.courseDetail !== undefined ? c.courseDetail?.activeSite : c.productDetail?.activeSite)" disabled>
              </td>
              <td class="td-action">
                <btn-drop-action (onEdit)="onEdit(c)" [canRemove]="false">...</btn-drop-action>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
  <!-- Begin Pagination -->
  <div class="pagination-container" *ngIf="!paginationSearch && courses.length > 0">
    <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
      (goPage)="getCourse($event)">
    </pagination>
  </div>
  <div class="pagination-container" *ngIf="paginationSearch && courses.length > 0">
    <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
      [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
  </div>
  <div class="table-info-container" *ngIf="courses.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-course" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão desta noticia?</div>
  <div body *ngIf="!removeAction">
    <app-course-form #courseForm
      [detail]="genericDetail"
      [areas]="areas"
      [level]="levelEducation"
      [name]="name"
      [typeName]="selectedOption === 'course' ? 'Curso' : 'Produto'"
    ></app-course-form>
  </div>
</modal>
<!-- End Modal-->
