<div class="row">
    <div class="col-2">
    </div>
    <div class="col-6">
        <nav aria-label="Paginação" *ngIf="totalPage > 0" class="pagination-table">
        <ul class="pagination pagination-sm justify-content-center">
            <li class="page-item" [ngClass]="{'disabled': page === 1}">
                <a class="page-link" href="javascript:void(0)" (click)="goTo(1)" tabindex="-1">
                    <i class="fa fa-angle-double-left"></i>
                </a>
            </li>
            <li class="page-item" [ngClass]="{'disabled': page === 1}">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(-1)" tabindex="-1">
                    <i class="fa fa-chevron-left"></i>
                </a>
            </li>
            <li class="page-item" *ngFor="let index of pages(totalPage,1); let i = index" [ngClass]="{'active': page === index}">
                  <a class="page-link" tabindex="-1" href="javascript:void(0)" (click)="goTo(index)">
                    {{index}}
                </a>
            </li>
            <li class="page-item dropdown" *ngIf="totalPage-2 > 5 && (this.totalPage - this.page) > 5">
                <a class="page-link" href="#" tabindex="-1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    ...
                </a>
                <div class="dropdown-menu list-pages" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="goTo(index)" *ngFor="let index of pagesListFull(totalPage,1); let i = index" [ngClass]="{'active': page === index}">
                        {{index}}
                    </a>                
                </div>
            </li>
            <li class="page-item" *ngIf="totalPage-2 > 5 && (this.totalPage - this.page) > 5">
                <a class="page-link" href="javascript:void(0)" (click)="goTo(totalPage)" tabindex="-1">
                    {{totalPage}}
                </a>
            </li>
            <li class="page-item" [ngClass]="{'disabled': page === totalPage}">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(1)" tabindex="-1">
                    <i class="fa fa-chevron-right"></i>
                </a>
            </li>
            <li class="page-item" [ngClass]="{'disabled': page === totalPage}">
                <a class="page-link" href="javascript:void(0)" (click)="goTo(totalPage)" tabindex="-1">
                    <i class="fa fa-angle-double-right"></i>
                </a>
            </li>
          </ul>
        </nav>
    </div>
</div>