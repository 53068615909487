import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'app-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  items: NbMenuItem[] = [
    /*{
      title: 'Home',
      icon: 'home-outline',
      link: '/index',
      pathMatch: 'prefix'
    },*/
    {
      title: 'Carrossel',
      icon: 'image-outline',
      link: '/carousel',
      pathMatch: 'prefix'
    },
    {
      title: 'Hotsites',
      icon: 'link-outline',
      link: '/hotsites',
      pathMatch: 'prefix'
    },
    {
      title: 'Depoimentos',
      icon: 'message-square-outline',
      link: '/evidence',
      pathMatch: 'prefix'
    },
    {
      title: 'Enquetes',
      icon: 'checkmark-outline',
      link: '/survey',
      pathMatch: 'prefix'
    },
    {
      title: 'Notícias',
      icon: 'globe-outline',
      link: '/news',
      pathMatch: 'prefix'
    },
    {
      title: 'Artigos',
      icon: 'book-open-outline',
      link: '/article',
      pathMatch: 'prefix'
    },
    {
      title: 'Parcerias',
      icon: 'pricetags-outline',
      link: '/partnership',
      pathMatch: 'prefix'
    },
    {
      title: 'Escolas',
      icon: 'people-outline',
      link: '/schools',
      pathMatch: 'prefix'
    },
    {
      title: 'Eventos',
      icon: 'pin-outline',
      link: '/event',
      pathMatch: 'prefix'
    },
    {
      title: 'Cursos',
      icon: 'grid-outline',
      link: '/course',
      pathMatch: 'prefix'
    },
    {
      title: 'Cursos por Escola',
      icon: 'keypad-outline',
      link: '/course-business-unit',
      pathMatch: 'prefix'
    },
    {
      title: 'Cursos Unificados',
      icon: 'bulb-outline',
      link: '/linkedCourse',
      pathMatch: 'prefix'
    },
    {
      title: 'Meu Senac',
      icon: 'people-outline',
      link: '/meu-senac',
      pathMatch: 'prefix'
    },
    {
      title: 'Arquivos',
      icon: 'file-outline',
      link: '/media',
      pathMatch: 'prefix'
    }
  ];
  constructor() { }

  ngOnInit() {

  }

}
