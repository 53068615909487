import { BaseEntity } from "src/app/shared/@models/baseEntity";
import { evidenceHotsites } from "./evidence-hotsites";

export class evidence extends BaseEntity {
    name: string;
    occupation: string;
    evidenceDesc: string;
    image: string;
    integrationCode: string;
    removedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    updatedUser: string;
    updatedUserId: string;
    businessUnitId: string;
    businessUnitName: string;
    courseId?: string;
    courseDocumentDescription?: string;
    productId?: string;
    productDocumentName?: string;
    areaId?: number;
    areaDescription: string;
    show: boolean;
    evidenceHotsites: evidenceHotsites[];
    imageVLibra: string;

    constructor(
        id?: string,
        name?: string,
        occupation?: string,
        evidenceDesc?: string,
        image?: string,
        businessUnitId?: string,
        businessUnitName?: string,
        courseId?: string,
        courseDocumentDescription?: string,
        productId?: string,
        productDocumentName?: string,
        areaId?: number,
        areaDescription?: string,
        show?: boolean,
        evidenceHotsites?: evidenceHotsites[],
        imageVLibra?: string
    ) {
        super();
        this.id = id;
        this.name = name;
        this.occupation = occupation;
        this.evidenceDesc = evidenceDesc;
        this.image = image;
        this.businessUnitId = businessUnitId;
        this.businessUnitName = businessUnitName;
        this.courseId = courseId;;
        this.courseDocumentDescription = courseDocumentDescription;
        this.productId = productId;
        this.productDocumentName = productDocumentName;
        this.areaId = areaId;
        this.areaDescription = areaDescription;
        this.show = show;
        this.evidenceHotsites = evidenceHotsites;
        this.imageVLibra = imageVLibra;
    }
}
