<nb-tabset class="my-nb-tabset">

  <nb-tab tabTitle="Dados da Escola">
    <form #FormSchoolData class="col-12">

      <div class="form-group col-12">
        <label for="active">Ativo:&nbsp;&nbsp;</label>
        <nb-checkbox id="active" name="active" [(ngModel)]="school.show" [checked]="school.show"></nb-checkbox>
      </div>

      <div class="form-group col-12">
        <label for="openModel">Agenda de Modelos:&nbsp;&nbsp;</label>
        <nb-checkbox id="openModel" name="openModel" [(ngModel)]="school.openModel" [checked]="school.openModel"></nb-checkbox>
      </div>

      <div class="form-group col-12">
        <label for="name">Nome da Escola:&nbsp;&nbsp;</label>
        <input type="text" id="name" name="name" [ngModel]="school.name" class="form-control col-12" disabled>
      </div>

      <div class="form-group col-12">
        <label for="description">Descrição Site:</label>
        <input type="text" id="description" name="description" [(ngModel)]="school.siteDescription"
          class="form-control col-12" placeholder="">
      </div>

      <div class="form-group col-12">
        <label for="facebookurl">Facebook:</label>
        <input type="text" id="facebookurl" name="facebookurl" [(ngModel)]="school.facebookUrl"
          class="form-control col-12" placeholder="">
      </div>

      <div class="form-group col-12">
        <label for="instagramUrl">Instagram:</label>
        <input type="text" id="instagramUrl" name="instagramUrl" [(ngModel)]="school.instagramUrl"
          class="form-control col-12" placeholder="">
      </div>

      <div class="form-group col-12">
        <label for="about">Conheça a Escola:</label>
        <textarea name="about" id="about" [(ngModel)]="school.about" class="form-control col-12" placeholder="" rows="7"
          style="resize: none;"></textarea>
      </div>

      <div class="form-group col-12">
        <label for="director">Nome Diretor (a):</label>
        <input type="text" id="director" name="director" [(ngModel)]="school.directorName" class="form-control col-12"
          placeholder="">
      </div>

      <div class="form-group col-12">
        <label for="directorsex">Sexo:&nbsp;&nbsp;</label>
        <nb-select placeholder="M/F" id="directorSex" name="directorSex" [(ngModel)]="school.directorSex"
          (selectedChange)="onSelectedDirectorSexChange($event)">
          <nb-option value="Masculino">Masculino</nb-option>
          <nb-option value="Feminino">Feminino</nb-option>
        </nb-select>
      </div>
      <div class="form-group col-12">
        <label>Horario de Funcionamento em dias uteis:</label>
      </div>
      <div class="form-row col-12">
        <div class="form-group col-sm-6">
          <input nbInput [nbTimepicker]="openingHoursWorkDays" placeholder="Horário Inicial" [(ngModel)]="school.openingHoursWorkDays"
            value="{{ school.openingHoursWorkDays | date:'HH:mm' }}" name="openingHoursWorkDays" id="openingHoursWorkDays"
            class="form-control">
          <nb-timepicker #openingHoursWorkDays format="HH:mm"></nb-timepicker>
        </div>
        <div class="form-group col-sm-6">
          <input nbInput [nbTimepicker]="closingHoursWorkDays" placeholder="Horário Final" [(ngModel)]="school.closingHoursWorkDays"
            value="{{ school.closingHoursWorkDays | date:'HH:mm' }}" name="closingHoursWorkDays" id="closingHoursWorkDays"
            class="form-control">
          <nb-timepicker #closingHoursWorkDays format="HH:mm"></nb-timepicker>
        </div>
      </div>
      <div class="form-group col-12">
        <label>Horario de Funcionamento em finais de semana:</label>
      </div>
      <div class="form-row col-12">
        <div class="form-group col-sm-6">
          <input nbInput [nbTimepicker]="openingHoursWeekend" placeholder="Horário Inicial" [(ngModel)]="school.openingHoursWeekend"
            value="{{ school.openingHoursWeekend | date:'HH:mm' }}" name="openingHoursWeekend" id="openingHoursWeekend"
            class="form-control">
          <nb-timepicker #openingHoursWeekend format="HH:mm"></nb-timepicker>
        </div>
        <div class="form-group col-sm-6">
          <input nbInput [nbTimepicker]="closingHoursWeekend" placeholder="Horário Final" [(ngModel)]="school.closingHoursWeekend"
            value="{{ school.closingHoursWeekend | date:'HH:mm' }}" name="closingHoursWeekend" id="closingHoursWeekend"
            class="form-control">
          <nb-timepicker #closingHoursWeekend format="HH:mm"></nb-timepicker>
        </div>
      </div>
    </form>
  </nb-tab>

  <nb-tab tabTitle="Imagens">
    <div class="form-group col-12">
      <label for="color">Foto Diretor (a):</label>
      <upload [maxLimitFiles]="1" (onUploadSuccess)="handleDirectorPhotoOnUploadSuccess($event)"
        (onUploadFailed)="handleDirectorPhotoOnUploadSuccessError($event)"></upload>

      <div class="form-group col-12" *ngIf="school.directorPhoto">
        <button type="button" aria-label="Fechar" class="close pull-left" (click)="handleRemoveDirectorPhoto()">
          <span aria-hidden="true">×</span>
        </button>
        <minio-image style="max-height: 100px; max-width: 100px;" class="stretch"
          [src]="this.school.directorPhoto"></minio-image>
      </div>
      <div class="form-group col-6">
        <input fullWidth type="text" id="vLibrasDirectorDescription" name="vLibrasDirectorDescription"
          class="form-control col-12" placeholder="Descrição VLibras"
          [(ngModel)]="school.vLibrasDirectorDescription">
      </div>
    </div>
    <div class="form-group col-12">
      <label for="color">Foto da Fachada:</label>
      <upload [maxLimitFiles]="1" (onUploadSuccess)="handleBuildFacedePhotoOnUploadSuccess($event)"
        (onUploadFailed)="handleBuildFacedePhotoOnUploadSuccessError($event)"></upload>

      <div class="form-group col-12" *ngIf="this.school.buildFacedePhoto">
        <button type="button" aria-label="Fechar" class="close pull-left" (click)="handleRemoveBuildFacedePhoto()">
          <span aria-hidden="true">×</span>
        </button>
        <minio-image style="max-height: 100px; max-width: 100px;" class="stretch"
          [src]="this.school.buildFacedePhoto"></minio-image>
      </div>
      <div class="form-group col-6">
        <input fullWidth type="text" id="vLibrasBuildFacedeDescription" name="vLibrasBuildFacedeDescription"
          class="form-control col-12" placeholder="Descrição VLibras"
          [(ngModel)]="school.vLibrasBuildFacedeDescription">
      </div>
    </div>
    <div class="form-group col-12">
      <label for="color">Fotos da Escola:</label>
      <div class="col-6">
        <label for="photoDescription">Descrição da foto:</label>
      </div>
      <div class="col-6">
        <input fullWidth type="text" id="photoDescription" name="photoDescription" class="form-control col-12"
          placeholder="Descrição da foto" [(ngModel)]="photoDescription">
      </div>
      <div class="col-6">
        <label for="vlibrasDescription">Descrição VLibras:</label>
      </div>
      <div class="col-6">
        <input fullWidth type="text" id="vlibrasDescription" name="vlibrasDescription" class="form-control col-12"
          placeholder="Descrição VLibras" [(ngModel)]="vlibrasDescription">
      </div>
      <div>
        <br />
        <upload [maxLimitFiles]="1" [isDisableUploadAction]="handlePhotoDisableUploadAction()"
          (onUploadSuccess)="handlePhotosOnUploadSuccess($event)"
          (onUploadFailed)="handlePhotosOnUploadSuccessError($event)"></upload>
      </div>
    </div>
    <div class="form-group col-12">
      <div *ngFor="let photo of school.photos" class="carousel">
        <div *ngIf="photo.isActive">
          <button type="button" aria-label="Fechar" class="close pull-right" (click)="removeImageSchool(photo)">
            <span aria-hidden="true">×</span>
          </button>
          <minio-image style="max-width: 100px" class="stretch" [src]="photo.photoData"></minio-image>
        </div>
      </div>
    </div>
  </nb-tab>

  <nb-tab tabTitle="Endereço">
    <form #FormSchoolAdress class="col-12">

      <div class="form-group col-12">
        <label for="completeAddress">Endereço:&nbsp;&nbsp;</label>
        <input type="text" id="completeAddress" name="completeAddress" [(ngModel)]="school.unitAdress.completeAddress"
          class="form-control col-12">
      </div>

      <div class="form-group col-12">
        <label for="city">Cidade:&nbsp;&nbsp;</label>
        <input type="text" id="city" name="city" [(ngModel)]="school.unitAdress.city" class="form-control col-12">
      </div>

      <div class="form-group col-2">
        <label for="state">UF:&nbsp;&nbsp;</label>
        <input type="text" id="state" name="state" [(ngModel)]="school.unitAdress.state" class="form-control col-12">
      </div>

      <div class="form-group col-6">
        <label for="neighborhood">Bairro:&nbsp;&nbsp;</label>
        <input type="text" id="neighborhood" name="neighborhood" [(ngModel)]="school.unitAdress.neighborhood"
          class="form-control col-12">
      </div>

      <div class="form-group col-6">
        <label for="zipCode">CEP:&nbsp;&nbsp;</label>
        <input type="text" id="zipCode" name="zipCode" [(ngModel)]="school.unitAdress.zipCode"
          class="form-control col-12">
      </div>

      <div class="form-group col-6">
        <label for="regionDescription">Região:&nbsp;&nbsp;</label>
        <input type="text" id="regionDescription" name="regionDescription" [(ngModel)]="school.regionDescription"
          class="form-control col-12">
      </div>

      <div class="form-group col-12">
        <label for="googleMapsUrl">Endereço Google:&nbsp;&nbsp;</label>
        <input type="text" id="googleMapsUrl" name="googleMapsUrl" [(ngModel)]="school.unitAdress.googleMapsUrl"
          class="form-control col-12">
      </div>

    </form>
  </nb-tab>

  <nb-tab tabTitle="Contato">
    <form #FormSchoolContacts class="col-12">
      <div class="form-row col-12">

        <div class="form-group col-5">
          <label for="contacttype">Tipo:&nbsp;&nbsp;</label>
          <nb-select fullWidth placeholder="Tipo" id="contacttype" name="contacttype"
            (selectedChange)="onSelectedContactTypeChange($event)" [(ngModel)]="contactAdd.contactType" class="">
            <nb-option value="Telefone">Telefone</nb-option>
            <nb-option value="Celular">Celular</nb-option>
            <nb-option value="WhatsApp">WhatsApp</nb-option>
          </nb-select>
        </div>

        <div class="form-group col-3">
          <label for="phone">Telefone:</label>
          <input fullWidth type="text" id="phone" name="phone" [(ngModel)]="contactAdd.contact" mask='(00) 00000-0000'
            class="form-control col-12" placeholder="">
        </div>

        <div class="form-group col-3">
          <label for="sector">Setor:</label>
          <input fullWidth type="text" id="sector" name="sector" [(ngModel)]="contactAdd.sector"
            class="form-control col-12" placeholder="">
        </div>

        <div class="form-group col-1" style="text-align: center;margin-top:27px">
          <button nbButton status="info" (click)="addContac()">
            <nb-icon icon="plus-square-outline"></nb-icon>
          </button>
        </div>
      </div>
    </form>
    <br>
    <br>
    <h4>Contatos</h4>
    <div class="col-12 mx-auto table-responsive no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed center" style="text-align:center;">
          <thead>
            <tr>
              <th><b>Tipo</b></th>
              <th><b>Telefone</b></th>
              <th><b>Setor</b></th>
              <th><b>Ações</b></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contact of school.contacts" style="max-width: 244px; overflow: hidden;">
              <td>{{ contact.contactType | slice:0:20 }}</td>
              <td>{{ contact.contact |  mask: '(00) 00000-0000'}}</td>
              <td>{{ contact.sector }}</td>
              <td class="td-action inner_button">
                <button nbButton status="" (click)="deleteContact(contact)">
                  <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-tab>

  <nb-tab tabTitle="Arquivos">
    <form #FormSchoolContacts class="col-12">
      <div class="form-row col-12">
        <div class="form-group col-6">
          <label for="contacttype">Chave no Site:&nbsp;&nbsp;</label>
          <nb-select fullWidth 
            placeholder="Selecione..."
            id="mediaSiteKey" 
            name="mediaSiteKey"
            (selectedChange)="onSelectedChangeMediaSiteKey($event)"
            [(ngModel)]="media.mediaSiteKeyId" 
            name="mediaSiteKey"
            [selected]="media.mediaSiteKeyId"
          >
            <nb-option *ngFor="let msk of mediaSiteKeys"
              [value]="msk.id"
            >
              {{ msk.siteKey }}
            </nb-option>
          </nb-select>
        </div>

        <div class="form-group col-4">
          <label for="contacttype">Arquivo:&nbsp;&nbsp;</label>
          <upload
            [maxLimitFiles]="1" 
            (onUploadSuccess)="saveFileSuccess($event)"
            (onUploadFailed)="saveFileFailed($event)"
            [isDisableUploadAction]="!media?.mediaSiteKeyId"
            [fileTypes]="typeFile"
            [mediaId]="media.id"
            [mediaSiteKeyId]="media.mediaSiteKeyId"
            [businessUnitId]="media.businessUnitId"
            [saveMedia]="saveMedia"
            [resetMedia]="resetMedia"
          >
          </upload>
        </div>

        <div class="form-group col-2" style="text-align: center;margin-top:27px">
          <button nbButton status="info" (click)="saveMediaFile()">
            <nb-icon icon="plus-square-outline"></nb-icon>
          </button>
          &nbsp;
          <button nbButton status="danger" (click)="clearMediaFile()">
            <nb-icon icon="close-square-outline"></nb-icon>
          </button>
        </div>

        <div class="form-group col-12">
          <label *ngIf="description">Descrição da Chave:&nbsp;</label> <span *ngIf="description" [innerText]="description"></span>
        </div>
      </div>
    </form>
    <br>
    <br>
    <h4>Arquivos</h4>
    <div class="col-12 mx-auto table-responsive no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed center" style="text-align:center;">
          <thead>
            <tr>
              <th><b>Chave</b></th>
              <th><b>Arquivo</b></th>
              <th class="inner_button"><b>Ações</b></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of medias" style="max-width: 244px; overflow: hidden;">
              <td>{{m.mediaSiteKey?.siteKey}}</td>
              <td>{{m.alternativeDescription}}</td>
              <td class="td-action inner_button">
                <btn-drop-action (onEdit)="editMedia(m)" (onRemove)="removeMedia(m)" (onDownload)="downloadMedia(m)" canDownload="true">...</btn-drop-action>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-container" *ngIf="!paginationSearchMedia && medias.length > 0">
        <pagination [totalRows]="totalRowsMedia" [pageSize]="pageSizeMedia" [rows]="rowsMedia" [page]="pageMedia" [totalPage]="totalPageMedia"
          (goPage)="getMedia($event)">
        </pagination>
      </div>
      <div class="pagination-container" *ngIf="paginationSearchMedia && medias.length > 0">
        <pagination class="paginationMedia" [totalRows]="totalRowsMedia" [pageSize]="pageSizeMedia" [rows]="rowsMedia" [page]="pageMedia"
          [totalPage]="totalPageMedia" (goPage)="getMedia($event)"></pagination>
      </div>
      <div class="table-info-container" *ngIf="medias.length > 0">
        <div class="table-info-label">
          <div>Página {{pageMedia}} de {{totalPageMedia}}. Registros: {{rowsMedia}} de {{totalRowsMedia}}</div>
        </div>
      </div>
    </div>
  </nb-tab>
</nb-tabset>