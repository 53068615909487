<form #newsForm class="col-12">
    <div class="form-group col-12">
        <label>Título:</label>
        <input fullWidth type="text" [(ngModel)]="news.title" name="title" class="form-control col-12"
            placeholder="Título" id="title">
    </div>
    <div class="form-group col-12">
        <label>Resumo:</label>
        <textarea nbInput fullWidth [(ngModel)]="news.resume" name="resume" class="form-control col-12"
            placeholder="Resumo" id="resume"></textarea>
    </div>
    <div class="form-group col-12">
        <label>Texto:</label>
        <editor [(ngModel)]="news.text" [ngModelOptions]="{standalone: true}" [init]="{height: 500,menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'
        }" apiKey="{{tinymceKey}}"></editor>
    </div>

    <upload [maxLimitFiles]="1" (onUploadSuccess)="handleOnUploadSuccess($event)"
        (onUploadFailed)="handleOnUploadSuccessError($event)"></upload>

    <div class="form-group col-12" *ngIf="news.photo">
        <minio-image style="max-height: 200px" [src]="this.news.photo"></minio-image>
    </div>
    <div class="form-group col-12">
        <label>VLibra:</label>
        <input fullWidth type="photoVLibra" [(ngModel)]="news.photoVLibra" name="photoVLibra"
            class="form-control col-12" placeholder="VLibra" id="photoVLibra">
    </div>
    <div class="form-group col-12">
        <label>Legenda da Foto:</label>
        <input fullWidth type="photoLegend" [(ngModel)]="news.photoLegend" name="photoLegend"
            class="form-control col-12" placeholder="Legenda da Foto" id="photoLegend">
    </div>
    <div class="form-group col-12">
        <label>Escolas:</label>
        <nb-select fullWidth multiple placeholder="Escolas" [(ngModel)]="news.newsBusinessUnits" name="newsBusinessUnits"
            [selected]="news.newsBusinessUnits">
            <nb-option *ngFor="let h of businessUnits" [value]="h.unitCode">{{h.name}}</nb-option>
        </nb-select>
    </div>
    <div class="form-group col-12">
        <label>Área:</label>
        <nb-select fullWidth placeholder="Área" [(ngModel)]="news.areaId" name="areaId" [selected]="news.areaId">
            <nb-option *ngFor="let b of areas" [value]="b.areaCode">{{b.areaDescription}}</nb-option>
        </nb-select>
    </div>
    <div class="form-group col-12">
        <label>Hotsites:</label>
        <nb-select fullWidth multiple placeholder="Hotsites" [(ngModel)]="news.newsHotsites" name="newsHotsites"
            [selected]="news.newsHotsites">
            <nb-option *ngFor="let h of hotsites" [value]="h.id">{{h.title}}</nb-option>
        </nb-select>
    </div>
    <div class="form-group col-12">
        <label>Data da Notícia:</label>
        <input nbInput fullWidth placeholder="Data da Notícia" [nbDatepicker]="newsDate" [(ngModel)]="news.date"
            value="{{ news.date | date:'dd/MM/yyyy' }}" name="date" class="form-control col-12">
        <nb-datepicker #dateTimePicker format="DD/MM/yyyy" #newsDate></nb-datepicker>
    </div>
    <div class="form-group col-12">
        <label>Link Externo:</label>
        <input type="text" [(ngModel)]="news.externalLink" name="externalLink" class="form-control col-12"
            placeholder="Link Externo" id="externalLink">
    </div>
    <div class="form-group col-12">
        <label>Notícia Destaque:</label>
        <input type="checkbox" [(ngModel)]="news.prominence" [checked]="news.prominence" name="prominence"
            id="prominence" style="margin-left: 5px;">
    </div>
    <div class="form-group col-12">
        <label>Ativo:</label>
        <input type="checkbox" [(ngModel)]="news.show" [checked]="news.show" name="show" id="show"
            style="margin-left: 5px;">
    </div>
</form>
