import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-btn-drop-action',
  templateUrl: './carousel-btn-drop-action.component.html',
  styleUrls: ['./carousel-btn-drop-action.component.scss']
})
export class CarouselBtnDropActionComponent  implements OnInit {

  @Output() onEdit: EventEmitter<any>;
  @Output() onRemove: EventEmitter<any>;
  @Output() onSend: EventEmitter<any>;
  @Output() onUp: EventEmitter<any>;
  @Output() onDown: EventEmitter<any>;

  @Input() schedule: boolean;


  constructor() {
    this.onEdit = new EventEmitter<any>();
    this.onRemove = new EventEmitter<any>();
    this.onSend = new EventEmitter<any>();
    this.onUp = new EventEmitter<any>();
    this.onDown = new EventEmitter<any>();
   }

  ngOnInit() {
  }

  edit = (event:any) => {
    this.onEdit.emit();
  }

  remove = (event:any) => {
    this.onRemove.emit();
  }

  send = (event:any) => {
    this.onSend.emit();
  }

  up = (event:any) => {
    this.onUp.emit();
  }

  down = (event:any) => {
    this.onDown.emit();
  }

}
