<app-header class="noprint"></app-header>

<nb-layout>
  <nb-sidebar class="noprint">
    <div class="logo_header_senac noprint"></div>
    <div style="text-align: center;">CMS</div>
    <app-menu class="noprint"></app-menu>
  </nb-sidebar>
  <nb-layout-column [className]="isDashboard ? 'dashboard' : ''">
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>