import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { areas } from 'src/app/shared/@models/areas';
import { environment } from 'src/environments/environment';
import { course } from '../../@model/course';
import { GenericDetail } from '../../@model/generic-detail';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrls: ['./course-form.component.scss']
})
export class CourseFormComponent implements OnInit {
  @Input() detail: GenericDetail = new GenericDetail();
  @Input() areas: areas[] = [];
  @Input() level: string;
  @Input() name: string = '';
  @Input() typeName: string = 'Curso';
  fileTypePdf: string = "pdf";

  tinymceKey: string = environment.tinymceKey;
  editorInit = {
    height: 250,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'
  };

  COURSE_DETAILS_MAP: { [key: string]: string } = {
    description: 'Descrição',
    methodology: 'Metodologia',
    courseware: 'Material Didático',
    prerequisite: 'Pré-requisitos',
    registrationDocuments: 'Documentos para Matrícula',
    materialList: 'Lista de materiais',
    observations: 'Observações',
    resolutions: 'Resolução',
    modality: 'Modalidade',
    differentials: 'Diferenciais',
    laborMarket: 'Mercado de trabalho',
    program: 'Programa',
    teachingStaff: 'Corpo Docente'
  };

  selectedDetail: string;


  constructor(
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
  }

  clear(): void {
    this.selectedDetail = null;
  }

  listDetails(): { [key: string]: string } {
    return this.COURSE_DETAILS_MAP;
  }

  click(detailKey: string) {
    this.selectedDetail = detailKey;
  }

  isSelectedButton(detailKey: string): boolean {
    return this.selectedDetail === detailKey;
  }

  anySelectedButton(): boolean {
    if (this.selectedDetail) {
      return true;
    }

    return false;
  }

  removeImage() {
    this.detail.photoFile = undefined;
  }

  handleOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.detail.photoFile = data[0].source;
  }

  handleOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handlePhoto(): string {
    return this.detail.photoFile ? `${environment.urls.minio}/${this.detail.photoFile}` : null;
  }


  handleOnUploadSuccessPdf(data: any): void {
    if (data && data.length > 0)
      this.detail.presentationFile = data[0].source;
  }

  handlePdf(): string {
    return this.detail.presentationFile ? `${environment.urls.minio}/${this.detail.presentationFile}` : null;
  }


  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
    };
  }

}
