import { BaseEntity } from "src/app/shared/@models/baseEntity";

export class article extends BaseEntity {
    title: string;
    autor: string;
    formation: string;
    text: string;
    hotSitesId: string[];
    businessUnitId: string;
    businessUnitName: string;
    areaId: string;
    areaName: string;
    show: boolean;

    constructor() {
        super();
    }
}
