<form #eventForm class="col-12">
    <div class="form-group col-12">
        <label for="description">Parceria:</label>
        <input type="text" id="description" name="description" class="form-control col-12"
            placeholder="" [(ngModel)]="partnership.description">
    </div>
    <div class="form-group col-4">
        <label for="discountPercentage">% Desconto:</label>
        <input type="number"  min="0.1" max="100" step="0.1"
        id="discountPercentage" name="discountPercentage" class="form-control col-12"
            placeholder="" [(ngModel)]="partnership.discountPercentage">
    </div>
    <div class="form-group col-12">
        <label for="coverage">Abrangência:</label>
        <input type="text" id="coverage" name="coverage" class="form-control col-12"
            placeholder="" [(ngModel)]="partnership.coverage">
    </div>
    <div class="form-group col-12">
        <label for="benefited">Beneficiados:</label>
        <input type="text" id="benefited" name="benefited" class="form-control col-12"
            placeholder="" [(ngModel)]="partnership.benefited">
    </div>
    <div class="form-group col-12">
        <label for="active">Ativo:&nbsp;&nbsp;</label>
        <input type="checkbox" [(ngModel)]="partnership.active" [(checked)]="partnership.active" name="active"  id="active" style="margin-left: 5px;">
    </div>
</form>