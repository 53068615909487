import { ApiClientService } from '../../shared/services/api-client.service';

import { Injectable } from '@angular/core';
import { ResponseMessage } from '../../shared/@models/responseMessage';
import { LoadingService } from '../../shared/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Subscriber } from '../@model/subscriber';
import { SubscriberStatus } from '../@model/subscriber-status';
import { Event } from '../@model/event';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  public DEFAULT_SUBSCRIBER_STATUS = 'df2b113a-67d6-41de-9e0a-d4a97eea8b88';  // Inscrito

  constructor(
    private apiClientService: ApiClientService
  ) { }

  getEvent(eventId: string, success: (event: Event) => void, error: (err: any) => void) {
    console.log('getEvent', eventId);
    this.apiClientService.get<Event>(environment.urls.api, 'event', eventId)
      .catch((response) => {
        error(response);
        this.apiClientService.showErrorStatusCode(response.status, response.error);
      })
      .then((event: Event) => success(event));
}


  getSubscriberStatus(success: (subscribers: SubscriberStatus[]) => void, error: (err: any) => void) {
    this.apiClientService.getPaginated(environment.urls.api, 'subscribertype', 1, 100)
      .catch((response) => {
        error(response);
        this.apiClientService.showErrorStatusCode(response.status, response.error);
      })
      .then((data: ResponseMessage) => {
        success(data.data);
      });
  }

  updateStatus(subscriber: Subscriber, success: (data: Subscriber) => void, error: (err: any) => void) {
    return this.save(subscriber, success, error);
  }

  delete(subscriverId: string, success: (data: Subscriber) => void, error: (err: any) => void) {
    this.apiClientService.delete<Subscriber>(
      environment.urls.api,
      'subscriber',
      subscriverId,
      success,
      error
    );
  }

  save(subscriber: Subscriber, success: (data: any) => void, error: (err: any) => void) {
    this.apiClientService.save<Subscriber>(
      environment.urls.api,
      'subscriber',
      subscriber,
      success,
      error
    );
  }
}
