
import { BaseEntity } from "../../shared/@models/baseEntity";

export class Partnership extends BaseEntity {

    public description: string;
    public discountPercentage: number;
    public benefited: string;
    public coverage: string;
    public active: boolean;

    constructor(
        id?: string,
        description?: string,
        discountPercentage?: number,
        coverage?: string,
        benefited?: string,
        active?: boolean,

    ) {
        super();
        this.id = id;
        this.description = description;
        this.discountPercentage = discountPercentage;
        this.coverage = coverage;
        this.benefited = benefited;
        this.active = active;
    }

}