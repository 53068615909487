import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'search-bar',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  element: any;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    let search = this;
    // this.element.querySelector('.input-search').addEventListener('focusout', function(e: any) {
    //   search.closeSearch();
    // });
    
  }

  openSearch() {
    this.element.querySelector('.btn-open-search').classList.remove('show');
    this.element.querySelector('.form-search-bar').classList.add('show');
    this.element.querySelector('.input-search').focus();
  }

  closeSearch() {
    this.element.querySelector('.btn-open-search').classList.add('show');
    this.element.querySelector('.form-search-bar').classList.remove('show');
  }

}
