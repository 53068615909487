import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvidenceIndexComponent } from './@pages/evidence-index/evidence-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';


const routes: Routes = [
  {
    path: 'evidence/index',
    component: EvidenceIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class EvidenceRoutingModule { }
