import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HttpClientModule } from '@angular/common/http';
import { NbMomentDateModule } from '@nebular/moment';
import { NbCardModule, NbFormFieldModule, NbIconModule, NbButtonModule, NbActionsModule, NbSelectModule, NbInputModule, NbDatepickerModule, NbListModule, NbTimepickerModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';

import { EventRoutingModule } from './event-routing.module';

import { EventFormComponent } from './@pages/event-form/event-form.component';
import { EventIndexComponent } from './@pages/event-index/event-index.component';
import { EventBtnDropActionComponent } from './components/event-btn-drop-action/event-btn-drop-action.component';
import { SubscriberFormComponent } from './@pages/subscriber-form/subscriber-form.component';
import { SubscriberIndexComponent } from './@pages/subscriber-index/subscriber-index.component';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    CommonModule,
    FormsModule,
    EditorModule,
    HttpClientModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbMomentDateModule,
    NbSelectModule,
    NbTimepickerModule,
    NgxCurrencyModule,
    NgxMaskModule.forRoot(options),
    SharedModule,
    EventRoutingModule,
  ],
  declarations: [
    EventFormComponent,
    EventIndexComponent,
    SubscriberFormComponent,
    SubscriberIndexComponent,
    EventBtnDropActionComponent
  ]
})
export class EventModule { }
