import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { banner } from '../../@model/banner';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel-form',
  templateUrl: './carousel-form.component.html',
  styleUrls: ['./carousel-form.component.scss']
})
export class CarouselFormComponent implements OnInit {
  @Input() banner: banner;
  @Input() businessUnit: businessUnit[] = [];

  disabledSelectBusinessUnit: boolean = false;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) {
    
  }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if (this.banner) {
      this.onHomePageAndBusinessUnitChange()
    }
  }

  removeImage() {
    this.banner.image = undefined;
  }

  handleOnUploadSuccess(data: any): void {
    if (data && data.length > 0)
      this.banner.image = data[0].source;
  }

  handleOnUploadSuccessError(error: any): void {
    if (error)
      this.toastr.error("Não foi possivel realizar o upload da media!");
  }

  handlePhoto(): string {
    return this.banner.image ? `${environment.urls.minio}/${this.banner.image}` : null;
  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
    };
  }

  onHomePageAndBusinessUnitChange() {
    if (this.banner.onHomePageAndBusinessUnit && this.banner.onHomePageAndBusinessUnit === true) {
      this.disabledSelectBusinessUnit = true;
      this.banner.businessUnitId = undefined;
    } else {
      this.disabledSelectBusinessUnit = false;
    }
  }
}
