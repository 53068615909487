export class GenericDetail {
    siteName: string;
    workload: number;
    areaId: string;
    description: string;
    methodology: string;
    courseware: string ;
    prerequisite: string;
    certification: boolean;
    registrationDocuments: string;
    materialList: string;
    observations: string;
    presentationFile: string;
    keyWords: string;
    insctructors: string;
    photoFile: string;
    photoVLibras: string;
    activeSite: boolean;
    resolutions: string;
    modality: string;
    differentials: string;
    laborMarket: string;
    program: string;
    teachingStaff: string;
    courseId: string;
    productId: string;
    urlExternal: string;
    turmaEcommerce: boolean;
}
