import { NgModule } from '@angular/core';
import { CourseIndexComponent } from './@pages/course-index/course-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';


const routes: Routes = [
  {
    path: 'course/index',
    component: CourseIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class CourseRoutingModule { }
