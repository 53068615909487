import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { EventType } from '../../@model/event-type';

import { hotsites } from './../../../hotsites/@model/hotsites';
import { Event } from './../../@model/event';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  @Input() event: Event;
  @Input() eventTypes: EventType[];

  businessUnits: any;
  selectedBU: string[] = [];
  hotsites: hotsites[] = [];
  visibleField: boolean = false;

  toggleVisibleField() {
    this.visibleField = !this.visibleField;
  }

  tinymceKey: string = environment.tinymceKey;
  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService
  ) { }

  ngOnInit() {
    this.getBusinessUnits();
    this.getHotsites();
    this.tinymceKey = environment.tinymceKey;
  }

  getBusinessUnits() {
    this.apiClientService
      .getAll(environment.urls.api, 'businessUnit')
      .then((data: any) => {
        this.businessUnits = data;
      })
      .finally(() => { });
  }

  getHotsites(n: number = 1) {
    this.apiClientService
      .getPaginated(environment.urls.api, 'hotsite', n, 20000)
      .then((response: ResponseMessage) => {
        this.hotsites = [];
        if (response != null && response.rows > 0) {
          response.data.forEach((d) => {
            const h = new hotsites();
            h.id = d.id;
            h.title = d.title;
            this.hotsites.push(h);
          });
        }
      })
      .finally(() => { });
  }

  buSelectedChange(businesUnitIds: string[]) {
    this.event.eventBusinessUnits = businesUnitIds;
  }

  allBU(): void {
    if (this.event.eventBusinessUnits.length === this.event.eventBusinessUnits.length && this.event.eventBusinessUnits.length > 0)
      this.event.eventBusinessUnits = [];
    else {
      this.event.eventBusinessUnits = [];
      this.businessUnits.forEach(element => {
        this.event.eventBusinessUnits.push(element.unitCode);
      });
    }
  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (
      loader
    ) {
      console.log(btoa(loader.file));
      //return new UploadAdapter(loader);
    };
  }

  disableSpecificEventType(eventType: EventType) {
    // if (this.businessUnits?.length === 1) {
    //   return eventType.description.toLowerCase() === 'Evento'.toLocaleLowerCase();
    // }

    return false;
  }
}
