import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbSelectModule, NbTabsetModule, NbThemeModule, NbUserModule } from '@nebular/theme';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ArticleIndexComponent } from './@pages/article-index/article-index.component';
import { ArticleFormComponent } from './@pages/article-form/article-form.component';
import { ArticleRoutingModule } from './article-routing.module';
import { EditorModule } from '@tinymce/tinymce-angular';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [ArticleIndexComponent, ArticleFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ArticleRoutingModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    BsDropdownModule.forRoot(),
    EditorModule,
    /* NEBULAR - Modules */
    NbLayoutModule,
    NbSelectModule,
    NbActionsModule,
    NbCheckboxModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbIconModule,
    NbFormFieldModule,
    NbTabsetModule,
    NbUserModule,
    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NbInputModule,
  ]
})
export class ArticleModule { }
