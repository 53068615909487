import { PartnershipIndexComponent } from './partnership/@pages/partnership-index/partnership-index.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CanDeactivateGuard } from './shared/strategies/can-deactivate-guard.service';
import { SelectivePreloadingStrategy } from './shared/strategies/selective-preloading-strategy';
import { AuthenticationGuard } from './shared/auth/authentication.guard';
import { AuthorizationGuard } from './shared/auth/authorization.guard';
import { IndexComponent } from './index/index.component';
import { CarouselIndexComponent } from './carousel/@pages/carousel-index/carousel-index.component';
import { ArticleIndexComponent } from './article/@pages/article-index/article-index.component';
import { HotsitesIndexComponent } from './hotsites/@pages/hotsites-index/hotsites-index.component';
import { EvidenceIndexComponent } from './evidence/@pages/evidence-index/evidence-index.component';
import { NewsIndexComponent } from './news/@pages/news-index/news-index.component';
import { SchoolIndexComponent } from './school/@pages/school-index/school-index.component';
import { SurveyIndexComponent } from './survey/@pages/survey-index/survey-index.component';
import { LinkedCourseIndexComponent } from './linkedCourse/@pages/linkedCourse-index/linkedCourse-index.component';
import { EventIndexComponent } from './event/@pages/event-index/event-index.component';
import { CourseIndexComponent } from './course/@pages/course-index/course-index.component';
import {CourseBusinessUnitFormComponent}from './course-business-unit/@pages/course-business-unit-form/course-business-unit-form.component';
import { MediaIndexComponent } from './media/@pages/media-index/media-index.component';
import { SenacIndexComponent } from './senac/@pages/senac-index/senac-index.component';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: IndexComponent,
    canActivate: [AuthenticationGuard]
  },
  { path: 'home', component: IndexComponent},
  { path: 'index', component: IndexComponent},
  {
    path: 'carousel',
    component: CarouselIndexComponent,
    data: { allowedModule: 'CMS_GESTAO_DE_CARROSSEL' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'hotsites',
    component: HotsitesIndexComponent,
    data: { allowedModule: 'CMS_Hotsites' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'evidence', component: EvidenceIndexComponent,
    data: { allowedModule: 'CMS_DEPOIMENTOS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'article',
    component: ArticleIndexComponent,
    data: { allowedModule: 'CMS_ARTIGOS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'partnership', component: PartnershipIndexComponent,
    data: { allowedModule: 'CMS_PARCERIAS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'news', component: NewsIndexComponent,
    data: { allowedModule: 'CMS_NOTICIAS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'schools', component: SchoolIndexComponent,
    data: { allowedModule: 'CMS_ESCOLAS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'survey', component: SurveyIndexComponent,
    data: { allowedModule: 'CMS_ENQUETES' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'linkedCourse', component: LinkedCourseIndexComponent,
    data: { allowedModule: 'CMS_CURSOS_UNIFICADOS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'course', component: CourseIndexComponent,
    data: { allowedModule: 'CMS_CURSOS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'course-business-unit', component: CourseBusinessUnitFormComponent,
    data: { allowedModule: 'CMS_CURSOS_ESCOLAS' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'media',
    component: MediaIndexComponent,
    // data: { allowedModule: 'CMS_Media' },
    // canActivate: [AuthenticationGuard, AuthorizationGuard]
  },
  {
    path: 'meu-senac',
    component: SenacIndexComponent,
    data: { allowedModule: 'CMS_MEUSENAC' },
    canActivate: [AuthenticationGuard, AuthorizationGuard]
  }
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [
    CanDeactivateGuard,
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }

