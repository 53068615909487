<form #articleForm class="col-12">
    <div class="form-group col-12">
        <label for="description">Título:</label>
        <input type="text" [(ngModel)]="article.title" name="title" class="form-control col-12"
            placeholder="Título" id="title" autocomplete="off">
    </div>
    <div class="form-group col-12">
        <label >Autor:</label>
        <input type="text" [(ngModel)]="article.autor" name="autor" class="form-control col-12" placeholder="Autor"
            id="autor">
    </div>
    <div class="form-group col-12">
        <label >Formação:</label>
        <input type="text" [(ngModel)]="article.formation" name="formation" class="form-control col-12"
            placeholder="Formação" id="formation">
    </div>
    
    <div class="form-group col-12">
        <label >Texto:</label>
        <editor id="text" name="text" [(ngModel)]="article.text" [init]="{height: 500,menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'
        }" apiKey="{{tinymceKey}}"></editor>
    </div>

    <div class="form-group col-12">
        <label for="color">Escola:</label>
        <nb-select id="business" placeholder="Selecione..." name="businessUnitId" [(ngModel)]="article.businessUnitId" [(selected)]="article.businessUnitId" class="select-color col-12">
            <nb-option class="wrapper-icon-color" *ngFor="let b of businessUnit" [value]="b.unitCode">
                <span class="descriptive-text">{{b.name}}</span>
            </nb-option>
        </nb-select>
    </div>

    <div class="form-group col-12">
        <label for="color">Area:</label>
        <nb-select id="area" placeholder="Selecione..." [(selected)]="article.areaId" class="select-color col-12">
            <nb-option class="wrapper-icon-color" *ngFor="let b of areas" [value]="b.areaCode">
                <span class="descriptive-text">{{b.areaDescription}}</span>
            </nb-option>
        </nb-select>
    </div>


    <div class="form-group col-12">
        <label for="color">Hot Sites:</label>
        <nb-select multiple id="hotsites" placeholder="Selecione..." [(selected)]="article.hotSitesId" class="select-color col-12">
            <nb-option class="wrapper-icon-color" *ngFor="let b of hotSites" [value]="b.id">
                <span class="descriptive-text">{{b.title}}</span>
            </nb-option>
        </nb-select>
    </div>


    <div class="form-group col-12">
        <label >Ativo:</label>
        <input type="checkbox" [(ngModel)]="article.show" [checked]="article.show" name="isActive"  id="isActive" style="margin-left: 5px;">
    </div>
</form>