import { Component, OnInit } from '@angular/core';
import { evidence } from "../../@model/evidence";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';

import { course } from '../../../shared/@models/course';
import { product } from '../../../shared/@models/product';
import { hotsites } from 'src/app/hotsites/@model/hotsites';
import { businessUnit } from 'src/app/shared/@models/businessUnit';
import { renderFlagCheckIfStmt } from '@angular/compiler/src/render3/view/template';
import { areas } from 'src/app/shared/@models/areas';

@Component({
  selector: 'app-evidence-index',
  templateUrl: './evidence-index.component.html',
  styleUrls: ['./evidence-index.component.scss']
})
export class EvidenceIndexComponent implements OnInit {

  evidence: evidence = new evidence();
  evidences: evidence[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "desc";
  show: boolean = true;
  businessUnit: businessUnit[] = [];
  course: course[] = [];
  product: product[] = [];
  hotsites: hotsites[] = [];
  area: areas[] = [];

  isSearchFinish: boolean = false;
  searchByResult: string = "";
  paginationSearch: boolean = false;
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.doSearch(1);
    this.getBusinessUnit(1, this.order);
    this.getCourse(1, this.order);
    //this.getProduct(1, this.order);
    this.getHotsites(1, this.order);
    this.getArea();
  }

  onCreate() {
    this.removeAction = false;
    this.evidence = new evidence();
    this.evidence.evidenceHotsites = [];
    this.evidence.evidenceDesc = '';
    this.evidence.isActive = true;
    this.evidence.show = true;
    this.modalService.modal('modal-evidence').title = "Criar Depoimento";
    this.modalService.modal('modal-evidence').to = "modal";
    this.modalService.modal('modal-evidence').labelAction = "Salvar";
    this.modalService.modal('modal-evidence').labelClose = "Cancelar";
    this.modalService.modal('modal-evidence').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-evidence');
  }

  onEdit(evidence: evidence): void {
    this.evidence = Object.assign({}, evidence);
    this.removeAction = false;
    this.modalService.modal('modal-evidence').title = "Editar Depoimento";
    this.modalService.modal('modal-evidence').to = "modal";
    this.modalService.modal('modal-evidence').labelAction = "Salvar";
    this.modalService.modal('modal-evidence').labelClose = "Cancelar";
    this.modalService.modal('modal-evidence').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-evidence');
  }
  
  onRemove(evidence: evidence): void {
    this.evidence = evidence;
    this.removeAction = true;
    this.modalService.modal('modal-evidence').title = "Excluir Depoimento";
    this.modalService.modal('modal-evidence').to = "modal";
    this.modalService.modal('modal-evidence').labelAction = "Excluir";
    this.modalService.modal('modal-evidence').labelClose = "Cancelar";
    this.modalService.modal('modal-evidence').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-evidence');
  }

  onDetails(evidence: evidence): void {
    this.removeAction = false;
    this.evidence = Object.assign({}, evidence);
    this.modalService.modal('modal-evidence').title = "Detalhes do Depoimento";
    this.modalService.modal('modal-evidence').to = "modal";
    this.modalService.modal('modal-evidence').labelClose = "Fechar";
    this.modalService.modal('modal-evidence').footerAction = false;
    this.modalService.modal('modal-evidence').noAction = true;
    this.modalService.open('modal-evidence');
  }  

  doSearch(n: number = 1) {


    let cmd = {      
      "name": this.searchByResult,
      "courseDocumentDescription": this.searchByResult,
      "businessUnitName": this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.evidences.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, "evidence/search", cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.pageSize = data.pageSize
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.evidences.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;        
      });
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getEvidence(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getEvidence(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }
  
  getEvidence(n: number = 1, order) {
    this.isinit = false;
    this.apiClientService.getPaginated(environment.urls.api, "evidence/GetAllEvidence", n, this.pageSize, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.evidences = [];
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.evidences.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  getBusinessUnit(n: number = 1, order) {
    this.isinit = false;
    this.apiClientService.getAll(environment.urls.api, "BusinessUnit").then((data: any) => {
      this.businessUnit = data;
    }).finally(() => {
      this.isinit = true;
    });
  }

  getCourse(n: number = 1, order) {
    this.isinit = false;
    this.apiClientService.getPaginated(environment.urls.courseApi, "Course/AllCMS", n, 20000, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.course = [];
        data.data.forEach(d => {
          this.course.push(d);
        });

      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  // getProduct(n: number = 1, order) {
  //   this.isinit = false;
  //   this.apiClientService.getPaginated(environment.urls.courseApi, "Product", n, 20000, order).then((data: ResponseMessage) => {
  //     if (data != null && data.rows > 0) {
  //       this.product = [];
  //       data.data.forEach(d => {
  //         this.product.push(d);
  //       });

  //     }
  //   }).finally(() => {
  //     this.isinit = true;
  //   });
  //  }
  
  getHotsites(n: number = 1, order) {
    this.isinit = false;
    this.apiClientService.getPaginated(environment.urls.api, "Hotsite", n, 20000, order).then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.hotsites = [];
        data.data.forEach(d => {
          this.hotsites.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }  

  saveData(): void {
    if (this.removeAction && this.validateCurrent()) {
      this.apiClientService.delete<evidence>(environment.urls.api, 'evidence', this.evidence.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.evidence !== null && this.validateCurrent()) {
        this.apiClientService.save<evidence>(environment.urls.api, 'evidence', this.evidence, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  getArea(n: number = 1) {
    this.apiClientService.getAll(environment.urls.genericApi, "GetAreas").then((data: any) => {
      if (data != null) {
        this.area = [];
        data.forEach(d => {
          var add = true;
          this.area.forEach(r => {
            if(r.areaCode === d.areaCode)
              add = false;
          });
         if(add)
          this.area.push(d);
        });
      }
    }).finally(() => {});
  }

  callbackSuccess = (resp: any) => {
    let msg = `Depoimento ` + (this.removeAction ? `excluído` : this.evidence.id !== undefined && this.evidence.id !== null ? `alterado` : `criado`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.getEvidence(1, this.order);
    this.modalService.close('modal-evidence');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.evidence.name === null || this.evidence.name === undefined || this.evidence.name === '') {
      this.toastr.error("Campo Nome deve ser preenchido.");
      response = false;
    }

    if (this.evidence.evidenceDesc === null || this.evidence.evidenceDesc === undefined || this.evidence.evidenceDesc === '') {
      this.toastr.error("Campo Depoimento deve ser preenchido.");
      response = false;
    }
    
    if ( this.evidence.businessUnitId === null || this.evidence.businessUnitId === undefined || this.evidence.businessUnitId === '') {
      this.toastr.error("Campo Escola deve ser preenchido.");
      response = false;
    } else {
      this.evidence.businessUnitName = this.businessUnit.find(param => param.unitCode == this.evidence.businessUnitId).name;
    } 
     
    if ( this.evidence.courseId === null || this.evidence.courseId === undefined || this.evidence.courseId === '') {
          this.toastr.error("Campo Curso deve ser preenchido.");
      response = false;
    } else{
      this.evidence.courseDocumentDescription = this.course.find(param => param.integrationCode == this.evidence.courseId).description;
    }

    if ( this.evidence.areaId === null || this.evidence.areaId === undefined)
    {
      this.toastr.error("Campo Area deve ser preenchido.");
      response = false;
    }
    
    if (this.evidence.image === undefined || this.evidence.image === null || this.evidence.image === '') {
      this.toastr.error("Campo Foto deve ser preenchido.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }
}
