import { SurveyHotsites } from './../../@model/survey-hotsites';
import { SurveyBusinessUnit } from './../../@model/survey-business-unit';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { Survey } from '../../@model/survey';


@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit {

  @Input() survey: Survey;

  businessUnits: SurveyBusinessUnit[] = [];
  hotsites: SurveyHotsites[] = [];
  alternative: string = '';

  selectedBU: string[] = []

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService
  ) { }

  ngOnInit() {
    this.getBusinessUnits();
    this.getHotsites();
  }

  ngOnChanges() {
    this.selectedBU = this.survey.businessUnits.map(m => m.id);
  }

  getBusinessUnits(n: number = 1) {
    this.apiClientService.getAll(environment.urls.api, "businessUnit")
      .then((data: any[]) => {
        this.businessUnits = [];
        if (data != null && data.length > 0) {
          data.forEach(d => {
            this.businessUnits.push( new SurveyBusinessUnit(d.unitCode, d.name) );
          });
        }
      }).finally(() => {});
  }

  getHotsites(n: number = 1) {
    this.apiClientService.getPaginated(environment.urls.api, "hotsite", n, 20000)
      .then((data: ResponseMessage) => {
        this.hotsites = [];
        if (data != null && data.rows > 0) {
          data.data.forEach(d => {
            this.hotsites.push( new SurveyHotsites(d.id, d.title) );
          });
        }

      }).finally(() => {});
  }

  include(){
    if(this.alternative != '') {
      this.survey.alternatives.push(this.alternative);
      this.alternative = '';
    }
  }

  exclude(alternative: string) {
    this.survey.alternatives = this.survey.alternatives.filter(a => a !== alternative);
  }

  buSelectedChange(businessUnitIds: string[]) {
    console.log(businessUnitIds);
    this.survey.businessUnits = this.businessUnits
      .filter(f => businessUnitIds.includes(f.id))
      .map(m => new SurveyBusinessUnit(m.id, m.name));
  }

}
