<div>
  <nz-tag *ngFor="let data of dataSource" nzColor="blue" nzMode="closeable" (nzAfterClose)="handleClose(data)">
    {{ sliceTagName(data) }}
  </nz-tag>
  <button *ngIf="!inputVisible" nz-button nzType="primary" (click)="showInput()">
    <i nz-icon nzType="plus" nzTheme="outline"></i>{{buttonText}}
  </button>
  <br />
  <input #tagInputElement *ngIf="inputVisible" type="text" [(ngModel)]="inputValue" class="form-control col-12"
    (blur)="handleInputConfirm()" (keydown.enter)="handleInputConfirm()" />
</div>
