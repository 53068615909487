import { BaseEntity } from "src/app/shared/@models/baseEntity";
import { linkedCourseCourse } from "./linkedCourse-course";

export class linkedCourse extends BaseEntity {
    description: string;
    courseName: string;
    keywords: string[];
    levelEducationCode: string;
    show: boolean;
    insertedUserId: string;
    updatedUser: string;
    updatedUserId: string;
    linkedCourseCourse: linkedCourseCourse[];
    linkedCourseCourses: string[]= [];
    level: string;
    constructor() {
        super();
        this.linkedCourseCourse = [];
    }
}
