export class CardImage {
    title: string;
    text: string;
    thumbnail: string;
    image: string;

    constructor() {
        this.thumbnail = null;
        this.image = null;
        this.title = null;
        this.text = null;
    }
}