import { Component, OnInit, EventEmitter } from '@angular/core';
import { Media } from '../../@model/media';
import { MediaSiteKey } from '../../@model/media-site-key';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-media-index',
  templateUrl: './media-index.component.html',
  styleUrls: ['./media-index.component.scss']
})
export class MediaIndexComponent implements OnInit {
  
  media: Media = new Media();
  medias: Media[] = [];
  mediaSiteKeys: MediaSiteKey[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "asc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  saveMedia: EventEmitter<any> = new EventEmitter();
  resetMedia: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {    
    this.getMedia(1, this.order);
    this.getMediaSiteKey();
  }

  onCreate() {
    this.removeAction = false;
    this.media = new Media();
    this.modalService.modal('modal-media').title = "Adicionar arquivo";
    this.modalService.modal('modal-media').to = "modal";
    this.modalService.modal('modal-media').labelAction = "Salvar";
    this.modalService.modal('modal-media').labelClose = "Cancelar";
    this.modalService.modal('modal-media').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-media');
  }

  onEdit(media: Media): void {
    this.media = Object.assign({}, media);
    this.removeAction = false;
    this.modalService.modal('modal-media').title = "Alterar arquivo";
    this.modalService.modal('modal-media').to = "modal";
    this.modalService.modal('modal-media').labelAction = "Salvar";
    this.modalService.modal('modal-media').labelClose = "Cancelar";
    this.modalService.modal('modal-media').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-media');
  }

  onRemove(media: Media): void {
    this.media = media;
    this.removeAction = true;
    this.modalService.modal('modal-media').title = "Excluir arquivo \"" + this.media.alternativeDescription + "\"";
    this.modalService.modal('modal-media').to = "modal";
    this.modalService.modal('modal-media').labelAction = "Excluir";
    this.modalService.modal('modal-media').labelClose = "Cancelar";
    this.modalService.modal('modal-media').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-media');
  }

  onDetails(media: Media): void {
    this.removeAction = false;
    this.media = Object.assign({}, media);
    this.modalService.modal('modal-media').title = "Detalhes do arquivo";
    this.modalService.modal('modal-media').to = "modal";
    this.modalService.modal('modal-media').labelClose = "Fechar";
    this.modalService.modal('modal-media').footerAction = false;
    this.modalService.modal('modal-media').noAction = true;
    this.modalService.open('modal-media');
  }

  onDownload(media: Media){
    if (media) {
      let url = environment.urls.api + '/Media/object/SiteKey/' + media.mediaSiteKey?.siteKey + '/';

      if (media.businessUnitId) {
        url = url + media.businessUnitId + '/';
      }
      
      window.open(url);
    }
  }

  getMedia(n: number = 1, order: string = this.order) {
    let cmd = {      
      'source': this.searchByResult,
      'alternativeDescription': this.searchByResult,
      'siteKey': this.searchByResult,
      'siteKeyDescription': this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.medias.length = 0;    
    this.apiClientService.postPaginated(environment.urls.api, 'Media/search', cmd, n, this.pageSize, order)
    .then((data: ResponseMessage) => {
      if (data != null && data.rows > 0) {
        this.page = data.page;
        this.rows = data.rows;
        this.pageSize = data.pageSize
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(element => { this.medias.push(element) });
      }
    })
    .finally(() => {
      this.isinit = true;
    });
  }

  getMediaSiteKey() {
    this.apiClientService.getAll(environment.urls.api, 'Media/MediaSiteKey')
    .then((data: any) => {
      if (data && data.length > 0) {
        this.mediaSiteKeys = [];
        data.forEach(element => { this.mediaSiteKeys.push(element) });
      }
    })
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      this.getMedia(this.page, this.order);

      this.direction = true;
    }
    else {
      this.order = "asc";

      this.getMedia(this.page, this.order);

      this.direction = false;
    }
  }

  saveData(): void {    
    if (this.removeAction && this.validateCurrent()) {
      this.apiClientService.delete<Media>(environment.urls.api, 'media', this.media.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.media !== null && this.validateCurrent()) {
        this.saveFile();
      }
    }
  }

  private saveFile() {
    if (this.saveMedia) {
      this.saveMedia.emit(null);
    }
  }

  saveSuccess(data: any): void {
    if (data && data.length > 0) {
      this.callbackSuccess({});
    }
  }

  saveError(error: any): void {
    console.error(error);
    if (error && !error?.error) {
      this.toastr.error("Não foi possivel realizar o upload do arquivo!");
    }
  }

  private resetFile() {
    if (this.resetMedia) {
      this.resetMedia.emit(null);
    }
  }

  callbackSuccess = (resp: any) => {
    this.resetFile();
    let msg = 'Arquivo ' + (this.removeAction ? 'excluído' : this.media.id !== undefined && this.media.id !== null ? 'alterado' : 'adicionado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getMedia(1, this.order);
    this.modalService.close('modal-media');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.media.mediaSiteKeyId === null || this.media.mediaSiteKeyId === undefined || this.media.mediaSiteKeyId === '') {
      this.toastr.error("Campo Chave no Site deve ser preenchido.");
      response = false;
    } 

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }
}
