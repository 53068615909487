import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { environment } from 'src/environments/environment';
import { ApiClientService } from '../../services/api-client.service';
import { Subscription } from 'rxjs';

function getBase64(file: any): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

@Component({
    selector: 'upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {

    @Input() urlAction: string;

    @Input() maxLimitFiles: number = 1;

    @Input() fileTypes: string = "image/png,image/jpeg";

    @Input() isDisableUploadAction: boolean;

    @Output() onUploadSuccess: EventEmitter<any>;

    @Output() onUploadFailed: EventEmitter<any>;

    @Input() mediaId: string;

    @Input() mediaSiteKeyId: string;

    @Input() businessUnitId: string;

    private _saveMedia: EventEmitter<any>;
    @Input() set saveMedia(value: EventEmitter<any>) {
        this._saveMedia = value;
        if (this._saveMedia) {
            this.saveMediaSubscription = this._saveMedia.subscribe(() => {
                this.save();
            });
        }
    }
    get saveMedia(): EventEmitter<any> {
        return this._saveMedia;
    }

    private _resetMedia: EventEmitter<any>;
    @Input() set resetMedia(value: EventEmitter<any>) {
        this._resetMedia = value;
        if (this._resetMedia) {
            this.resetSubscription = this._resetMedia.subscribe(() => {
                this.resetForm();
            });
        }
    }
    get resetMedia(): EventEmitter<any> {
        return this._resetMedia;
    }

    fileList: NzUploadFile[] = [];

    previewImage: string | undefined = '';

    previewVisible = false;

    uploading: boolean = false;

    isVisible: boolean = false;

    private saveMediaSubscription: Subscription;

    private resetSubscription: Subscription;

    constructor(private apiClientService: ApiClientService) {
        this.urlAction = environment.urls.api;
        this.onUploadSuccess = new EventEmitter<any>();
        this.onUploadFailed = new EventEmitter<any>();
        this.isDisableUploadAction = false;

        this.handleUploadSuccess = this.handleUploadSuccess.bind(this);
        this.handleUploadFailed = this.handleUploadFailed.bind(this);
    }

    ngOnInit(): void {
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        var self = this;
        getBase64(file).then(b => {
            self.fileList = self.fileList.concat(Object.assign(file, {thumbUrl: b}));
        });

        return false;
    };

    handleOkButtonText(): string {
        if (this.mediaSiteKeyId) {
            return this.fileList.length <= 0 ? null : `Selecionar ${this.fileList.length} arquivo(s)`;
        } else {
            return this.fileList.length <= 0 ? null : `Inserir ${this.fileList.length} arquivo(s)`;
        }
    }

    handleChange({ file, fileList }: NzUploadChangeParam): void {

    }

    handlePreview = async (file: NzUploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }

        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
    };

    handleUpload(): void {
        if (this.mediaSiteKeyId) {
            this.isVisible = false;
        } else {
            this.save();
        }
    }

    private save() {
        if (this.fileList.length == 0) {
            this.handleUploadFailed({});
        } else {
            const formData = new FormData();

            // tslint:disable-next-line:no-any
            this.fileList.forEach((file: any) => { formData.append('files', file) });
            this.uploading = true;

            let path = '';
            
            if (this.mediaId) {
                path = path + 'media/update/' + this.mediaId + '/';
            } else {
                path = path + 'media/upload/'
            }
            
            if (this.mediaSiteKeyId) {
                path = path + this.mediaSiteKeyId + '/'
            }

            if (this.businessUnitId) {
                path = path + this.businessUnitId + '/'
            }

            if (this.mediaId) {
                this.apiClientService.put(this.urlAction, path, formData, this.handleUploadSuccess, this.handleUploadFailed)
                    .then(() => { })
                    .finally(() => {
                        this.uploading = false;
                    });
            } else {
                this.apiClientService.post(this.urlAction, path, formData, this.handleUploadSuccess, this.handleUploadFailed)
                .then(() => { })
                .finally(() => {
                    this.uploading = false;
                });
            }
        }
    }

    handleUploadSuccess(data: any): void {
        this.onUploadSuccess.emit(data);
        this.handleOk();
    }

    handleUploadFailed(error: any): void {
        this.onUploadFailed.emit(error);
    }

    handleOk(): void {
        this.resetForm();
    }

    handleCancel(): void {
        this.resetForm();
    }

    handleOkDisabled(): boolean {
        return this.uploading || this.fileList.length > this.maxLimitFiles;
    }

    handleUploadDisable(): boolean {
        return this.isDisableUploadAction || false;
    }

    resetForm(): void {
        this.fileList = [];
        this.previewImage = '';
        this.previewVisible = false;
        this.uploading = false;
        this.isVisible = false;
    }

    ngOnDestroy(): void {
        if (this.saveMediaSubscription) {
            this.saveMediaSubscription.unsubscribe();
        }
        if (this.resetSubscription) {
            this.resetSubscription.unsubscribe();
        }
    }
}