import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselIndexComponent } from './@pages/carousel-index/carousel-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';


const routes: Routes = [
  {
    path: 'carousel/index',
    component: CarouselIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class CarouselRoutingModule { }
