<content-header title="Parcerias">
    <div content-text>
        &nbsp;
    </div>
    <div content-action class='group-action'>
        <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
            <nb-icon icon="plus" pack="eva"></nb-icon>Criar
        </button>
    </div>
</content-header>
<nb-card>
    <nb-card-header>
        <nb-form-field>
            <input nbInput type="text" placeholder="Pesquisar ..."
                [(ngModel)]="searchParameterPartnershipDescription" (keydown.enter)="doSearch()">
            <button nbSuffix nbButton ghost (click)="doSearch()">
                <nb-icon icon="search-outline" pack="eva"> </nb-icon>
            </button>
        </nb-form-field>
    </nb-card-header>
    <nb-card-body>
        <!-- Alertas  -->
        <div class="alert alert-warning col-12" role="alert"
        *ngIf="partnerships.length == 0 && isinit && !errorOnLoaging && paginationSearch">
            Nenhuma parceria encontrada.
        </div>
        <div class="alert alert-danger col-12" role="alert"
        *ngIf="partnerships.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
            Nenhuma parceria cadastrada.
        </div>
        <!-- Tabela com a listagem dos Grupos de Acesso -->
        <div class="col-12 mx-auto table-responsive no-padding">
            <div class="table-container">
                <table class="table table-striped table-hover table-fixed" *ngIf="partnerships.length > 0">
                    <thead>
                        <tr>
                            <th (click)="sortResult()" class="name">
                                <b>Parceria&nbsp;</b>
                                <i class="fa fa-chevron-up" *ngIf="!direction"></i>
                                <i class="fa fa-chevron-down" *ngIf="direction"></i>
                            </th>
                            <th><b>% Desconto</b></th>
                            <th><b>Data de Inclusão</b></th>
                            <th><b>Ativo</b></th>
                            <th><b>Ações</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let partnership of partnerships" style="max-width: 244px; overflow: hidden;">
                            <td>{{ partnership.description | slice:0:100 }}</td>
                            <td>{{ partnership.discountPercentage}}%</td>
                            <td>{{ moment(partnership.insertedAt).format('DD/MM/YYYY') }}</td>
                            <td>
                                <!-- <nb-checkbox [disabled]="true" [(ngModel)]="partnership.active"></nb-checkbox> -->
                                <input type="checkbox" disabled [(ngModel)]="partnership.active" [(checked)]="partnership.active" name="active"  id="active" style="margin-left: 5px;">
                            </td>
                            <td class="td-action">
                                <btn-drop-action (onEdit)="onEdit(partnership)" (onRemove)="onRemove(partnership)">
                                </btn-drop-action>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Begin Pagination -->
            <div class="pagination-container" *ngIf="!paginationSearch && partnerships.length > 0">
                <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
                    [totalPage]="totalPage" (goPage)="getPartnerships($event)">
                </pagination>
            </div>
            <div class="pagination-container" *ngIf="paginationSearch && partnerships.length > 0">
                <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
                    [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
            </div>
            <div class="table-info-container" *ngIf="partnerships.length > 0">
                <div class="table-info-label">
                    <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
                </div>
            </div>
            <!-- End Pagination -->
        </div>
    </nb-card-body>
</nb-card>
<!-- Modal -->
<modal id="modal-partnership" (onAction)="saveData()" (onClose)="resetForm()">
    <div body *ngIf="removeAction">
        <p>Confirma a exclusão da Parceria?</p><br>
        <!-- <p>Confirma a exclusão do Grupo : <b>{{selectedGroup.name}}</b> ?</p><br> -->
    </div>
    <div body *ngIf="!removeAction">
        <app-partnership-form *ngIf="show" [partnership]="selectedPartnership" [isEdit]="edit">
        </app-partnership-form>
    </div>
</modal>