// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urls: {
    authApi: 'https://desenvolvimento.senacrs.com.br/Auth/api',
    api: 'http://localhost:5000/api',
    //api: 'https://desenvolvimento.senacrs.com.br/cmsApi/api',
    businessUnitApi: 'https://desenvolvimento.senacrs.com.br/BusinessUnits/api',
    courseApi: 'https://desenvolvimento.senacrs.com.br/Courses/api',
    //courseApi: 'http://localhost:5004/api',
    //hotsitesApi: 'https://desenvolvimento.senacrs.com.br/CmsApi/api',
    genericApi: 'https://desenvolvimento.senacrs.com.br/Genericapi/console/Ecommerce/GvCollege',
    loginUnificado:'https://desenvolvimento.senacrs.com.br/iam/login',
    minio: 'http://minio-desenvolvimento.senacrs.com.br:9000/cms'
  },
  tinymceKey : "71mfv1qe2dsqmtbfy98cnrkgzlv5lruz2ibvi9y1zz4moq4r"
};
