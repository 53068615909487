import { BaseEntity } from "src/app/shared/@models/baseEntity";

export class hotsites extends BaseEntity {
    code: string;
    title: string;
    description: string;
    link: string;
    integrationCode: string;
    removedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    updatedUser: string;
    updatedUserId: string;
    businessUnitId: string;
    businessUnitName: string;
    show: boolean;
    used: boolean;

    constructor(
        id?: string,
        code?: string,
        title?: string,
        description?: string,
        link?: string,
        show?: boolean,
        used?: boolean
    ) {
        super();
        this.id = id;
        this.code = code;
        this.title = title;
        this.description = description;
        this.link = link;
        this.show = show;
        this.used = used;
    }
}
