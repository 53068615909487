import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-event-btn-drop-action',
  templateUrl: './event-btn-drop-action.component.html',
  styleUrls: ['./event-btn-drop-action.component.scss']
})
export class EventBtnDropActionComponent  implements OnInit {

  @Output() onEdit: EventEmitter<any>;
  @Output() onRemove: EventEmitter<any>;
  @Output() onRegister: EventEmitter<any>;

  constructor() {
    this.onEdit = new EventEmitter<any>();
    this.onRemove = new EventEmitter<any>();
    this.onRegister = new EventEmitter<any>();
   }

  ngOnInit() {
  }

  edit = (event:any) => {
    this.onEdit.emit();
  }

  remove = (event:any) => {
    this.onRemove.emit();
  }

  register = (event:any) => {
    this.onRegister.emit();
  }
}
