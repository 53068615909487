import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NewsRoutingModule } from './news-routing.module';
import { NbCardModule, NbButtonModule, NbActionsModule, NbSelectModule, NbFormFieldModule, NbDatepickerModule } from '@nebular/theme';
import { NewsIndexComponent } from './@pages/news-index/news-index.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NewsFormComponent } from './@pages/news-form/news-form.component';
import { NbIconModule, NbInputModule } from '@nebular/theme';
import { EditorModule } from '@tinymce/tinymce-angular';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [NewsIndexComponent, NewsFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    NewsRoutingModule,
    SharedModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    EditorModule,
    NbDatepickerModule
  ]
})

export class NewsModule { }
