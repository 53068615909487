import { EventType } from './../@model/event-type';
import { Injectable } from '@angular/core';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

@Injectable({
  providedIn: 'root',
})
export class EventTypeService {
  constructor(private apiClientService: ApiClientService) {}

  public getAll() {

    return this.apiClientService.getAll(environment.urls.api, 'eventtype');
  }
}
