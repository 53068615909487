<content-header title="Enquetes">
  <div content-text>
      Nesta tela você poderá cadastrar e administrar suas Enquetes.
  </div>
  <div content-action>
      <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
          <i class="fa fa-plus margin-right-8"></i> Criar
      </button>
  </div>
</content-header>
<nb-card>
  <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..."
          [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
        <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
        </button>
    </nb-form-field>
  </nb-card-header>
  <nb-card-body>
      <div class="alert alert-danger col-12" *ngIf="surveys.length === 0">
          Nenhuma Enquete cadastrada.
      </div>
      <div class="col-12 mx-auto table-responsive-sm no-padding">
          <div class="table-container">
              <table class="table table-striped table-hover table-fixed">
                  <thead>
                      <tr>
                        <th class="title">Título</th>
                        <!-- <th class="title">Escola</th> -->
                        <th class="title">Usuário</th>
                        <th class="date">Data Inclusão</th>
                        <th class="check">Ativo</th>
                        <th class="options">Ações</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let s of surveys">
                          <td class="title-grid">{{s.title}}</td>
                          <!-- <td>{{s.businessUnitName}}</td> -->
                          <td>{{s.insertedUser}}</td>
                          <td>{{s.insertedAt | date: 'dd/MM/yyyy'}}</td>
                          <td>
                              <input type="checkbox" [checked]="s.active" disabled>
                          </td>
                          <td class="td-action">
                            <btn-drop-action (onEdit)="onEdit(s)" (onRemove)="onRemove(s)">...</btn-drop-action>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </nb-card-body>
  <!-- Begin Pagination -->
  <div class="pagination-container" *ngIf="surveys.length > 0">
    <pagination
      [totalRows]="totalRows"
      [pageSize]="pageSize"
      [rows]="rows"
      [page]="page"
      [totalPage]="totalPage"
      (goPage)="doSearch($event)">
    </pagination>
  </div>
  <div class="table-info-container" *ngIf="surveys.length > 0">
    <div class="table-info-label">
      <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
    </div>
  </div>
  <!-- End Pagination -->
</nb-card>

<!-- Begin Modal-->
<modal id="modal-news" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão desta Enquete?</div>
  <div body *ngIf="!removeAction">
      <app-survey-form [survey]="survey"></app-survey-form>
  </div>
</modal>
<!-- End Modal-->
