import { Component, OnInit } from '@angular/core';
import { news } from '../../@model/news';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-index',
  templateUrl: './news-index.component.html',
  styleUrls: ['./news-index.component.scss']
})
export class NewsIndexComponent implements OnInit {
  
  news: news = new news();
  newss: news[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "desc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getNews(1, this.order);
  }

  onCreate() {
    this.removeAction = false;
    this.news = new news();
    this.news.isActive = true;
    this.modalService.modal('modal-news').title = "Criar Notícias";
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Salvar";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-news');
  }

  onEdit(news: news): void {
    this.news = Object.assign({}, news);
    this.removeAction = false;
    this.modalService.modal('modal-news').title = "Editar Notícias";
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Salvar";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-news');
  }

  onRemove(news: news): void {
    this.news = news;
    this.removeAction = true;
    this.modalService.modal('modal-news').title = "Excluir Notícias " + this.news.title;
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Excluir";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-news');
  }

  onDetails(news: news): void {
    this.removeAction = false;
    this.news = Object.assign({}, news);
    this.modalService.modal('modal-news').title = "Detalhes da Notícia";
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelClose = "Fechar";
    this.modalService.modal('modal-news').footerAction = false;
    this.modalService.modal('modal-news').noAction = true;
    this.modalService.open('modal-news');
  }

  getNews(n: number = 1, order) {
    this.isinit = false;
    this.paginationSearch = false;
    this.newss = [];
    this.apiClientService.getPaginated(environment.urls.api, "news/GetAllNews", n, this.pageSize, order).then((data: ResponseMessage) => {
      
      if (data != null && data.rows > 0) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.rows = data.rows;
        this.totalRows = data.totalRows;
        this.totalPage = data.totalPage;
        data.data.forEach(d => {
          this.newss.push(d);
        });
      }
    }).finally(() => {
      this.isinit = true;
    });
  }

  doSearch(n: number = 1) {

    if (!this.searchByResult) {
      this.getNews(1, this.order);
      return
    }

    let cmd = {    
      "title": this.searchByResult
    }

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.newss.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, 'news/search', cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.pageSize = data.pageSize
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.newss.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getNews(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getNews(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  saveData(): void {    
    if (this.removeAction) {
      this.apiClientService.delete<news>(environment.urls.api, 'news', this.news.id, this.callbackDeleteSuccess, this.callbackErr);
    } else {
      if (this.news !== null && this.validateCurrent()) {
        this.apiClientService.save<news>(environment.urls.api, 'news', this.news, this.callbackSaveSuccess, this.callbackErr);
      }
    }
  }

  callbackSaveSuccess = (resp: any) => {
    let msg = 'Noticia ' + (this.removeAction ? 'excluída' : this.news.id !== undefined && this.news.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getNews(1, this.order);
    this.modalService.close('modal-news');
  }

  callbackDeleteSuccess = (resp: any) => {
    let msg = 'Noticia ' + (this.removeAction ? 'excluída' : this.news.id !== undefined && this.news.id !== null ? 'alterado' : 'criado') + ' com sucesso!';
    this.toastr.success(msg);
    this.getNews(1, this.order);
    this.modalService.close('modal-news');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.news.title === null || this.news.title === undefined || this.news.title === '') {
      this.toastr.error("Campo Título deve ser preenchido.");
      response = false;
    } 
    if (this.news.resume === null || this.news.resume === undefined || this.news.resume === '') {
      this.toastr.error("Campo Resumo deve ser preenchido.");
      response = false;
    }

    if (this.news.text === null || this.news.text === undefined || this.news.text === '') {
      this.toastr.error("Campo Texto deve ser preenchido.");
      response = false;
    }

    if (this.news.photo === null || this.news.photo === undefined || this.news.photo === '') {
      this.toastr.error("Campo Foto deve ser preenchido.");
      response = false;
    }

    if (this.news.photoLegend === null || this.news.photoLegend === undefined || this.news.photoLegend === '') {
      this.toastr.error("Campo Legenda da Foto deve ser preenchido.");
      response = false;
    }

    if (this.news.date === null || this.news.date === undefined) {
      this.toastr.error("Campo Data da Notícia deve ser preenchido.");
      response = false;
    }

    return response;
  }

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

}
