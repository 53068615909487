<div class="btn-group dropdown-card" dropdown (click)="handlerShow($event)" (onShown)='show($event)' (onHidden)='hidden($event)'>
    <button 
        id="button-droppdown-card" dropdownToggle 
        type="button" 
        class="btn btn-outline-primary dropdown-toggle circle button-droppdown-card"
        aria-controls="dropdown-animated">
            <i [className]="iconNoImage" *ngIf="card.thumbnail === null && iconNoImage !== null"></i>
            <img class="img-thumbnail circle" [src]="card.thumbnail" *ngIf="card.thumbnail !== null" />
    </button>
    <div id="dropdown-droppdown-card" 
        *dropdownMenu
        class="dropdown-menu arrow-up animated"
        role="menu"
        aria-labelledby="button-animated" 
        (click)="$event.stopPropagation()">
            <div class="card" style="width: 18rem;">
                <div class="card-img-top img-fluid img-thumbnail circle rounded mx-auto d-block" *ngIf="card.image === null">
                    <i [className]="iconNoImage"></i>
                </div>
                <img class="card-img-top img-fluid img-thumbnail circle rounded mx-auto d-block" [src]="card.image" *ngIf="card.image !== null">
                <div class="card-body">
                    <h5 class="card-title">{{card.title}}</h5>
                    <p class="card-text">{{card.text}}</p>
                </div>
                <ul class="list-group list-group-flush" *ngIf="listLinks.length > 0">
                    <li class="list-group-item" *ngFor="let link of listLinks">
                        <a class="card-link" *ngIf="link.url === null" href="javascript:void(0);" (click)="link.action($event)">
                            <i [className]='link.icon' *ngIf="link.icon !== null"></i>
                            {{link.title}}
                        </a>
                        <a class="card-link" *ngIf="link.url !== null" [href]="link.url">
                            <i [className]='link.icon' *ngIf="link.icon !== null"></i>
                            {{link.title}}
                        </a>
                    </li>
                </ul>
                <div class="card-body links" *ngIf="links.length > 0">
                    <span class="card-link" *ngFor="let link of links">
                        <a class="card-link" *ngIf="link.url === null" href="javascript:void(0);" (click)="link.action($event)">
                            <i [className]='link.icon' *ngIf="link.icon !== null"></i>
                            {{link.title}}
                        </a>
                        <a class="card-link" *ngIf="link.url !== null" [href]="link.url">
                            <i [className]='link.icon' *ngIf="link.icon !== null"></i>
                            {{link.title}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
</div>