import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NbCardModule, NbFormFieldModule, NbIconModule, NbButtonModule, NbActionsModule, NbSelectModule, NbInputModule, NbDatepickerModule, NbListModule } from '@nebular/theme';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';

import { SurveyRoutingModule } from './survey-routing.module';

import { SurveyFormComponent } from './@pages/survey-form/survey-form.component';
import { SurveyIndexComponent } from './@pages/survey-index/survey-index.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [
    SurveyFormComponent,
    SurveyIndexComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbFormFieldModule,
    NbIconModule,
    NbInputModule,
    NbListModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
     SharedModule,
    SurveyRoutingModule,
  ]
})
export class SurveyModule { }
