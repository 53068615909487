import { Subscriber } from './subscriber';
import { BaseEntity } from './../../shared/@models/baseEntity';

export class Event extends BaseEntity {
  eventTypeId: string;
  title: string;
  localization: string;
  dateInitial: Date;
  dateFinal: Date;
  openingHours: Date;
  closingHours: Date;
  whoPromotes: string;
  price: number;
  phone: string;
  quantityForRegistration: number;
  openRegistration: boolean;
  active: boolean;
  description: string = '';
  eventLink?: string;

  eventBusinessUnits: string[] = [];
  eventHotsites: string[] = [];
  subscribers: Subscriber[];

  constructor() {
    super();
  }

}
