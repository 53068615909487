export class MeuSenacPerson{
    id: string;
    cpf: string;
    name: string;
    gender: string;
    ddd: string;
    phone: string;
    email: string;
    birthDate: Date;
    city: number;
    insertedAt: Date;
    insertedUser: string;
    insertedUserId: string;
    commited: boolean;
    isActive: boolean;
    isRemoved: boolean;
    updatedAt?: Date;
    updatedUser: string;
    updatedUserId: string;
}