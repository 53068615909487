<form #newsForm class="col-12">
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="form-group col-12">
          <label>Tipo de Evento:</label>
          <nb-select fullWidth placeholder="Selecione..." [(ngModel)]="event.eventTypeId" name="eventType"
            [selected]="event.eventTypeId">
            <nb-option *ngFor="let et of eventTypes"
              [value]="et.id"
              [disabled]="disableSpecificEventType(et)">{{et.description}}</nb-option>
          </nb-select>
        </div>
        <div class="form-group col-12">
          <label>Título do Evento:</label>
          <input fullWidth type="text" [(ngModel)]="event.title" name="title" class="form-control col-12"
            placeholder="Título" id="title" maxlength="200" required>
        </div>
        <div class="form-group col-12">
          <label>Local do Evento:</label>
          <input fullWidth type="text" [(ngModel)]="event.localization" name="localization" class="form-control col-12"
            placeholder="Local" id="localization" maxlength="200" required>
        </div>
        <div class="form-row col-12">
          <div class="form-group col-sm-6">
            <label for="startDate">Data Início:</label>
            <input nbInput placeholder="Data Início" [nbDatepicker]="dtpStartDate" [(ngModel)]="event.dateInitial"
              value="{{ event.dateInitial | date:'dd/MM/yyyy' }}" name="dateInitial" id="dateInitial"
              class="form-control">
            <nb-datepicker #dtpStartDate format="DD/MM/yyyy"></nb-datepicker>
          </div>
          <div class="form-group col-sm-6">
            <label for="endDate">Data Final:</label>
            <input nbInput placeholder="Data Final" [nbDatepicker]="dtpEndDate" [(ngModel)]="event.dateFinal"
              value="{{ event.dateFinal | date:'dd/MM/yyyy' }}" name="dateFinal" id="dateFinal" class="form-control">
            <nb-datepicker #dtpEndDate format="DD/MM/yyyy"></nb-datepicker>
          </div>
        </div>
        <div class="form-row col-12">
          <div class="form-group col-sm-6">
            <label for="openingHours">Horário Início:</label>
            <input nbInput [nbTimepicker]="dtpOpeningHours" [(ngModel)]="event.openingHours"
              value="{{ event.openingHours | date:'HH:mm' }}" name="openingHours" id="openingHours"
              class="form-control">
            <nb-timepicker #dtpOpeningHours format="HH:mm"></nb-timepicker>
          </div>
          <div class="form-group col-sm-6">
            <label for="closingHours">Horário Final:</label>
            <input nbInput [nbTimepicker]="dtpClosingHours" [(ngModel)]="event.closingHours"
              value="{{ event.closingHours | date:'HH:mm' }}" name="closingHours" id="closingHours"
              class="form-control">
            <nb-timepicker #dtpClosingHours format="HH:mm"></nb-timepicker>
          </div>
        </div>
        <div class="form-group col-12">
          <label>Quem Promove:</label>
          <input fullWidth type="text" [(ngModel)]="event.whoPromotes" name="whoPromotes" class="form-control col-12"
            placeholder="Promotor" id="whoPromotes" maxlength="100" required>
        </div>
        <div class="form-group col-12">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" [checked]="event.eventLink" (change)="toggleVisibleField()">
            <label class="form-check-label">Evento Online</label>
          </div>
          <div *ngIf="visibleField || event.eventLink != null" class="mt-3">
            <label>Link Evento:</label>
            <input fullWidth type="text" [(ngModel)]="event.eventLink" name="eventLink" class="form-control col-12"
            placeholder="Inserir o Link" id="eventLink">
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group col-6">
          <label style="min-width: 200px;">Escolas: </label> <br/>
          <a style="cursor: pointer;
            font-size: 0.8em;
            padding: 5px 10px;
            background-color: #0173c0;
            color: #FFF;
            border-radius: 4px;margin-bottom: 10px;" (click)="allBU()">Selecionar todas</a>
          <nb-select multiple placeholder="Escolas" (selectedChange)="buSelectedChange($event)" name="eventBusinessUnit"
            [selected]="event.eventBusinessUnits">
            <nb-option *ngFor="let b of businessUnits" [value]="b.unitCode">{{b.name}}</nb-option>
          </nb-select>
        </div>
        <div class="form-group col-6">
          <label>Publicar em Hotsites:</label>
          <nb-select multiple placeholder="Hotsites" [(ngModel)]="event.eventHotsites" name="eventHotsites"
            [selected]="event.eventHotsites">
            <nb-option *ngFor="let h of hotsites" [value]="h.id">{{h.title}}</nb-option>
          </nb-select>
        </div>
        <div class="form-group col-12">
          <label>Valor:</label>
          <input fullWidth type="text" [(ngModel)]="event.price" id="price" name="price" class="form-control col-12"
            currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" required>
        </div>
        <div class="form-group col-12">
          <label>Telefone:</label>
          <input fullWidth type="text" [(ngModel)]="event.phone" name="phone" class="form-control col-12"
            mask="(00) 0000-0000||(00) 0 0000-0000" [showMaskTyped]="true" [validation]="true" id="phone"
            maxlength="16" required>
        </div>
        <div class="form-group col-12">
          <label for="openSubscription">Inscrições Abertas:</label>
          <input type="checkbox" [(ngModel)]="event.openRegistration" [(checked)]="event.openRegistration"
            name="openRegistration" id="openRegistration" style="margin-left: 5px;">
        </div>
        <div class="form-group col-12">
          <label for="vacancies">Vagas:</label>
          <input type="text" [(ngModel)]="event.quantityForRegistration" name="vacancies" class="form-control col-12"
            id="vacancies" mask="9999" maxlength="4" [required]="event.openRegistration" [disabled]="!event.openRegistration">
        </div>
        <div class="form-group col-12">
          <label for="active">Ativo:</label>
          <input type="checkbox" [(ngModel)]="event.active" [(checked)]="event.active" name="active" id="active"
            style="margin-left: 5px;">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <div class="form-group col-12">
          <label>Texto:</label>
          <editor [(ngModel)]="event.description" [ngModelOptions]="{standalone: true}" [init]="{height: 500,menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
                'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \ bullist numlist outdent indent | removeformat | help'
        }" apiKey="{{tinymceKey}}"></editor>
        </div>
      </div>
    </div>
  </div>
</form>
