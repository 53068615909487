<content-header title="Inscrições do Evento: {{ event?.title }}">
  <div content-text>
    Nesta tela você poderá cadastrar e administrar as Inscrições do Evento.
  </div>
  <div content-action>
    <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
      <i class="fa fa-plus margin-right-8"></i> Criar
    </button>
    &nbsp;
    <button nbButton shape="round" outline size="small" status="primary" (click)="export()">
      <i class="fa fa-file-excel margin-right-8"></i> Exportar Excel
    </button>
    &nbsp;
    <button nbButton shape="round" outline size="small" status="secondary" routerLink="/event">
      <i class="fa fa-undo margin-right-8"></i> Voltar
    </button>
  </div>
</content-header>
<nb-card>
  <!-- <nb-card-header>
    <nb-form-field>
      <input nbInput type="text" placeholder="Pesquisar..." #search>
      <button nbSuffix nbButton ghost>
        <nb-icon icon="search-outline" pack="eva"> </nb-icon>
      </button>
    </nb-form-field>
  </nb-card-header> -->
  <nb-card-body>
    <div class="alert alert-danger col-12" *ngIf="event?.subscribers?.length === 0">
      Nenhuma Inscrição cadastrada.
    </div>
    <div class="col-12 mx-auto table-responsive-sm no-padding">
      <div class="table-container">
        <table class="table table-striped table-hover table-fixed">
          <thead>
            <tr>
              <th class="title">CPF</th>
              <th class="title">Nome</th>
              <th class="title">Telefone</th>
              <th class="title">Celular</th>
              <th class="title">E-mail</th>
              <th class="title">Data Cadastro</th>
              <th class="title">Classificar</th>
              <th class="options">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of subscribers">
              <td class="title-grid text-nowrap" >{{s.cpf | mask: 'CPF_CNPJ'}}</td>
              <td>{{s.name}}</td>
              <td>{{s.phone | mask: '(00) 90000-0000'}}</td>
              <td>{{s.cellphone | mask: '(00) 90000-0000'}}</td>
              <td>{{s.email}}</td>
              <td>{{s.insertedAt | date: 'dd/MM/yyyy'}}</td>
              <td>
                <nb-select [(ngModel)]="s.subscriberTypeId" [selected]="s.subscriberTypeId" (selectedChange)="onChangeSubscriberStatus(s)">
                  <nb-option *ngFor="let rs of subscriberStatus" [value]="rs.id">{{rs.description}}</nb-option>
                </nb-select>
              </td>
              <td class="td-action">
                <btn-drop-action (onEdit)="onEdit(s)" (onRemove)="onRemove(s)">...</btn-drop-action>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<!-- Begin Modal-->
<modal id="modal-subscriber" (onAction)="saveData()" (onClose)="resetForm()">
  <div body *ngIf="removeAction">Confirma a exclusão desta Inscrição?</div>
  <div body *ngIf="!removeAction">
    <app-subscriber-form [subscriber]="subscriber" [subscribers]="event?.subscribers" #subscriberForm></app-subscriber-form>
  </div>
</modal>
<!-- End Modal-->
