import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  @Input() dataSource = [];

  @Input() buttonText = "";

  @Output() onChangeTag = new EventEmitter<any>();

  @ViewChild('tagInputElement', { static: false }) inputElement?: ElementRef;

  inputVisible = false;
  inputValue = '';

  constructor() { }

  ngOnInit(): void {
  }

  handleClose(removedTag: {}): void {
    this.dataSource = this.dataSource.filter(tag => tag != removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.inputValue != '' && this.dataSource.indexOf(this.inputValue) === -1) {
      this.dataSource = [...this.dataSource, this.inputValue];
      this.onChangeTag.emit(this.dataSource);
    }
    this.inputValue = '';
    this.inputVisible = false;
  }
}
