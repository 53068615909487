import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'btn-drop-action',
  templateUrl: './btn-drop-action.component.html',
  styleUrls: ['./btn-drop-action.component.scss']
})
export class BtnDropActionComponent implements OnInit {

  @Output() onEdit: EventEmitter<any>;
  @Output() onRemove: EventEmitter<any>;
  @Output() onSend: EventEmitter<any>;
  @Output() onDownload: EventEmitter<any>;

  @Input() canSend: boolean;
  @Input() canEdit: boolean = true;
  @Input() canRemove: boolean = true;
  @Input() canDownload: boolean;

  constructor() {
    this.onEdit = new EventEmitter<any>();
    this.onRemove = new EventEmitter<any>();
    this.onSend = new EventEmitter<any>();
    this.onDownload = new EventEmitter<any>();
   }

  ngOnInit() {
  }

  edit = (event:any) => {
    this.onEdit.emit();
  }

  remove = (event:any) => {
    this.onRemove.emit();
  }

  send = (event:any) => {
    this.onSend.emit();
  }

  download = (event:any) => {
    this.onDownload.emit();
  }
}
