<form #subscriberForm class="col-12">
  <div class="container">
    <div class="form-group col-12">
      <label>CPF:</label>
      <input fullWidth type="text" [(ngModel)]="subscriber.cpf" name="title" class="form-control col-12"
        placeholder="CPF" id="cpf" maxlength="14" required mask="000.000.000-00" [validation]="true" (blur)="validateAlreadyExistent()" #inputCpf>
    </div>
    <div class="form-group col-12">
      <label>Nome:</label>
      <input fullWidth type="text" [(ngModel)]="subscriber.name" name="name" class="form-control col-12"
        placeholder="Nome" id="name" maxlength="200" required>
    </div>
    <div class="form-group col-12">
      <label>E-mail:</label>
      <input fullWidth type="email" [(ngModel)]="subscriber.email" name="email" class="form-control col-12"
        placeholder="email" id="email" maxlength="200">
    </div>
    <div class="form-group col-12">
      <label>Telefone:</label>
      <input fullWidth type="text" [(ngModel)]="subscriber.phone" name="phone" class="form-control col-12"
            mask="(00) 0000-0000||(00) 0 0000-0000" [showMaskTyped]="true" [validation]="true" id="phone"
            maxlength="16">
    </div>
    <div class="form-group col-12">
      <label>Celular:</label>
      <input fullWidth type="text" [(ngModel)]="subscriber.cellphone" name="cellphone" class="form-control col-12"
            mask="(00) 0000-0000||(00) 0 0000-0000" [showMaskTyped]="true" [validation]="true" id="cellphone"
            maxlength="16">
    </div>
  </div>
</form>
