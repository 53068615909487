<content-header title="MeuSenac">
    <div content-text>
        Nesta tela você poderá administrar o Meu Senac.      
    </div>  
  </content-header>
  <nb-card>
    <nb-card-header>
      <nb-form-field>
        <input nbInput type="text" placeholder="Pesquisar..."
            [(ngModel)]="searchByResult" (keydown.enter)="doSearch()">
        <button nbSuffix nbButton ghost (click)="doSearch()">
          <nb-icon icon="search-outline" pack="eva"> </nb-icon>
        </button>
      </nb-form-field>
    </nb-card-header>
    <nb-card-body>
        <div class="alert alert-warning col-12" role="alert"
        *ngIf="MeuSenacPersons.length == 0 && isinit && !errorOnLoaging && paginationSearch">
          Nenhum hotsite encontrado.
        </div>
        <div class="alert alert-danger col-12" role="alert"
        *ngIf="MeuSenacPersons.length == 0 && isinit && !errorOnLoaging && !paginationSearch">
          Nenhum hotsite cadastrado.
        </div>
        <div class="col-12 mx-auto table-responsive-sm no-padding">
            <div class="table-container">
                <table class="table table-striped table-hover table-fixed" *ngIf="MeuSenacPersons.length > 0">
                    <thead>
                        <tr>
                          <th class="code">CPF</th>
                          <th class="title">Nome</th>                       
                          <th class="description">Fone</th>
                          <th class="check">Email</th>
                          <th class="options">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let h of MeuSenacPersons;let i = index">
                            <td class="title-grid">
                                {{h.cpf}}
                            </td>
                            <td>
                                {{h.name}}
                            </td>
                            <td>
                                {{h.phone}}
                            </td>
                            <td>
                                <input type="text" name="ëmail{{i}}" id="email{{i}}" class="form-control" [(ngModel)]="h.email">
                            </td>
                            <td class="td-action">
                              <btn-drop-action (onSend)="onEdit(h)" [canRemove]="false" [canEdit]="false" [canSend]="true">...</btn-drop-action>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </nb-card-body>
    <!-- Begin Pagination -->  
    <div class="pagination-container" *ngIf="!paginationSearch && MeuSenacPersons.length > 0">
      <pagination [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page" [totalPage]="totalPage"
        (goPage)="getHotsites($event)">
      </pagination>
    </div>
    <div class="pagination-container" *ngIf="paginationSearch && MeuSenacPersons.length > 0">
      <pagination class="pagination" [totalRows]="totalRows" [pageSize]="pageSize" [rows]="rows" [page]="page"
        [totalPage]="totalPage" (goPage)="doSearch($event)"></pagination>
    </div>
    <div class="table-info-container" *ngIf="MeuSenacPersons.length > 0">
      <div class="table-info-label">
        <div>Página {{page}} de {{totalPage}}. Registros: {{rows}} de {{totalRows}}</div>
      </div>
    </div>
    <!-- End Pagination -->
  </nb-card>
  
  <!-- Begin Modal-->
  <!-- <modal id="modal-senac" >
    <div body *ngIf="removeAction">Confirma a exclusão deste hotsite?</div>
    <div body *ngIf="!removeAction">
        <app-senac-form ></app-senac-form>
    </div>
  </modal> -->
  <!-- End Modal-->