<form #hotsitesForm class="col-12">  
  <div class="form-group col-12">
      <label >Título:</label>
      <input type="text" [(ngModel)]="hotsites.title" name="title" class="form-control col-12" placeholder="Título"
          id="title">
  </div>
  <div class="form-group col-12">
      <label for="description">Descrição:</label>
      <input type="text" [(ngModel)]="hotsites.description" name="description" class="form-control col-12"
          placeholder="Descrição" id="description" autocomplete="off">
  </div>
  <div class="form-group col-12">
      <label >Link:</label>
      <input type="text" [(ngModel)]="hotsites.link" name="Link" class="form-control col-12" placeholder="Link"
          id="Link">
  </div>  
  <div class="form-group col-12">
      <label >Ativo:</label>
      <input type="checkbox" [(ngModel)]="hotsites.show" [(checked)]="hotsites.isActive" name="isActive"  id="isActive" style="margin-left: 5px;">
  </div>
</form>