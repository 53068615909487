<content-header title="Cursos por Escola">
  <div content-text>
    Nesta tela você poderá cadastrar e administrar seus cursos por escolas.
  </div>
  <div content-action>
    <button nbButton shape="round" outline size="small" status="primary" (click)="clearForm()">
        <i class="fa fa-trash margin-right-8"></i> Limpar
    </button>
</div>
</content-header>
<nb-card>
  <nb-card-body>
    <form #linkedCourseForm class="col-12">
      <div class="form-group col-12">
        <label>Escola:</label>
        <nb-select fullWidth placeholder="Seleciona a Escola..." [(ngModel)]="courseBusinessUnit.businessUnitId"
          name="businessUnitId" [selected]="courseBusinessUnit.businessUnitId"
          (selectedChange)="onSelectedChangeBusinessUnit($event)">
          <nb-option *ngFor="let h of businessUnits" [value]="h.unitCode">{{h.name}}</nb-option>
        </nb-select>
      </div>
      <div class="form-group col-12">
        <label>Tipo:</label>
        <nb-select fullWidth id="levelCourse" placeholder="Seleciona o Tipo..."
          [disabled]="!this.courseBusinessUnit.businessUnitId" [(ngModel)]="levelEducationCode"
          name="levelEducationCode" (selectedChange)="changeLevelLearning()">
          <nb-option value="">Selecione</nb-option>
          <nb-option *ngFor="let b of levels" [value]="b.code">{{b.name}}</nb-option>
        </nb-select>
      </div>
      <div class="form-group col-12">
        <label for="color">Cursos:</label>
        <nz-transfer fullWidth [nzDisabled]="!this.courseBusinessUnit.businessUnitId" [nzDataSource]="courseDataSource"
          nzShowSearch [nzFilterOption]="filterOption" (nzChange)="change($event)" (nzSelectChange)="selectChange($event)"
          [nzListStyle]="{ 'width.px': 650, 'height.px': 480 }">
        </nz-transfer>
      </div>
      <div class="form-group col-12" style="margin-bottom: 0px;">
        <label>Curso Selecionado:</label>
      </div>
      <div class="form-group col-12" style="margin-bottom: 0px;">
        <label *ngIf="selectItem">&nbsp;&nbsp;Descrição:&nbsp;&nbsp; <span style="font-weight: bold;">{{ selectItem?.item.descriptionItem }}</span></label>
      </div>
      <div class="form-group col-12" style="margin-bottom: 0px;">
        <label *ngIf="selectItem">&nbsp;&nbsp;Nome do Curso no Site:&nbsp;&nbsp; <span style="font-weight: bold;">{{ selectItem?.item.frameworkDescription }}</span></label>
      </div>
      <div class="form-group col-12" style="margin-bottom: 0px;">
        <label *ngIf="selectItem">&nbsp;&nbsp;Carga Horária Total:&nbsp;&nbsp; <span style="font-weight: bold;" *ngIf="selectItem?.item.totalWorkload">{{ selectItem?.item.totalWorkload }}h</span></label>
      </div>
      <div class="form-group col-12">
        <label *ngIf="selectItem">&nbsp;&nbsp;Nome Documento:&nbsp;&nbsp; <span style="font-weight: bold;">{{ selectItem?.item.documentName }}</span></label>
      </div>
    </form>
  </nb-card-body>
</nb-card>
