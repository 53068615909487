export class BaseEntity {
    id: string;
    integrationCode: string;
    isActive: boolean;
    isRemoved: boolean;
    insertedAt: Date;
    updatedAt: Date;
    removedAt: Date;
    commited: boolean;
}
