import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NbActionsModule, NbButtonModule, NbCardModule, NbFormFieldModule, NbIconModule, NbInputModule, NbListModule, NbSelectModule, NbTabsetModule, NbUserModule, NbTimepickerModule, NbRadioModule } from '@nebular/theme';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CourseIndexComponent } from './@pages/course-index/course-index.component';
import { CourseFormComponent } from './@pages/course-form/course-form.component';
import { CourseRoutingModule } from './course-routing.module';
import { EditorModule } from '@tinymce/tinymce-angular';


let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [CourseFormComponent, CourseIndexComponent],
  imports: [
    CommonModule,
    FormsModule,
    CourseRoutingModule,
    SharedModule,
    /* NEBULAR - Modules */
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    NbIconModule,
    NbFormFieldModule,
    NbTabsetModule,
    NbUserModule,
    NbListModule,
    NbInputModule,
    NbTimepickerModule,
    NbRadioModule,
    EditorModule
  ]
})

export class CourseModule { }
