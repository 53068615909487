import { NgModule } from '@angular/core';
import { MediaIndexComponent } from './@pages/media-index/media-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';


const routes: Routes = [
  {
    path: 'media/index',
    component: MediaIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class MediaRoutingModule { }
