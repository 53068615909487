import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading: boolean;
  dataChange: BehaviorSubject<boolean>;
  dataChangeObserver: Observable<boolean>;

  constructor() {
    this.dataChange = new BehaviorSubject(false);
    this.dataChangeObserver = this.dataChange.asObservable();
  }

  start = () =>{
    this.isLoading = true;
      this.dataChange.next(this.isLoading);
   }

  end = () => {
    this.isLoading = false;
      this.dataChange.next(this.isLoading);
  }
}