<form #hotsitesForm class="col-12">
    <div class="form-group col-12">
        <label>Chave no Site:</label>
        <nb-select fullWidth 
            placeholder="Selecione..." 
            [(ngModel)]="media.mediaSiteKeyId" 
            name="mediaSiteKey"
            [selected]="media?.mediaSiteKeyId"
            (selectedChange)="onSelectedChangeMediaSiteKey($event)"
        >
            <nb-option *ngFor="let msk of mediaSiteKeys"
                [value]="msk.id"
            >
                {{ msk.siteKey }}
            </nb-option>
        </nb-select>
        <div style="padding-top: 5px; min-height: 40px;">
            <label *ngIf="description">Descrição da Chave:&nbsp;</label> <span *ngIf="description" [innerText]="description"></span>
        </div>

    </div>

    <div class="form-group col-12">
        <upload 
            [maxLimitFiles]="1" 
            (onUploadSuccess)="saveFileSuccess($event)"
            (onUploadFailed)="saveFileFailed($event)"
            [isDisableUploadAction]="!media?.mediaSiteKeyId"
            [fileTypes]="typeFile"
            [mediaId]="media.id"
            [mediaSiteKeyId]="media.mediaSiteKeyId"
            [businessUnitId]="media.businessUnitId"
            [saveMedia]="saveMedia"
            [resetMedia]="resetMedia"
        >
        </upload>
    </div>
</form>