import { NgModule } from '@angular/core';
import { HotsitesIndexComponent } from './@pages/hotsites-index/hotsites-index.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../shared/auth/authentication.guard';


const routes: Routes = [
  {
    path: 'hotsites/index',
    component: HotsitesIndexComponent,
    canActivate: [AuthenticationGuard]
  }
 ];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class HotsitesRoutingModule { }
