import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

import { environment } from 'src/environments/environment';

import { Survey } from '../../@model/survey';
import { SurveySearchCommand } from '../../@model/survey-search-command';


@Component({
  selector: 'app-survey-index',
  templateUrl: './survey-index.component.html',
  styleUrls: ['./survey-index.component.scss']
})
export class SurveyIndexComponent implements OnInit {

  survey = new Survey();
  surveys: Survey[] = [];
  direction: boolean = true;
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 1;
  page: number = 1;
  removeAction: boolean = false;
  isinit: boolean = false;
  order: string = "desc";
  show: boolean = true;

  isSearchFinish: boolean = false;
  searchByResult: string = "";
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private apiClientService: ApiClientService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.doSearch(1);
  }

  onCreate() {
    this.removeAction = false;
    this.survey = new Survey();
    this.modalService.modal('modal-news').title = "Criar Enquetes";
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Salvar";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "fa fa-plus margin-right-8";
    this.modalService.open('modal-news');
  }

  doSearch(pageNumber: number = 1) {

    let cmd = new SurveySearchCommand(this.searchByResult);

    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.surveys.length = 0;
    this.apiClientService.postPaginated(environment.urls.api, 'survey/search', cmd, pageNumber, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.loadPaginationInfo(data);
          this.loadData(data);
        }
        else
        {
          this.surveys = [];
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  private loadPaginationInfo(data: ResponseMessage) {
    this.page = data.page;
    this.rows = data.rows;
    this.pageSize = data.pageSize
    this.totalRows = data.totalRows;
    this.totalPage = data.totalPage;
  }

  private loadData(data: ResponseMessage) {
    this.surveys = data.data;
  }

  onEdit(survey: Survey): void {
    this.survey = Object.assign({}, survey);
    this.removeAction = false;
    this.modalService.modal('modal-news').title = "Editar Enquete";
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Salvar";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "far fa-save margin-right-8";
    this.modalService.open('modal-news');
  }

  onRemove(survey: Survey): void {
    this.survey = survey;
    this.removeAction = true;
    this.modalService.modal('modal-news').title = "Excluir Enquete " + this.survey.title;
    this.modalService.modal('modal-news').to = "modal";
    this.modalService.modal('modal-news').labelAction = "Excluir";
    this.modalService.modal('modal-news').labelClose = "Cancelar";
    this.modalService.modal('modal-news').iconAction = "fas fa-check margin-right-8";
    this.modalService.open('modal-news');
  }

  saveData(): void {
    if (this.removeAction) {
      this.apiClientService.delete<Survey>(environment.urls.api, 'survey', this.survey.id, this.callbackSuccess, this.callbackErr);
    } else {
      if (this.survey !== null && this.validateCurrent()) {
        this.apiClientService.save<Survey>(environment.urls.api, 'survey', this.survey, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  validateCurrent() {
    let response = true;

    if (this.survey.title === null || this.survey.title === undefined || this.survey.title === '') {
      this.toastr.error("Campo Título deve ser preenchido.");
      response = false;
    }
    if (this.survey.businessUnits === null || this.survey.businessUnits === undefined || this.survey.businessUnits.length < 1) {
      this.toastr.error("Campo Escola deve conter pelo menos um elemento.");
      response = false;
    }

    if (this.survey.alternatives === null || this.survey.alternatives === undefined || this.survey.alternatives.length < 1) {
      this.toastr.error("Campo Altrernativas deve conter pelo menos um elemento.");
      response = false;
    }

    return response;
  }

  callbackSuccess = (resp: any) => {
    const msg = 'Enquete '
      + (this.removeAction ? 'excluída' : this.survey.id !== undefined && this.survey.id !== null ? 'alterada' : 'criada')
      + ' com sucesso!';
    this.toastr.success(msg);

    if (this.survey.active) {
      this.toastr.warning('Apenas uma enquete por escola pode estar ativa. As demais enquetes foram desativadas.');
    }

    this.doSearch(1);
    this.modalService.close('modal-news');
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }


  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

}
