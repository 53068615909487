import { Component, OnInit, Input } from '@angular/core';
import { hotsites } from '../../@model/hotsites';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

@Component({
  selector: 'app-hotsites-form',
  templateUrl: './hotsites-form.component.html',
  styleUrls: ['./hotsites-form.component.scss']
})
export class HotsitesFormComponent implements OnInit {

  @Input() hotsites: hotsites;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService
  ) { }

  ngOnInit() {
  }

}
