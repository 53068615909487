import { BaseEntity } from "src/app/shared/@models/baseEntity";

export class courseBusinessUnitCourse extends BaseEntity {
  courseBusinessUnitId: string;
  courseId: string;
  courseType: string;

  constructor() {
    super();
  }
}
