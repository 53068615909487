import { AuthenticationService } from './../../auth/authentication.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NbMenuService, NbMenuItem } from '@nebular/theme';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, map } from 'rxjs/operators';

interface User {
  name: string;
  picture: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  items = [{ title: 'Sair' }];
  menuSubscription: Subscription;
  menu: NbMenuService;
  auth: AuthenticationService;

  constructor(menu: NbMenuService, auth: AuthenticationService) {
    this.menu = menu;
    this.auth = auth;
  }

  ngOnInit() {
    this.menuSubscription = this.menu.onItemClick().subscribe((menuBag) => {
      switch(menuBag.item.title) {
        case 'Sair':
          this.auth.logout();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.menuSubscription.unsubscribe();
  }
}