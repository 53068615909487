<form #eventForm class="col-12">
    <div class="form-group col-12">
        <label for="description">Descrição:</label>
        <input type="text" [(ngModel)]="banner.description" name="description" class="form-control col-12"
            placeholder="Descrição" id="description" autocomplete="off">
    </div>
    <div class="form-group col-12">
        <label >Título:</label>
        <input type="text" [(ngModel)]="banner.title" name="title" class="form-control col-12" placeholder="Título"
            id="title">
    </div>
    <div class="form-group col-12">
        <label >Sub Título:</label>
        <input type="text" [(ngModel)]="banner.subTitle" name="subTitle" class="form-control col-12"
            placeholder="Sub Título" id="subTitle">
    </div>
    <div class="form-group col-12">
        <label >Link:</label>
        <input type="text" [(ngModel)]="banner.link" name="Link" class="form-control col-12" placeholder="Link"
            id="Link">
    </div>
    <div class="form-group col-12">
        <label >Texto Botão:</label>
        <input type="text" [(ngModel)]="banner.textButton" name="textButton" class="form-control col-12"
            placeholder="Texto Botão" id="textButton">
    </div>

    <div class="form-group col-12">
        <label >Home Page e Escolas:</label>
        <input type="checkbox" 
        [(ngModel)]="banner.onHomePageAndBusinessUnit" 
        [checked]="banner.onHomePageAndBusinessUnit"
        (change)="onHomePageAndBusinessUnitChange()"
        name="onHomePageAndBusinessUnit"  
        id="onHomePageAndBusinessUnit" style="margin-left: 5px;">
    </div>

    <div class="form-group col-12">
        <label for="color">Escola:</label>
        <nb-select id="color" placeholder="Selecione..." [(selected)]="banner.businessUnitId" class="select-color col-12" [disabled]="disabledSelectBusinessUnit">
            <nb-option class="wrapper-icon-color" *ngFor="let b of businessUnit" [value]="b.integrationCode">
                <span class="descriptive-text">{{b.name}}</span>
            </nb-option>
        </nb-select>
    </div>

    <upload [maxLimitFiles]="1" (onUploadSuccess)="handleOnUploadSuccess($event)"
        (onUploadFailed)="handleOnUploadSuccessError($event)">
    </upload>

    <div class="form-group col-12" *ngIf="banner.image">
        <minio-image style="max-height: 200px;" class="stretch" [src]="this.banner.image"></minio-image>
    </div>

    <div class="form-group col-12">
        <label >Descrição Imagem (VLibras):</label>
        <input type="text" [(ngModel)]="banner.imageVLibra" name="imageVLibra" class="form-control col-12"
            placeholder="Descrição Imagem (VLibras)" id="imageVLibra">
    </div>

    <div class="form-group col-12">
        <label >Ativo:</label>
        <input type="checkbox" [(ngModel)]="banner.show" [checked]="banner.show" name="isActive"  id="isActive" style="margin-left: 5px;">
    </div>
</form>
