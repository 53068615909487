import { Component, OnInit, Input, ElementRef, ViewEncapsulation, AfterViewInit, ComponentFactoryResolver } from '@angular/core';
import { NotificationMessage } from './notificationMessage';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class NotificationsComponent implements OnInit, AfterViewInit {

  @Input() id: string;
  @Input() icon: string = 'far fa-bell';
  @Input() historyUrl: string = '/';
  @Input() titleLabel: string = 'Notificações';
  @Input() historyLabel: string = 'Visualizar histórico...';
  @Input() noMessageLabel: string = 'Nenhuma notificação pendente';
  @Input() notifications: Array<NotificationMessage> = new Array<NotificationMessage>();
  @Input() shortcutApp: boolean = false;
  private element: any;
  private isOpen: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {

    this.id = "1";
    let messages = new Array<NotificationMessage>();
    let msg = new NotificationMessage();
    msg.id = "001";
    msg.image = "";
    msg.title = "Novo Conflito";
    msg.message = "Novo Conflito";
    msg.type = "conflict";
    msg.isRead = false;
    msg.icon = "far fa-envelope";
    msg.alerts = 1;
    messages.push(msg);
    messages.push(msg);
    this.notifications = messages;
    this.shortcutApp = true;

  }

  ngAfterViewInit() {
  }

  closeDropDown = () => {
    this.element.click();
  }

  handlerShow = ($event: any): void => {
    if ((this.element.querySelector('.dropdown-menu').getBoundingClientRect().x + 340) > window.innerWidth) {
      this.element.querySelector('.dropdown-menu').classList.remove('arrow-up');
      this.element.querySelector('.dropdown-menu').style.left = ((window.innerWidth - (this.element.querySelector('.dropdown-menu').getBoundingClientRect().x)) - 360).toString() + 'px';
    }
  }

  show = ($event: any): void => {
    this.isOpen = true;
  }

  hidden = ($event: any): void => {
    this.isOpen = false;
  }
}
