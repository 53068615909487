<form #linkedCourseForm class="col-12">
  <div class="form-group col-12">
    <label>Nome do Curso:</label>
    <input type="courseName" [(ngModel)]="linkedCourse.courseName" name="courseName" class="form-control col-12"
      placeholder="Nome do Curso" id="courseName" autocomplete="off">
  </div>

  <div class="form-group col-12">
    <label for="description">Descrição:</label>
    <input type="description" [(ngModel)]="linkedCourse.description" name="description" class="form-control col-12"
      placeholder="Descriçao" id="name" autocomplete="off">
  </div>

  <div class="form-group col-12">
    <label>Palavras Chave:</label>
    <tags [(dataSource)]="linkedCourse.keywords" (onChangeTag)="onChangeTag($event)" buttonText="Nova Palavra Chave"
      name="keywords" id="keywords"></tags>
  </div>

  <div class="form-group col-12">
    <label>Ativo:</label>
    <input type="checkbox" [(ngModel)]="linkedCourse.show" [(checked)]="linkedCourse.isActive" name="isActive"
      id="isActive" style="margin-left: 5px;">
  </div>

  <div class="form-group col-12">
    <label for="color">Nível de Ensino:</label>
  </div>

  <div class="form-group col-12">
    <nb-select fullWidth id="levelCourse" [(ngModel)]="linkedCourse.levelEducationCode" name="levelEducationCode"
      (selectedChange)="changeLevelLearning()" [disabled]="linkedCourse.id !== undefined" class="select-color col-12 padding">
      <nb-option class="wrapper-icon-color" value="S" disabled>
        <span class="descriptive-text">Selecione</span>
      </nb-option>
      <nb-option class="wrapper-icon-color" *ngFor="let b of levels" [value]="b.code">
        <span class="descriptive-text">{{b.name}}</span>
      </nb-option>
    </nb-select>
  </div>

  <div class="form-group col-12">
    <label for="color">Cursos:</label>
    <nz-transfer [nzDataSource]="courseDataSource" nzShowSearch [nzFilterOption]="filterOption"
      (nzChange)="change($event)" [nzListStyle]="{ 'width.px': 600, 'height.px': 300 }">
    </nz-transfer>
  </div>
</form>
