import { environment } from './../../../environments/environment';
import { ApiClientService } from './../services/api-client.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export interface HasAccess {
  hasAccess: boolean;
}
export interface ResponseHasAccess {
  data: HasAccess;
  isValid: boolean;
  notifications: any[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(
    private apiClientService: ApiClientService,
    private router: Router
  ) { }

  userHasAccessToSelectedModule(allowedModule: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!allowedModule) {
        resolve(false);
      }

      /* Validação de acesso via API de Auth "Has Access" */
      this.apiClientService.get(environment.urls.authApi, "modules/userHasAccess/" + allowedModule, "")
        .then((data: ResponseHasAccess) => {
          if (data != null && data != undefined && data.data.hasAccess) {
            resolve(true);
          }
          else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err)
          reject(false);
        });
      /* Fim do metodo get */
    });
  }

}

