import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  getCurrentUser() {
    const userDataString = localStorage.getItem('user_data')
    if(userDataString) {
      var userData = JSON.parse(localStorage.getItem('user_data'));
      if (userData && userData.thumbnailPhoto && userData.thumbnailPhoto.substring(0, 4) === "/9j/") {
        userData = Object.assign({}, userData, {
          thumbnailPhoto: "data:image/png;base64," + userData.thumbnailPhoto
        });  
      } else {
        userData = Object.assign({}, userData, {
          thumbnailPhoto: ""
        });
      }

      return userData;
    } else {
      return {};
    }
  }

  logout() {
    localStorage.clear();
    window.location.href = environment.urls.loginUnificado;
  }
}
