<content-header title="Gestão de Carrossel">
    <div content-text>
        Nesta tela você poderá cadastrar e administrar os banners de Carrossel.
    </div>
    <div content-action>
        <button nbButton shape="round" outline size="small" status="primary" (click)="onCreate()">
            <i class="fa fa-plus margin-right-8"></i> Criar
        </button>
    </div>
</content-header>
<nb-card>
    <nb-card-body>
        <div class="alert alert-danger col-12" role="alert"
        *ngIf="banners.length == 0 && isinit">
            Nenhum carrousel cadastrado.
        </div>
        <div class="col-12 mx-auto table-responsive-sm no-padding">
            <div class="table-container">
                <table class="table table-striped table-hover table-fixed" *ngIf="banners.length > 0">
                    <thead>
                        <tr>
                            <th class="name">
                                Descrição
                            </th>
                            <th class="description">Escola</th>
                            <th class="description">Imagem</th>
                            <th class="name">Data Inclusão</th>
                            <th class="name">Ativo</th>
                            <th class="options">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let b of banners">
                            <td class="title-grid">
                                {{b.description}}
                            </td>
                            <td>
                                {{b.businessUnitName}}
                            </td>
                            <td>
                                <minio-image [src]="b.image" style="max-height: 100px;width: auto;"></minio-image>
                            </td>
                            <td>
                                {{b.insertedAt | date:'dd/MM/yy HH:mm'}}
                            </td>
                            <td>
                                <input type="checkbox" [checked]="b.show" disabled>
                            </td>
                            <td class="td-action">
                                <app-carousel-btn-drop-action (onEdit)="onEdit(b)" (onRemove)="onRemove(b)" (onUp)="onUp(b)" (onDown)="onDown(b)">
                                </app-carousel-btn-drop-action>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-info-container" *ngIf="banner !== undefined || banners.length > 0">
                <div class="table-info-label">
                    <div> Registros:{{totalRows}}</div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<modal id="modal-banner" (onAction)="saveData()" (onClose)="resetForm()">
    <div body *ngIf="removeAction">Confirma a exclusão deste banner?</div>
    <div body *ngIf="!removeAction">
        <app-carousel-form [banner]="banner" [businessUnit]="businessUnit"></app-carousel-form>
    </div>
</modal>
