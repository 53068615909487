export class NotificationMessage {
    id: string;
    image: string;
    title: string;
    message: string;
    type: string;
    isRead: boolean;
    read: ($event: any) => void;
    confirm: ($event: any) => void;
    unconfirm: ($event: any) => void;
    readAt: Date;
    icon: string;
    alerts: number;

    constructor() {
        this.id = null;
        this.image = null;
        this.title = null;
        this.message = null;
        this.type = null;
        this.isRead = false;
        this.read = null;
        this.confirm = null;
        this.unconfirm = null;
        this.readAt = null;
        this.icon = null;
        this.alerts = 0;
    }
}