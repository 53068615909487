import { ApiClientService } from './../../shared/services/api-client.service';
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ResponseMessage } from '../../shared/@models/responseMessage';
import { LoadingService } from '../../shared/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private apiClientService: ApiClientService
  ) { }

  getPaginated(cmd: any, page: number, pageSize: number, order: string = 'asc') {
    return this.apiClientService.postPaginated(environment.urls.api, 'event/search', cmd, page, pageSize, order);
  }
}
