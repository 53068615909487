import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EvidenceRoutingModule } from './evidence-routing.module';
import { NbCardModule, NbButtonModule, NbActionsModule, NbSelectModule, NbFormFieldModule, NbCheckboxModule } from '@nebular/theme';
import { EvidenceIndexComponent } from './@pages/evidence-index/evidence-index.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EvidenceFormComponent } from './@pages/evidence-form/evidence-form.component';
import { NbIconModule, NbInputModule } from '@nebular/theme';
import { EditorModule } from '@tinymce/tinymce-angular';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [EvidenceIndexComponent, EvidenceFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    EvidenceRoutingModule,
    SharedModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    EditorModule
  ]
})

export class EvidenceModule { }