import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Media } from '../../@model/media';
import { MediaSiteKey } from '../../@model/media-site-key';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClientService } from 'src/app/shared/services/api-client.service';

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss']
})
export class MediaFormComponent implements OnInit {

  private _media: Media;
  @Input() set media(value: Media) {
      this._media = value;
      this.resetFile();
      if (this._media && this._media.mediaSiteKeyId) {
        this.onSelectedChangeMediaSiteKey(this._media.mediaSiteKeyId);
      }
  }
  get media(): Media {
      return this._media;
  }

  @Input() mediaSiteKeys: MediaSiteKey[];
  @Input() saveMedia: EventEmitter<any>;
  @Output() saveSuccess: EventEmitter<any>;
  @Output() saveError: EventEmitter<any>;
  @Input() resetMedia: EventEmitter<any>;

  description: string = '';
  typeFile: string = '';

  constructor(
    protected sanitizer: DomSanitizer,
    private apiClientService: ApiClientService
  ) { 
    this.saveSuccess = new EventEmitter<any>();
    this.saveError = new EventEmitter<any>();
  }

  public ngOnInit(): void {
  }

  public onSelectedChangeMediaSiteKey(id: any): void {
    this.resetFile();
    this.description = '';
    this.typeFile = '';
    if (this.mediaSiteKeys) {
      this.mediaSiteKeys.forEach(mediaSiteKey => { 
        if (mediaSiteKey.id == id) {
          this.description = mediaSiteKey.description;
          this.typeFile = mediaSiteKey.typeFile ?? undefined;
        }
      });
    }
  }

  private resetFile() {
    this.description = '';
    this.typeFile = '';
    if (this.resetMedia) {
      this.resetMedia.emit(null);
    }
  }

  saveFileSuccess(data: any): void {
    this.saveSuccess.emit(data);
  }

  saveFileFailed(error: any): void {
    this.saveError.emit(error);
  }
}
