import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LinkedCourseRoutingModule } from './linkedCourse-routing.module';
import { NbCardModule, NbButtonModule, NbActionsModule, NbSelectModule, NbFormFieldModule, NbDatepickerModule } from '@nebular/theme';
import { LinkedCourseIndexComponent } from './@pages/linkedCourse-index/linkedCourse-index.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LinkedCourseFormComponent } from './@pages/linkedCourse-form/linkedCourse-form.component';
import { NbIconModule, NbInputModule } from '@nebular/theme';
import { NzTransferModule } from 'ng-zorro-antd/transfer';

let options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

@NgModule({
  declarations: [LinkedCourseIndexComponent, LinkedCourseFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    LinkedCourseRoutingModule,
    SharedModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbSelectModule,
    NgxMaskModule.forRoot(options),
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbDatepickerModule,
    NzTransferModule
  ]
})

export class LinkedCourseModule { }
