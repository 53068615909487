import { School, UnitAdress, Photo } from './../../@model/school';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { ApiClientService } from 'src/app/shared/services/api-client.service';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from 'src/app/shared/@models/responseMessage';

@Component({
  selector: 'app-school-index',
  templateUrl: './school-index.component.html',
  styleUrls: ['./school-index.component.scss']
})
export class SchoolIndexComponent implements OnInit {
  /* PAGINATION */
  pageSize: number = 30;
  rows: number = 0;
  totalRows: number = 0;
  totalPage: number = 0;
  page: number = 0;
  order: string = "asc";
    
  moment: any = moment;
  show: boolean = false;
  direction: boolean = false;
  isinit: boolean = false;
  errorOnLoaging: boolean = false;
  schools: Array<School> = [];
  selectedSchool: School = new School();
  removeAction: boolean = false;
  edit: boolean = false;
  isSearchFinish: boolean = false;
  paginationSearch: boolean = false;
  searchParameter: string = "";

  constructor(
    private apiClientService: ApiClientService,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) {
    moment.locale('pt-br');
  }

  ngOnInit(): void {
    this.getSchools();
  }

  sortResult() {
    if (this.direction == false) {
      this.order = "desc";

      if (!this.paginationSearch)
        this.getSchools(this.page, this.order);
      else
        this.doSearch();

      this.direction = true;
    }
    else {
      this.order = "asc";

      if (!this.paginationSearch)
        this.getSchools(this.page, this.order);
      else
        this.doSearch();

      this.direction = false;
    }
  }

  doSearch(n: number = 1) {

    if (!this.searchParameter) {
      this.getSchools(n);
      return
    }

    let cmd = {
      "searchParameter": this.searchParameter
    };

    this.paginationSearch = true;
    this.isSearchFinish = false;
    this.isinit = false;
    this.errorOnLoaging = false;
    this.schools.length = 0;
    this.apiClientService.postPaginated(environment.urls.businessUnitApi, "businessUnit/search", cmd, n, this.pageSize, this.order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.schools.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
        this.isSearchFinish = true;
      });
  }

  getSchools(n: number = 1, order: string = 'asc') {
    this.isinit = false;
    this.show = false;
    this.errorOnLoaging = false;
    this.schools.length = 0;
    this.apiClientService.getPaginated(environment.urls.businessUnitApi, "businessUnit", n, this.pageSize, order)
      .then((data: ResponseMessage) => {
        if (data != null && data.rows > 0) {
          this.page = data.page;
          this.rows = data.rows;
          this.totalRows = data.totalRows;
          this.totalPage = data.totalPage;
          data.data.forEach(element => { this.schools.push(element) });
        }
      })
      .finally(() => {
        this.isinit = true;
      });
  }

  onEdit(obj: School) {
    this.selectedSchool = Object.assign({}, obj);
    this.selectedSchool.contacts.length = 0; 
    this.removeAction = false;
    this.edit = true;
    this.show = true;
    this.modalService.modal('modal-school').title = "Editar Escola";
    this.modalService.modal('modal-school').to = "modal";
    this.modalService.modal('modal-school').labelAction = "Salvar";
    this.modalService.modal('modal-school').labelClose = "Cancelar";
    this.modalService.modal('modal-school').iconClose = "fas fa-times margin-right-8"
    this.modalService.modal('modal-school').iconAction = "fas fa-plus margin-right-8";
    this.modalService.open('modal-school');
  }

  resetForm() {
    this.show = false;
    this.selectedSchool = undefined;
  }

  saveData(): void {
    this.normalizeHours();

    if (!this.removeAction) {
      if (this.selectedSchool !== null && this.validateCurrent()) {
        this.apiClientService.save<School>(environment.urls.businessUnitApi, 'businessUnit', this.selectedSchool, this.callbackSuccess, this.callbackErr);
      }
    }
  }

  callbackSuccess = (resp: any) => {
    let msg = `Escola` + ' ' + (this.removeAction ? `excluída` : this.selectedSchool.id !== undefined && this.selectedSchool.id !== null ? `alterada` : `criada`) + ` com sucesso!`;
    this.toastr.success(msg);
    this.getSchools();
    this.modalService.close('modal-school');
  }

  private normalizeHours() {
    this.selectedSchool.openingHoursWorkDays = this.convertMomentToUtcDate(this.selectedSchool.openingHoursWorkDays);
    this.selectedSchool.closingHoursWorkDays = this.convertMomentToUtcDate(this.selectedSchool.closingHoursWorkDays);
    this.selectedSchool.openingHoursWeekend = this.convertMomentToUtcDate(this.selectedSchool.openingHoursWeekend);
    this.selectedSchool.closingHoursWeekend = this.convertMomentToUtcDate(this.selectedSchool.closingHoursWeekend);
  }

  private convertMomentToUtcDate(moment) {
    try {
      let m = (moment as unknown) as Moment;
      m.utc(true);
      return m.toDate();
    } catch {
      return moment;
    }
  }

  callbackErr = (resp: any) => {
    console.error(resp);
  }

  validateCurrent() {
    let response = true;

    if (this.selectedSchool.siteDescription === null 
      || this.selectedSchool.siteDescription === undefined 
      || this.selectedSchool.siteDescription === '') {
      this.toastr.error("Campo Descrição do Site deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.directorName === null 
      || this.selectedSchool.directorName === undefined 
      || this.selectedSchool.directorName === '') {
      this.toastr.error("Campo Nome Diretor (a) deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.directorSex === null 
      || this.selectedSchool.directorSex === undefined 
      || this.selectedSchool.directorSex === '') {
      this.toastr.error("Campo Sexo do Diretor deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.unitAdress.completeAddress === null 
      || this.selectedSchool.unitAdress.completeAddress === undefined 
      || this.selectedSchool.unitAdress.completeAddress === '') {
      this.toastr.error("Campo Endereço deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.unitAdress.city === null 
      || this.selectedSchool.unitAdress.city === undefined 
      || this.selectedSchool.unitAdress.city === '') {
      this.toastr.error("Campo Cidade deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.unitAdress.state === null 
      || this.selectedSchool.unitAdress.state === undefined 
      || this.selectedSchool.unitAdress.state === '') {
      this.toastr.error("Campo Estado deve ser preenchido.");
      response = false;
    }


    if (this.selectedSchool.unitAdress.neighborhood === null 
      || this.selectedSchool.unitAdress.neighborhood === undefined 
      || this.selectedSchool.unitAdress.neighborhood === '') {
      this.toastr.error("Campo Bairro deve ser preenchido.");
      response = false;
    }

    if (this.selectedSchool.unitAdress.zipCode === null 
      || this.selectedSchool.unitAdress.zipCode === undefined 
      || this.selectedSchool.unitAdress.zipCode === '') {
      this.toastr.error("Campo CEP deve ser preenchido.");
      response = false;
    }

    return response;
  }

}
