import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { Title } from '@angular/platform-browser';

import { Event } from './../../@model/event';
import { Subscriber } from '../../@model/subscriber';
import { SubscriberService } from '../../@services/subscriber.service';
import { SubscriberStatus } from '../../@model/subscriber-status';
import { CpfValidator } from './../../../shared/validator/cpf-validator';
import { EmailValidator } from './../../../shared/validator/email-validator';

import { saveAs } from "file-saver";
import { environment } from 'src/environments/environment';
import { ApiClientService } from 'src/app/shared/services/api-client.service';

@Component({
  selector: 'app-subscriber-index',
  templateUrl: './subscriber-index.component.html',
  styleUrls: ['./subscriber-index.component.scss'],
})
export class SubscriberIndexComponent implements OnInit {
  event: Event;
  subscriber = new Subscriber();
  subscribers: Subscriber[];
  subscriberStatus: SubscriberStatus[];
  removeAction: boolean = false;
  isinit: boolean = false;
  show: boolean = true;

  isSearchFinish: boolean = false;
  searchByResult: string = '';
  errorOnLoaging: boolean = false;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private subscriberService: SubscriberService,
    private router: Router,
    private apiClientService: ApiClientService
  ) {}

  ngOnInit() {
    this.recoverEvent();
    this.getSubscriberStatus();
    this.validateRecoveredEvent();
  }

  private recoverEvent() {
    this.event = history.state.data;
  }

  private validateRecoveredEvent() {
    if (this.event === undefined) {
      this.router.navigate(['/event']);
    } else {
      if (this.event.subscribers) {
        this.loadData(this.event);
      } else {
        this.doSearch(this.event.id);
      }
    }
  }

  getSubscriberStatus() {
    this.subscriberService.getSubscriberStatus(
      (subscribers) => (this.subscriberStatus = subscribers),
      this.callbackError
    );
  }

  onCreate() {
    if (!this.availableVacancies()) {
      this.toastr.warning('Vagas indisponíveis.');
      return;
    } else {
      this.openModal();
    }
  }

  export() {
    this.apiClientService
    .getAll(environment.urls.api, 'subscriber/export/'+this.subscribers[0].eventId)
    .then((data: any) => {
      const _blob = new Blob([data.base64], { type: 'plain/text' });
      saveAs(_blob, "exportar.xls");
    })
    .finally(() => { });
  }

  private openModal(subscriber: Subscriber = null) {
    if (!subscriber) {
      this.subscriber = this.initializeNewSubscriber();

      this.modalService.modal('modal-subscriber').title = 'Criar Inscrição';
      this.modalService.modal('modal-subscriber').iconAction =
        'fa fa-plus margin-right-8';
    } else {
      this.subscriber = Object.assign({}, subscriber);
      this.modalService.modal('modal-subscriber').title = 'Editar Inscrição';
      this.modalService.modal('modal-subscriber').iconAction =
        'far fa-save margin-right-8';
    }

    this.removeAction = false;
    this.modalService.modal('modal-subscriber').to = 'modal';
    this.modalService.modal('modal-subscriber').labelAction = 'Salvar';
    this.modalService.modal('modal-subscriber').labelClose = 'Cancelar';
    this.modalService.open('modal-subscriber');
  }

  private initializeNewSubscriber() {
    const subscriber = new Subscriber();
    subscriber.eventId = this.event.id;
    subscriber.subscriberTypeId = this.subscriberService.DEFAULT_SUBSCRIBER_STATUS;

    return subscriber;
  }

  availableVacancies() {
    return (
      this.event?.subscribers.length < this.event?.quantityForRegistration
    );
  }

  doSearch(eventId: string) {
    this.subscriberService.getEvent(
      eventId,
      (event) => this.loadData(event),
      this.callbackError
    );
  }

  private loadData(event: Event) {
    this.event = event;
    this.subscribers = this.OrderSubscribersByName(
      this.filterActive(event.subscribers)
    );
  }

  private filterActive(subscribers: Subscriber[]) {
    return subscribers.filter((f) => f.isActive);
  }

  OrderSubscribersByName(subscribers: Subscriber[]) {
    subscribers = subscribers.sort(
      (subscriber1: Subscriber, subscriber2: Subscriber) => {
        if (subscriber1.name >= subscriber2.name) {
          return 1;
        }

        return -1;
      }
    );

    return subscribers;
  }

  onEdit(subscriber: Subscriber): void {
    this.openModal(subscriber);
  }

  onRemove(subscriber: Subscriber): void {
    this.subscriber = subscriber;
    this.removeAction = true;
    this.modalService.modal('modal-subscriber').title =
      'Excluir Inscrição ' + this.subscriber.name;
    this.modalService.modal('modal-subscriber').to = 'modal';
    this.modalService.modal('modal-subscriber').labelAction = 'Excluir';
    this.modalService.modal('modal-subscriber').labelClose = 'Cancelar';
    this.modalService.modal('modal-subscriber').iconAction =
      'fas fa-check margin-right-8';
    this.modalService.open('modal-subscriber');
  }

  saveData(): void {
    if (this.removeAction) {
      this.subscriberService.delete(
        this.subscriber.id,
        this.callbackSuccess,
        this.callbackError
      );
    } else {
      if (this.subscriber !== null && this.validateCurrent()) {
        this.subscriberService.save(
          this.subscriber,
          this.callbackSuccess,
          this.callbackError
        );
      }
    }
  }

  validateCurrent() {
    let response = true;
    response = response && this.ValidateCpf(this.subscriber.cpf);
    response = response && this.validateName(this.subscriber.name);
    response = response && this.validateEmail(this.subscriber.email);

    return response;
  }

  private validateEmail(email: string) {
    let response = true;

    // if (
    //   email === null ||
    //   email === undefined ||
    //   email === ''
    // ) {
    //   this.toastr.error('Campo E-mail deve ser preenchido.');
    //   response = false;
    // } else
    if (email !== null &&
      email !== undefined &&
      email !== '' &&
      this.isInvalidEmail(email)) {
      this.toastr.error('Campo E-mail inválido.');
      response = false;
    }

    return response;
  }

  private validateName(name: string) {
    let response = true;

    if (name === null ||
      name === undefined ||
      name === '') {
      this.toastr.error('Campo Nome deve ser preenchido.');
      response = false;
    }

    return response;
  }

  private ValidateCpf(cpf: string) {
    let response = true;

    if (cpf === null ||
      cpf === undefined ||
      cpf === '') {
      this.toastr.error('Campo CPF deve ser preenchido.');
      response = false;
    } else if (this.isInvalidCPF(cpf)) {
      this.toastr.error('Campo CPF inválido.');
      response = false;
    }
    return response;
  }

  isInvalidCPF(cpf: string) {
    return !CpfValidator.isValid(cpf);
  }

  isInvalidEmail(email: string) {
    return !EmailValidator.isValid(email);
  }

  callbackSuccess = (resp: any) => {
    const msg =
      'Inscrição ' +
      (this.removeAction
        ? 'excluída'
        : this.subscriber.id !== undefined && this.subscriber.id !== null
        ? 'alterada'
        : 'criada') +
      ' com sucesso!';
    this.toastr.success(msg);

    this.doSearch(this.event.id);
    this.modalService.close('modal-subscriber');
  };

  callbackError = (resp: any) => {
    console.error(resp);
  };

  resetForm() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 10);
  }

  onChangeSubscriberStatus(subscriber: Subscriber) {
    this.subscriberService.updateStatus(
      subscriber,
      subscriber => this.toastr.success('Inscrição alterada com sucesso!'),
      this.callbackError
    );
  }
}
