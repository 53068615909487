import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ResourceAction } from '../../@models/resourceAction';
import { CardImage } from '../../@models/cardImage';

@Component({
  selector: 'dropdown-card',
  templateUrl: './dropdown-card.component.html',
  styleUrls: ['./dropdown-card.component.scss']
})
export class DropdownCardComponent implements OnInit, AfterViewInit {

@Input() iconNoImage: string = 'fa fa-user';
@Input() listLinks: Array<ResourceAction> = new Array<ResourceAction>();
@Input() links: Array<ResourceAction> = new Array<ResourceAction>();
@Input() card: CardImage = new CardImage();

private element: any;
private isOpen: boolean = false;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  handlerShow = ($event: any): void => {
    if((this.element.querySelector('.dropdown-menu').getBoundingClientRect().x + 300) > window.innerWidth) {
      this.element.querySelector('.dropdown-menu').classList.remove('arrow-up');
      this.element.querySelector('.dropdown-menu').style.left = ((window.innerWidth - (this.element.querySelector('.dropdown-menu').getBoundingClientRect().x ))-300).toString() + 'px';
    }
  }

  show = ($event: any): void => {
    this.isOpen = true;
  }

  hidden = ($event: any): void => {
      this.isOpen = false;
  }
}
